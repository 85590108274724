/**
 * Created by florin.iacob on 5/22/2017.
 */
(function() {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .factory('InitService', InitService);

    InitService.$inject = ['$q', 'GenericService'];

    function InitService($q, GenericService) {

        var Service = {};

        /**
         * Aducerea mesajului initial configurat pentru aifsare
         */
        Service.getWarningMessage = function() {
            return GenericService.GetFn('/init/getWarningMessage');
        };

        return Service
    }
})();

