/**
 * Created by florin.iacob on 5/22/2017.
 */
(function() {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .factory('SeapAlertService', SeapAlertService);

    SeapAlertService.$inject = ['toastr', '$uibModal', 'toastrConfig', 'TOAST_DEFAULT_POS'];

    function SeapAlertService(toastr, $uibModal, toastrConfig, TOAST_DEFAULT_POS) {

        var defaultTimeout = 10000;

        /**
         * Show error message with Toastr Service
         *
         * @param massage
         * @param title
         * @private
         */
        var _toastError = function(message, title, timeout) {
            if (!title) {
                title = "Eroare";
            }
            toastr.error(message, title);
        };

        /**
         * Show success message with Toastr Service
         *
         * @param massage
         * @param title
         * @private
         */
        var _toastSuccess = function(message, title, timeout) {
            if (!title) {
                title = "Succes";
            }
            toastr.success(message, title);
        };

		var _toastWarning = function(message, title, position) {
            if (!title) {
                title = "Atentie";
            }
            if (position) {
                toastrConfig.positionClass = 'toast-' + position;
                toastr.warning(message, title);
                toastrConfig.positionClass = TOAST_DEFAULT_POS;
            } else {
                toastr.warning(message, title);
            }
        };

        /**
         * Display alert message as a modal
         *
         * @param message: the message to be displayed
         * @param title: title for the modal
         * @param okCallback: function to be executed when ok button is pressed
         * @private
         */
        var _displayModalAlert = function(message, title, okCallback) {
            $uibModal.open({
                templateUrl: 'app/_seap/service/alerts/modal/modal-alert-template.html',
                controller: 'ModalAlertTemplateCtrl',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    modalOptions: function() {
                        return {
                            message: message,
                            title: title,
                            okCallback: okCallback
                        };
                    }
                }
            })
        };

        return {
            toastError: _toastError,
            toastSuccess: _toastSuccess,
			toastWarning: _toastWarning,
            displayModalAlert: _displayModalAlert
        }
    }
})();

