/**
 * Created by florin.iacob on 5/16/2017.
 */
(function() {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .controller('DeschidereOferteFormCtrl', DeschidereOferteFormCtrl);

    DeschidereOferteFormCtrl.$inject = ['$q', 'SeapAlertService', '$uibModal', 'AcqProcedureLotService', 'FormProceduraAchizitieService', 'Constants', 'Principal', 'SicapSyncService'];
    function DeschidereOferteFormCtrl($q, SeapAlertService, $uibModal, AcqProcedureLotService, FormProceduraAchizitieService, Constants, Principal, SicapSyncService) {

        var vm = this;

        /*
            acqProcedure: '=',
            isReadOnly: '='
            isLocal: '='
        */

        vm.acqProcedureId = vm.acqProcedure.acqProcedureId;
        vm.isTenderOpenDateMandatory = vm.acqProcedure.tenderOpenDateMandatory;

        vm.hasRoleManualAddAcqProcedure = vm.acqProcedure.isLocal && Principal.hasAnyAuthority(['ROLE_MANUAL_ADD_ALL', 'ROLE_MANUAL_ADD_ACQ_PROCEDURE']);
        vm.hasRoleManualAddAcqProcedureLot = vm.acqProcedure.isLocal && Principal.hasAnyAuthority(['ROLE_MANUAL_ADD_ALL', 'ROLE_MANUAL_ADD_ACQ_PROCEDURE_LOT']);
        vm.hasRoleExclusiveSyncAllSections = Principal.hasAnyAuthority(['ROLE_EXCLUSIVE_SYNC_ALL_SECTIONS']);

        vm.dataInitialized = false;

        /**
         * Initializeaza datele necesare in Controller
         */
        vm.initData = function() {
            vm.isLoadingData = true;
            var serverRequestArray = [];
            serverRequestArray.push(AcqProcedureLotService.findByAcqProcedureId(vm.acqProcedureId));

            $q.all(serverRequestArray).then(
                function success(responseArray) {
                    vm.acqProcedureLotList = responseArray[0];
                    vm.acqProcedure.tenderOpenDate = vm.acqProcedure.tenderOpenDate ? new Date(vm.acqProcedure.tenderOpenDate) : null;
                    vm.isLoadingData = false;
                    vm.dataInitialized = true;
                }, function error(errorMsgArray) {
                    vm.isLoadingData = false;
                    SeapAlertService.toastError('Eroare la aducerea datelor despre Procedura. Mesaj: ' + errorMsgArray, 'Eroare');
                }
            );
        };

        if (vm.acqProcedureId) {
            vm.initData();
        }

        // Adaugare lot
        vm.openSaveLotModal = function(lot) {
            var lotEntity = null;
            if (lot) {
                lotEntity = {};
                angular.copy(lot, lotEntity);
            }

            $uibModal.open({
                templateUrl: 'app/_seap/modals/create-lot/create-lot.html',
                controller: 'CreateLotCtrl',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    acqProcedureId: function() {
                        return vm.acqProcedureId;
                    },
                    acqProcedure: function() {
                        return vm.acqProcedure;
                    },
                    lotEntity: function() {
                        return lotEntity;
                    },
                    isTenderOpenDateMandatory: function () {
                        return vm.isTenderOpenDateMandatory;
                    }
                }
            }).result.then(
                function success(responseLot) {
                    vm.initData();
                    /*if (lot) {
                        angular.forEach(vm.acqProcedureLotList, function(lotItem, index) {
                            if (responseLot.acqProcedureLotId && lotItem.acqProcedureLotId == responseLot.acqProcedureLotId) {
                                vm.acqProcedureLotList[index] = responseLot;
                                return;
                            }
                        });
                    } else {
                        vm.acqProcedureLotList.push(responseLot);
                    }*/
                }, function error(response) {
                    console.error(' -- Eroare salvare date in Modal AcqProcedureLot');
                }
            );
        };

        // Sterge lot
        vm.deleteLot = function(lotId) {
            AcqProcedureLotService.delete(lotId).then(
                function success(response) {
                    vm.initData();
                    /*angular.forEach(vm.acqProcedureLotList, function(lotItem, index) {
                        if (lotItem.acqProcedureLotId == lotId) {
                            vm.acqProcedureLotList.splice(index, 1);
                            SeapAlertService.toastSuccess('Lotul a fost sters!');
                            return;
                        }
                    });*/
                }, function error(errorMsg) {
                    SeapAlertService.toastError('Eroare la stergerea Lotului. Mesaj: ' + errorMsg, 'Eroare');
                }
            );
        };

        // Editable
        vm.isDeschidereOferteFormEditable = false;
        vm.toggleDeschidereOferteFormEdit = function() {
            vm.isDeschidereOferteFormEditable = !vm.isDeschidereOferteFormEditable;
            if (vm.isDeschidereOferteFormEditable && !vm.dataInitialized) {
                vm.initData();
            }
        };

        /**
         * Salveaza informatiile depre fond UE
         */
        vm.saveDeschidereOferteForm = function() {
            if (vm.acqProcedure.isLot === true) {
                if (vm.acqProcedureLotList.length === 0) {
                    SeapAlertService.toastError('Procedura trebuie sa contina loturi! Adaugati loturile procedurii sau schimbati optiunea in procedura fara loturi!', 'Atentie!');
                    return;
                }
            }
            FormProceduraAchizitieService.update(vm.acqProcedure, true).then(
                function success(response) {
                    vm.isDeschidereOferteFormEditable = false;
                    SeapAlertService.toastSuccess('Date despre DESCHIDERE OFERTE salvate cu succes!', 'Success');
                }, function error(errorMsg) {
                    SeapAlertService.toastError('Eroare la salvarea datelor. Mesaj: ' + errorMsg, 'Eroare');
                }
            );
        };

        /**
         * Apelare functie pentru schimbare optiune isLot pe procedura
         */
        vm.isLotChanged = function() {
            if(vm.isTenderOpenDateMandatory) {
                SeapAlertService.toastError('Procedura nu permite in acest caz de utilizare modificarea tipului de organizare pe loturi', 'Atentie!');
                vm.acqProcedure.isLot = !vm.acqProcedure.isLot;
                return;
            }

            if (vm.acqProcedure.isLot === false) {
                if (vm.acqProcedureLotList.length > 0) {
                    SeapAlertService.toastError('Procedura contine loturi! Stergeti loturile si apoi puteti sa schimbati in procedura fara loturi!', 'Atentie!');
                    vm.acqProcedure.isLot = true;
                    return;
                }
            }
        };

        // Sincronizare date lista loturi din SICAP
        vm.syncAcqProcedureLotFromSicap = function() {
            vm.isLoadingData = true;
            SicapSyncService.syncAcqProcedureLotList(vm.acqProcedureId).then(
                function success(response) {
                    vm.initData();
                    SeapAlertService.toastSuccess("Preluarea datelor pentru lista loturi din Noul SEAP s-a efectuat cu succes!");
                    vm.isLoadingData = false;
                },
                function error(errorMsg) {
                    SeapAlertService.toastError(errorMsg);
                    vm.isLoadingData = false;
                }
            );
        };

        // DATE PICKER
        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.dataDeschidereOferte = false;
        vm.openCalendar = function(date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
