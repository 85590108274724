/**
 * Created by florin.iacob on 5/16/2017.
 */
(function() {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .controller('AlegeContracteFormCtrl', AlegeContracteFormCtrl);

    AlegeContracteFormCtrl.$inject = ['$q', 'SeapAlertService', 'DecisionMakerContractService', 'EvalCmteMemberContractService', '$scope', 'ContracteFormService', 'ContractTendererService', 'Principal', 'SicapSyncService'];

    function AlegeContracteFormCtrl($q, SeapAlertService, DecisionMakerContractService, EvalCmteMemberContractService, $scope, ContracteFormService, ContractTendererService, Principal, SicapSyncService ) {

        var vm = this;
        /*
            acqProcedureId: '=',
            acqProcedure: '=',
            parentId: '=',
            parentName: '@',
            isContractSigner: '=',
            isReadOnly: '='
            isLocal: '='
         */
        var entityName;

        vm.hasRoleManualAddContract = vm.acqProcedure.isLocal && Principal.hasAnyAuthority(['ROLE_MANUAL_ADD_ALL', 'ROLE_MANUAL_ADD_CONTRACT']);
        vm.hasRoleExclusiveSyncAllSections = Principal.hasAnyAuthority(['ROLE_EXCLUSIVE_SYNC_ALL_SECTIONS']);

        vm.initData = function() {
            var serverRequestsArray = [
                ContracteFormService.findByAcqProcedureId(vm.acqProcedureId)
            ];
            if (vm.parentId) {
                if (vm.parentName === 'DecisionMaker') {
                    serverRequestsArray.push(DecisionMakerContractService.findByDecisionMakerId(vm.parentId));
                    entityName = 'decisionMaker';
                    vm.hasRoleManualAddContract = true;
                } else if (vm.parentName ===  'EvalCmteMember') {
                    serverRequestsArray.push(EvalCmteMemberContractService.findByEvalCmteMemberId(vm.parentId));
                    entityName = 'evalCmteMember';
                    vm.hasRoleManualAddContract = true;
                } else if (vm.parentName ===  'Tenderer') {
                    serverRequestsArray.push(ContractTendererService.findByTendererId(vm.parentId));
                    entityName = 'tenderer';
                } else {
                    SeapAlertService.toastError("Nu pot fi aduse date despre contracte!");
                    return;
                }
            }

            vm.data = {};

            // Aducere date Server
            vm.isLoadingData = true;
            $q.all(serverRequestsArray).then(
                function success(responseArray) {
                    vm.data.contractDTOList = responseArray[0];

                    if (vm.parentId) {
                        vm.entityContractDTOList = responseArray[1];
                        if (vm.entityContractDTOList.length > 0) {
                            vm.isContractSigner = true;
                        } else {
                            vm.isContractSigner = false;
                        }
                    } else {
                        vm.entityContractDTOList = [];
                    }

                    vm.isLoadingData = false;
                },
                function error(error) {
                    SeapAlertService.toastError('Eroare la aducere date despre Contracte!');
                    vm.isLoadingData = false;
                }
            );
        };

        vm.initData();

        vm.selectedContractId = null;

        // Adauga un nou Contract
        vm.addContract = function(selectedContractId) {
            var existent = false;
            angular.forEach(vm.entityContractDTOList, function(entityContractDTO, index) {
                if (!entityContractDTO.isDeleted && entityContractDTO.contractDTO.contractId === parseInt(selectedContractId)) {
                    existent = true;
                    return;
                }
            });

            if (existent) {
                SeapAlertService.toastError('Contract deja existent!');
            } else {
                angular.forEach(vm.data.contractDTOList, function (contractDTO, index) {
                    if (contractDTO.contractId === parseInt(selectedContractId)) {
                        vm.entityContractDTOList.push({
                            contractDTO: contractDTO
                        });
                        $scope.formAlegeContracte.$pristine = false;
                        return;
                    }
                });
            }

        };

        // Sterge o Contract
        vm.deleteContract = function(selectedEntityContractDTO) {
            $scope.formAlegeContracte.$pristine = false;
            angular.forEach(vm.entityContractDTOList, function(entityContractDTO, index) {
                var entityFieldId;
                if (entityName === 'tenderer') {
                    entityFieldId = "contractTendererId";
                } else {
                    entityFieldId = entityName + "ContractId";
                }

                if (selectedEntityContractDTO[entityFieldId]) {
                    if (entityContractDTO[entityFieldId] === selectedEntityContractDTO[entityFieldId]) {
                        vm.entityContractDTOList[index].isDeleted = true;
                        return;
                    }
                } else {
                    if (entityContractDTO.contractDTO.contractId === selectedEntityContractDTO.contractDTO.contractId) {
                        vm.entityContractDTOList.splice(index, 1);
                        return;
                    }
                }
            });
        };

        // Editable
        vm.isAlegeContracteFormEditable = false;
        vm.toggleAlegeContracteFormEdit = function() {
            vm.isAlegeContracteFormEditable = !vm.isAlegeContracteFormEditable;
        };

        /**
         * Salveaza informatiile depre formular Alegere Contracte
         */
        vm.saveAlegeContracteForm = function() {
            var serverRequestArray = [];
            if (vm.parentName == 'DecisionMaker') {
                serverRequestArray.push(DecisionMakerContractService.save(vm.parentId, vm.entityContractDTOList));
            } else if (vm.parentName ==  'EvalCmteMember') {
                serverRequestArray.push(EvalCmteMemberContractService.save(vm.parentId, vm.entityContractDTOList));
            } else if (vm.parentName ==  'Tenderer') {
                serverRequestArray.push(ContractTendererService.save(vm.parentId, vm.entityContractDTOList));
            }

            vm.isLoadingData = true;
            $q.all(serverRequestArray).then(
                function success(responseArray) {
                    vm.entityContractDTOList = responseArray[0];
                    if (vm.entityContractDTOList.length > 0) {
                        vm.isContractSigner = true;
                    } else {
                        vm.isContractSigner = false;
                    }
                    vm.isAlegeContracteFormEditable = false;
                    $scope.formAlegeContracte.$pristine = true;
                    vm.isLoadingData = false;
                    SeapAlertService.toastSuccess("Lista de contracte salvata cu succes!", "Succes");

                }, function error(errMsg) {
                    vm.isLoadingData = false;
                    SeapAlertService.toastError("Eroare la salvare lista de contracte! Mesaj: " + errMsg, "Eroare");
                }
            );
        };

        // Sicronizare din SICAP pentru contracte atribuite
        vm.syncContractsFromSicap = function() {
            vm.isLoadingData = true;
            SicapSyncService.syncContractList(vm.acqProcedureId).then(
                function success(response) {
                    vm.initData();
                    SeapAlertService.toastSuccess("Preluarea listei de contracte din aplicatia Noul SEAP s-a efectuat cu succes!");
                    vm.isLoadingData = false;
                },
                function error(errorMsg) {
                    SeapAlertService.toastError(errorMsg);
                    vm.isLoadingData = false;
                }
            );
        };
    }
})();
