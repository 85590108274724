/**
 * Created by florin.iacob on 5/16/2017.
 */
(function() {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .controller('ContracteFormCtrl', ContracteFormCtrl);

    ContracteFormCtrl.$inject = ['$q', 'SeapAlertService', '$uibModal', 'ContracteFormService', 'Constants', 'Principal', 'SicapSyncService'];

    function ContracteFormCtrl($q, SeapAlertService, $uibModal, ContracteFormService, Constants, Principal, SicapSyncService) {

        var vm = this;
        /*
             acqProcedure: '=',
             isReadOnly: '='
             isLocal: '='
        */

        vm.contractList = [];
        vm.dataInitialized = false;

        vm.acqProcedureId = vm.acqProcedure.acqProcedureId;

        vm.hasRoleManualAddContract = vm.acqProcedure.isLocal && Principal.hasAnyAuthority(['ROLE_MANUAL_ADD_ALL', 'ROLE_MANUAL_ADD_CONTRACT']);
        vm.hasRoleExclusiveSyncAllSections = Principal.hasAnyAuthority(['ROLE_EXCLUSIVE_SYNC_ALL_SECTIONS']);

        /**
         * Initializeaza datele necesare in Controller
         */
        vm.initData = function() {
            vm.isLoadingData = true;
            var serverRequestArray = [];
            serverRequestArray.push(ContracteFormService.findByAcqProcedureId(vm.acqProcedureId));

            $q.all(serverRequestArray).then(
                function success(responseArray) {
                    vm.contractList = responseArray[0];
                    vm.isLoadingData = false;
                    vm.dataInitialized = true;
                }, function error(errorMsg) {
                    vm.isLoadingData = false;
                    SeapAlertService.toastError('Eroare la aducerea datelor despre Contracte. Mesaj: ' + errorMsg, 'Eroare');
                }
            );
        };

        if (vm.acqProcedureId) {
            vm.initData();
        }

        // Adaugare Contract
        vm.openSaveContractModal = function(contract) {
            var contractEntity = null;
            if (contract) {
                contractEntity = {};
                angular.copy(contract, contractEntity);
            }

            $uibModal.open({
                templateUrl: 'app/_seap/modals/create-contract/create-contract.html',
                controller: 'CreateContractCtrl',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    acqProcedureId: function() {
                        return vm.acqProcedureId;
                    },
                    acqProcedure: function() {
                        return vm.acqProcedure;
                    },
                    contractEntity: function() {
                        return contractEntity;
                    }
                }
            }).result.then(
                function success(responseContract) {
                    if (contract) {
                        angular.forEach(vm.contractList, function(contractItem, index) {
                            if (contractItem.contractId === responseContract.contractId) {
                                vm.contractList[index] = responseContract;
                                return;
                            }
                        });
                    } else {
                        vm.contractList.push(responseContract);
                    }
                }, function error(response) {
                    console.error(' -- Eroare salvare date in Modal AcqProcedureLot');
                }
            );
        };

        // Editable
        vm.isContracteFormEditable = false;
        vm.toggleContracteFormEdit = function() {
            vm.isContracteFormEditable = !vm.isContracteFormEditable;
        };

        /**
         * Salveaza informatiile depre fond UE
         */
        vm.saveContracteForm = function() {
            vm.isContracteFormEditable = false;
        };

        // Sterge Contract
        vm.deleteContract = function(contractId) {
            ContracteFormService.delete(contractId).then(
                function success(response) {
                    SeapAlertService.toastSuccess('Contract sters cu succes!', 'Succes');
                    angular.forEach(vm.contractList, function(contractItem, index) {
                        if (contractItem.contractId == contractId) {
                            vm.contractList.splice(index, 1);
                            return;
                        }
                    });
                }, function error(errorMsg) {
                    SeapAlertService.toastError('Eroare la stergerea Contractului. Mesaj: ' + errorMsg, 'Eroare');
                }
            );
        };

        // Sincronizare date lista contracte din SICAP
        vm.syncContractListFromSicap = function() {
            vm.isLoadingData = true;
            SicapSyncService.syncContractList(vm.acqProcedureId).then(
                function success(response) {
                    vm.initData();
                    SeapAlertService.toastSuccess("Preluarea listei de contracte din Noul SEAP s-a efectuat cu succes!");
                    vm.isLoadingData = false;
                },
                function error(errorMsg) {
                    SeapAlertService.toastError(errorMsg);
                    vm.isLoadingData = false;
                }
            );
        };
    }
})();
