/**
 * Created by florin.iacob on 5/22/2017.
 */
(function() {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .factory('EvalCmteMemberWorkRelService', EvalCmteMemberWorkRelService);

    EvalCmteMemberWorkRelService.$inject = ['$q', '$http', 'API_BASE_URL'];

    function EvalCmteMemberWorkRelService($q, $http, API_BASE_URL) {

        var Service = {};

        /**
         * Aducerea listei de entitati WorkRel pentru Membru comisie specificat
         */
        Service.findByEvalCmteMemberId = function(evalCmteMemberID) {
            var deferred = $q.defer();
            // make server request
            $http({
                method: 'GET',
                url: API_BASE_URL + '/evalCmteMemberWorkRel/findByEvalCmteMemberId/' + evalCmteMemberID,
                headers: {
                    'Content-Type': "application/json"
                }
            }).then(
                function success(response) {
                    deferred.resolve(response.data);

                }, function error(response) {
                    deferred.reject(response.data.message);
                    console.error(' -- REST call Error: ' + API_BASE_URL + '/evalCmteMemberWorkRel/findByEvalCmteMemberId/' + evalCmteMemberID);
                }
            );
            return deferred.promise;
        };

        /**
         * Actualizeaza lista de enititati WorkRel pentru Membru comisie specificat
         */
        Service.save = function(evalCmteMemberID, evalCmteMemberWorkRelDTOList) {
            var deferred = $q.defer();
            // make server request
            $http({
                method: 'PUT',
                url: API_BASE_URL + '/evalCmteMemberWorkRel/save/' + evalCmteMemberID,
                headers: {
                    'Content-Type': "application/json"
                },
                data: JSON.stringify(evalCmteMemberWorkRelDTOList)
            }).then(
                function success(response) {
                    deferred.resolve(response.data);

                }, function error(response) {
                    var errMsg = 'Eroare la salvarea Membrului de comisie!';
                    if (response.data && response.data.message) {
                        errMsg = response.data.message;
                    }
                    deferred.reject(errMsg);
                    console.error(' -- REST PUT call Error: /evalCmteMemberWorkRel/save/' + evalCmteMemberID);
                }
            );
            return deferred.promise;
        };

        return Service
    }
})();

