/**
 * Created by florin.iacob on 5/16/2017.
 */
(function() {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .controller('AlegeCalitateFormCtrl', AlegeCalitateFormCtrl);

    AlegeCalitateFormCtrl.$inject = ['$q', 'SeapAlertService', 'NomenclatorService', 'EvalCommitteMemberService', 'EvalCommitteExpertService',
        'TendererAssocMemberService', 'TendererParticipantService', '$scope'];

    function AlegeCalitateFormCtrl($q, SeapAlertService, NomenclatorService, EvalCommitteMemberService, EvalCommitteExpertService,
                                   TendererAssocMemberService, TendererParticipantService, $scope) {

        var vm = this;
        /*
            formName: '@',
            acqProcedureId: '=',
            parentEntity: '=',
            parentName: '@',
            isReadOnly: '='
        */

        vm.isLoadingData = false;
        vm.selectedCalitateId = null;

        /**
         * Initializeaza data Controller
         */
        vm.initData = function() {
            var serverRequestArray = [];
            if (vm.parentName === 'EvalCmteMember') {
                if (vm.parentEntity.nomEvalCmteMemberPoDTO) {
                    vm.selectedCalitateId = vm.parentEntity.nomEvalCmteMemberPoDTO.id;
                }
                serverRequestArray.push(NomenclatorService.findByKey('NomEvalCmteMemberPo'));

            } else if (vm.parentName === 'EvalCmteExpert') {
                if (vm.parentEntity.nomEvalCmteExpertPoDTO) {
                    vm.selectedCalitateId = vm.parentEntity.nomEvalCmteExpertPoDTO.id;
                }
                serverRequestArray.push(NomenclatorService.findByKey('NomEvalCmteExpertPo'));

            } else if (vm.parentName === 'TendererAssocMember') {
                if (vm.parentEntity.nomTendererAscMbrPoDTO) {
                    vm.selectedCalitateId = vm.parentEntity.nomTendererAscMbrPoDTO.id;
                }
                serverRequestArray.push(NomenclatorService.findByKey('NomTendererAscMbrPo'));

            } else if (vm.parentName === 'TendererParticipant') {
                if (vm.parentEntity.nomTendererPartPoDTO) {
                    vm.selectedCalitateId = vm.parentEntity.nomTendererPartPoDTO.id;
                }
                serverRequestArray.push(NomenclatorService.findByKey('NomTendererPartPo'));

            } else {
                SeapAlertService.toastError("Datele pentru alegere calitate nu pot fi aduse!");
                return;
            }

            vm.data = {nomenclatorCalitateList: []};
            vm.isLoadingData = true;
            $q.all(serverRequestArray).then(
                function success(responseArray) {
                    vm.data.nomenclatorCalitateList = responseArray[0];
                    vm.isLoadingData = false;
                }, function error(errMsg) {
                    vm.isLoadingData = false;
                    SeapAlertService.toastError("Eroare aducere date pentru alegere calitate!");
                }
            );
        };

        vm.initData();


        // Editable
        vm.isAlegeCalitateFormEditable = false;
        vm.toggleAlegeCalitateFormEdit = function() {
            vm.isAlegeCalitateFormEditable = !vm.isAlegeCalitateFormEditable;
        };

        /**
         * Salveaza informatiile depre formular Calitate
         */
        vm.saveAlegeCalitateForm = function() {
            var serverRequestArray = [];
            if (vm.parentName === 'EvalCmteMember') {
                vm.parentEntity.nomEvalCmteMemberPoDTO.id = vm.selectedCalitateId;
                serverRequestArray.push(EvalCommitteMemberService.update(vm.acqProcedureId, vm.parentEntity));
            } else if (vm.parentName === 'EvalCmteExpert') {
                vm.parentEntity.nomEvalCmteExpertPoDTO.id = vm.selectedCalitateId;
                serverRequestArray.push(EvalCommitteExpertService.update(vm.acqProcedureId, vm.parentEntity));
            } else if (vm.parentName === 'TendererAssocMember') {
                vm.parentEntity.nomTendererAscMbrPoDTO.id = vm.selectedCalitateId;
                serverRequestArray.push(TendererAssocMemberService.update(vm.acqProcedureId, vm.parentEntity));
            } else if (vm.parentName === 'TendererParticipant') {
                vm.parentEntity.nomTendererPartPoDTO.id = vm.selectedCalitateId;
                serverRequestArray.push(TendererParticipantService.update(vm.acqProcedureId, vm.parentEntity));
            }

            vm.isLoadingData = true;
            $q.all(serverRequestArray).then(
                function success(responseArray) {
                    vm.parantEntity = responseArray[0];
                    vm.isAlegeCalitateFormEditable = false;
                    vm.isLoadingData = false;
                    $scope.formAlegeCalitate.$setPristine();
                    SeapAlertService.toastSuccess("Salvare cu succes!");
                }, function error(errMsg) {
                    vm.isLoadingData = false;
                    SeapAlertService.toastError("Eroare la salvare date despre calitate in comisie! Mesaj eroare: " + errMsg);
                }
            );
        };
    }
})();
