/**
 * Created by florin.iacob on 6/8/2017.
 */
(function() {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .factory('FormConsultantiDocumentatieService', FormConsultantiDocumentatieService);

    FormConsultantiDocumentatieService.$inject = ['$http','$q','API_BASE_URL'];

    function FormConsultantiDocumentatieService ($http, $q, API_BASE_URL) {

        var service = {};

        /**
         * Aducerea listei de Consultanti pentru Procedura de Achizitia specificata
         */
        service.findByAcqProcedureId = function(acqProcedureId) {
            var deferred = $q.defer();
            // make server request
            $http({
                method: 'GET',
                url: API_BASE_URL + '/procurementConsultant/findByAcqProcedureId/' + acqProcedureId,
                headers: {
                    'Content-Type': "application/json"
                }
            }).then(
                function success(response) {
                    if (!response.data) {
                        deferred.reject('No data returned!');
                    }
                    deferred.resolve(response.data);

                }, function error(response) {
                    deferred.reject(response.data.message);
                    console.error(' -- REST call Error: ' + API_BASE_URL + '/procurementConsultant/findByAcqProcedureId/' + acqProcedureId);
                }
            );
            return deferred.promise;
        };

        /**
         * Aduce un Consultant pe baza ID-ului specificat
         */
        service.findById = function(procurementConsultantId) {
            var deferred = $q.defer();
            // make server request
            $http({
                method: 'GET',
                url: API_BASE_URL + '/procurementConsultant/findById/' + procurementConsultantId,
                headers: {
                    'Content-Type': "application/json"
                }
            }).then(
                function success(response) {
                    if (!response.data) {
                        deferred.reject('No data returned!');
                    }
                    deferred.resolve(response.data);

                }, function error(response) {
                    deferred.reject(response.data.message);
                    console.error(' -- REST call Error: ' + API_BASE_URL + '/procurementConsultant/findById/' + procurementConsultantId);
                }
            );
            return deferred.promise;
        };

        /**
         * Creaza un Consultant pentru o Procedura de Achizitie
         */
        service.create = function(acqProcedureId, procurementConsultantDTO) {
            var deferred = $q.defer();
            // make server request
            $http({
                method: 'POST',
                url: API_BASE_URL + '/procurementConsultant/create/' + acqProcedureId,
                headers: {
                    'Content-Type': "application/json"
                },
                data: JSON.stringify(procurementConsultantDTO)
            }).then(
                function success(response) {
                    deferred.resolve(response.data);

                }, function error(response) {
                    var errMsg = 'Eroare la salvarea Consultantului!';
                    if (response.data && response.data.message) {
                        errMsg = response.data.message;
                    }
                    deferred.reject(errMsg);
                    console.error(' -- REST POST call Error: /procurementConsultant/create/' + acqProcedureId);
                }
            );
            return deferred.promise;
        };

        /**
         * Actualizeaza un Consultant pentru o Procedura de Achizitie
         */
        service.update = function(acqProcedureId, procurementConsultantDTO) {
            var deferred = $q.defer();
            // make server request
            $http({
                method: 'PUT',
                url: API_BASE_URL + '/procurementConsultant/update/' + acqProcedureId,
                headers: {
                    'Content-Type': "application/json"
                },
                data: JSON.stringify(procurementConsultantDTO)
            }).then(
                function success(response) {
                    deferred.resolve(response.data);

                }, function error(response) {
                    var errMsg = 'Eroare la salvarea Consultantului!';
                    if (response.data && response.data.message) {
                        errMsg = response.data.message;
                    }
                    deferred.reject(errMsg);
                    console.error(' -- REST POST call Error: /procurementConsultant/update/' + acqProcedureId);
                }
            );
            return deferred.promise;
        };

        /**
         * Sterge un Consultant pentru o Procedura de Achizitie
         */
        service.delete = function(acqProcedureId, procurementConsultantId) {
            var deferred = $q.defer();
            // make server request
            $http({
                method: 'DELETE',
                url: API_BASE_URL + '/procurementConsultant/delete/' + acqProcedureId + "/" + procurementConsultantId,
                headers: {
                    'Content-Type': "application/json"
                }
            }).then(
                function success(response) {
                    deferred.resolve(response.data);

                }, function error(response) {
                    var errMsg = 'Eroare la stergerea Consultantului!';
                    if (response.data && response.data.message) {
                        errMsg = response.data.message;
                    }
                    deferred.reject(errMsg);
                    console.error(' -- REST POST call Error: /procurementConsultant/delete/' + acqProcedureId + "/" + procurementConsultantId);
                }
            );
            return deferred.promise;
        };

        return service;
    }
})();
