(function() {
    'use strict';

    var seapPageTitle = {
        template:
            '<div class="padding-bottom-10 padding-left-10">' +
            '   <h3>{{vm.sectionName}}</h3>' +
            '   <label>{{vm.procedureName}}</label>' +
            '</div>',
        controller: seapPageTitleController,
        controllerAs: 'vm',
        bindings: {
            sectionName: '@',
            procedureName: '='
        }
    };

    angular
        .module('seapAniFormsApp')
        .component('seapPageTitle', seapPageTitle);

    seapPageTitleController.$inject = ['$scope', 'AlertService', '$rootScope'];

    function seapPageTitleController ($scope, AlertService, $rootScope) {
        var vm = this;
    }
})();
