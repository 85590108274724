/**
 * Created by florin.iacob on 5/16/2017.
 */
(function() {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .component('adresaForm', {
            templateUrl: 'app/_seap/components/adresa-form/adresa-form.html',
            controller: 'AdresaFormCtrl',
            controllerAs: 'vm',
            bindings: {
                formTitle: '@',
                personId: '=',
                entityName: '@',
                isReadOnly: '='
            }
        });
})();
