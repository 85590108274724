(function () {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .factory('ProfileService', ProfileService);

    ProfileService.$inject = ['$http', '$q'];

    function ProfileService($http, $q) {

        var dataPromise;

        var service = {
            getProfileInfo: getProfileInfo,
            updateSeapInfo: updateSeapInfo
        };

        return service;

        function getProfileInfo() {
            if (angular.isUndefined(dataPromise)) {
                dataPromise = $http.get('api/profile-info').then(function (result) {
                    if (result.data.activeProfiles) {
                        var response = {};
                        response.activeProfiles = result.data.activeProfiles;
                        response.ribbonEnv = result.data.ribbonEnv;
                        response.inProduction = result.data.activeProfiles.indexOf("prod") !== -1;
                        response.swaggerEnabled = result.data.activeProfiles.indexOf("swagger") !== -1;
                        return response;
                    }
                });
            }
            return dataPromise;
        }

        function updateSeapInfo() {
            var deferred = $q.defer();
            // make server request
            $http.get('api/update-seap-info').then(
                function success(response) {
                    if (!response.data) {
                        deferred.reject('No data returned!');
                    }
                    deferred.resolve(response.data);

                }, function error(response) {
                    deferred.reject(response.data.message);
                    console.error(' -- REST call Error: api/update-info');
                }
            );
            return deferred.promise;
        }
    }
})();
