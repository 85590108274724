/**
 * Created by florin.iacob on 5/16/2017.
 */
(function() {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .controller('CategorieFunctieFormCtrl', CategorieFunctieFormCtrl);

    CategorieFunctieFormCtrl.$inject = ['$q', 'SeapAlertService', 'NomenclatorService', 'DecisionMakerFctCatService', 'EvalCmteExpertFctCatService', 'EvalCmteMemberFctCatService', '$scope'];

    function CategorieFunctieFormCtrl($q, SeapAlertService, NomenclatorService, DecisionMakerFctCatService, EvalCmteExpertFctCatService, EvalCmteMemberFctCatService, $scope) {

        var vm = this;
        /*
            parentId: '=',
            parentName: '@',
            isReadOnly: '='
        */
        var entityName;

        vm.initData = function() {
            var serverRequestsArray = [
                NomenclatorService.findByKey('NomFunctionCateg')
            ];
            if (vm.parentId) {
                if (vm.parentName ==  'DecisionMaker') {
                    serverRequestsArray.push(DecisionMakerFctCatService.findByDecisionMakerId(vm.parentId));
                    entityName = 'decisionMaker';
                } else if (vm.parentName ==  'EvalCmteExpert') {
                    serverRequestsArray.push(EvalCmteExpertFctCatService.findByEvalCmteExpertId(vm.parentId));
                    entityName = 'evalCmteExpert';
                } else if (vm.parentName ==  'EvalCmteMember') {
                    serverRequestsArray.push(EvalCmteMemberFctCatService.findByEvalCmteMemberId(vm.parentId));
                    entityName = 'evalCmteMember';
                } else {
                    SeapAlertService.toastError("Nu pot fi aduse date depre categorii functii!");
                    return;
                }
            }

            vm.data = {};

            // Aducere date Server
            vm.isLoadingData = true;
            $q.all(serverRequestsArray).then(
                function success(responseArray) {

                    vm.data.nomFunctionCategList = responseArray[0];
                    if (vm.parentId) {
                        vm.fctCatDTOList = responseArray[1];
                    } else {
                        vm.fctCatDTOList = [];
                    }

                    vm.isLoadingData = false;
                },
                function error(error) {
                    SeapAlertService.toastError('Eroare la aducere date despre Categorie Functie!');
                    vm.isLoadingData = false;
                }
            );
        };

        vm.initData();

        vm.isCategFunctionAsText = false;
        vm.selectedNomFunctionCategID = null;
        vm.functionCategAsText = null;

        // Adauga o noua data
        vm.addFunctie = function(selectedNomFunctionCategID) {
            var existent = false;
            if (vm.isCategFunctionAsText) {
                if (!vm.functionCategAsText) {
                    SeapAlertService.toastError('Nume categorie functie trebuie completat!');
                    return;
                }

                angular.forEach(vm.fctCatDTOList, function (functieItem, index) {
                    if (!functieItem.isDeleted && functieItem.functionCategText === vm.functionCategAsText) {
                        existent = true;
                        return;
                    }
                });
                if (existent) {
                    SeapAlertService.toastError('Categorie functie deja existenta!');
                } else {
                    vm.fctCatDTOList.push({
                        nomFunctionCategDTO: null,
                        functionCategText: vm.functionCategAsText
                    });
                }

            } else {
                angular.forEach(vm.fctCatDTOList, function (functieItem, index) {
                    if (!functieItem.isDeleted && functieItem.nomFunctionCategDTO != null
                            && functieItem.nomFunctionCategDTO.id === parseInt(selectedNomFunctionCategID)) {
                        existent = true;
                        return;
                    }
                });
                if (existent) {
                    SeapAlertService.toastError('Categorie functie deja existenta!');
                } else {
                    angular.forEach(vm.data.nomFunctionCategList, function (nomFunctionCateg, index) {
                        if (nomFunctionCateg.id === parseInt(selectedNomFunctionCategID)) {
                            $scope.formCategorieFunctie.$pristine = false;
                            vm.fctCatDTOList.push({
                                nomFunctionCategDTO: nomFunctionCateg
                            });
                            return;
                        }
                    });
                }
            }

        };

        // Sterge o Functie
        vm.deleteFunctie = function(functie) {
            $scope.formCategorieFunctie.$pristine = false;
            angular.forEach(vm.fctCatDTOList, function(functieItem, index) {
                if (functie[entityName + "FctCatId"]) {
                    if (functieItem[entityName + "FctCatId"] === functie[entityName + "FctCatId"]) {
                        vm.fctCatDTOList[index].isDeleted = true;
                        return;
                    }
                } else {
                    if ((functieItem.nomFunctionCategDTO && functie.nomFunctionCategDTO && functieItem.nomFunctionCategDTO.id === functie.nomFunctionCategDTO.id)
                            || (functieItem.functionCategText && functieItem.functionCategText === functie.functionCategText)) {
                        vm.fctCatDTOList.splice(index, 1);
                        return;
                    }
                }
            });
        };

        // Editable
        vm.isCategorieFunctieFormEditable = false;
        vm.toggleCategorieFunctieFormEdit = function() {
            vm.isCategorieFunctieFormEditable = !vm.isCategorieFunctieFormEditable;
        };

        /**
         * Salveaza informatiile depre formular Categorie Functie
         */
        vm.saveCategorieFunctieForm = function() {
            var serverRequestArray = [];
            if (vm.parentName ==  'DecisionMaker') {
                serverRequestArray.push(DecisionMakerFctCatService.save(vm.parentId, vm.fctCatDTOList));
            } else if (vm.parentName ==  'EvalCmteExpert') {
                serverRequestArray.push(EvalCmteExpertFctCatService.save(vm.parentId, vm.fctCatDTOList));
            } else if (vm.parentName ==  'EvalCmteMember') {
                serverRequestArray.push(EvalCmteMemberFctCatService.save(vm.parentId, vm.fctCatDTOList));
            }

            vm.isLoadingData = true;
            $q.all(serverRequestArray).then(
                function success(responseArray) {
                    vm.fctCatDTOList = responseArray[0];
                    vm.isCategorieFunctieFormEditable = false;
                    vm.isLoadingData = false;
                    $scope.formCategorieFunctie.$pristine = true;
                    SeapAlertService.toastSuccess("Lista de functii salvata cu succes!", "Succes");

                }, function error(errMsg) {
                    vm.isLoadingData = false;
                    SeapAlertService.toastError("Eroare la salvare lista de functii! Mesaj eroare: " + errMsg, "Eroare");
                }
            );
        };

        vm.onCategChange = function(selectedNomFunctionCategID) {
            vm.isCategFunctionAsText = false;
            angular.forEach(vm.data.nomFunctionCategList, function (nomFunctionCateg, index) {
                if (nomFunctionCateg.id === parseInt(selectedNomFunctionCategID)) {
                    if (nomFunctionCateg.code == '4') {
                        vm.isCategFunctionAsText = true;
                    }
                    return;
                }
            });
        }
    }
})();
