/**
 * Created by florin.iacob on 5/16/2017.
 */
(function () {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .factory('GenericService', GenericService);

    GenericService.$inject = ['$http', '$q', 'API_BASE_URL'];

    function GenericService($http, $q, API_BASE_URL) {

        var service = {};

        service.GetFn = function (path) {
            var deferred = $q.defer();
            // make server request
            $http({
                method: 'GET',
                url: API_BASE_URL + path,
                headers: {
                    'Content-Type': "application/json"
                }
            }).then(
                function success(response) {
                    if (!response.data) {
                        deferred.reject('No data returned!');
                    }
                    deferred.resolve(response.data);

                }, function error(response) {
                    deferred.reject(response.data.message);
                    console.error(' -- REST call Error: ' + API_BASE_URL + path);
                }
            );
            return deferred.promise;
        };

        service.GetAsXMLFn = function (path) {
            var deferred = $q.defer();
            // make server request
            $http({
                method: 'GET',
                url: path,  // Query Parameters (GET)
                transformResponse : function(data) {
                    // string -> XML document object
                    return $.parseXML(data);
                }
            }).then(
                function success(response) {
                    if (!response.data) {
                        deferred.reject('No data returned!');
                    }
                    deferred.resolve(response.data);

                }, function error(response) {
                    deferred.reject(response.data.message);
                    console.error(' -- REST call Error: ' + path);
                }
            );
            return deferred.promise;
        };

        service.PostFn = function (path, dataDTO) {
            var deferred = $q.defer();
            // make server request
            $http({
                method: 'POST',
                url: API_BASE_URL + path,
                headers: {
                    'Content-Type': "application/json"
                },
                data: JSON.stringify(dataDTO)
            }).then(
                function success(response) {
                    if (!response.data) {
                        deferred.reject('No data returned!');
                    }
                    deferred.resolve(response.data);

                }, function error(response) {
                    deferred.reject(response.data.message);
                    console.error(' -- REST POST call Error: ' + API_BASE_URL + path);
                }
            );
            return deferred.promise;
        };

        service.PutFn = function (path, dataDTO) {
            var deferred = $q.defer();
            // make server request
            $http({
                method: 'PUT',
                url: API_BASE_URL + path,
                headers: {
                    'Content-Type': "application/json"
                },
                data: JSON.stringify(dataDTO)
            }).then(
                function success(response) {
                    if (!response.data) {
                        deferred.reject('No data returned!');
                    }
                    deferred.resolve(response.data);

                }, function error(response) {
                    deferred.reject(response.data.message);
                    console.error(' -- REST PUT call Error: ' + API_BASE_URL + path);
                }
            );
            return deferred.promise;
        };

        /**
         * Sterge un Factor decizional pentru o Procedura de Achizitie
         */
        service.DeleteFn = function(path) {
            var deferred = $q.defer();
            // make server request
            $http({
                method: 'DELETE',
                url: API_BASE_URL + path,
                headers: {
                    'Content-Type': "application/json"
                }
            }).then(
                function success(response) {
                    deferred.resolve(response.data);

                }, function error(response) {
                    var errMsg = 'Eroare la stergere!';
                    if (response.data && response.data.message) {
                        errMsg = response.data.message;
                    }
                    deferred.reject(errMsg);
                    console.error(' -- REST DELETE call Error: ' + API_BASE_URL + path);
                }
            );
            return deferred.promise;
        };

        return service;
    }
})();
