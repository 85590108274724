/**
 * Created by florin.iacob on 5/16/2017.
 */
(function() {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .component('alegeCalitateForm', {
            templateUrl: 'app/_seap/components/alege-calitate-form/alege-calitate-form.html',
            controller: 'AlegeCalitateFormCtrl',
            controllerAs: 'vm',
            bindings: {
                formName: '@',
                acqProcedureId: '=',
                parentEntity: '=',
                parentName: '@',
                isReadOnly: '='
            }
        });
})();
