/**
 * Created by florin.iacob on 5/16/2017.
 */
(function () {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .factory('IntegrityFormService', IntegrityFormService);

    IntegrityFormService.$inject = ['$http', '$q', 'API_BASE_URL', 'GenericService', '$uibModal'];

    function IntegrityFormService($http, $q, API_BASE_URL, GenericService, $uibModal) {

        var service = {};

        /**
         * Aducerea listei de Formulare Integritate pentru utilizatorul conectat
         */
        service.findAllForUser = function () {
            return GenericService.GetFn('/integrityForm/findAll');
        };

        /**
         * Aducerea listei de Formulare Integritate pe baza filtrelor specificate
         *
         * @param filterDTO
         */
        service.findAllByFilter = function(filterDTO, page, limit) {
            var postUrl = '/integrityForm/findAllByFilter';
            if (page) {
                postUrl += '?page=' + page + '&limit=' + limit;
            }
            return GenericService.PostFn(postUrl, filterDTO);
        };

        /**
         * Aducerea unui Formular de Integritate pentru utilizatorul conectat
         */
        service.findById = function (idIntegrityForm) {
            return GenericService.GetFn('/integrityForm/findById/' + idIntegrityForm)
        };

        /**
         * Stergrea unui Formular de Integritate pentru utilizatorul conectat
         */
        service.delete = function (idIntegrityForm) {
            return GenericService.DeleteFn('/integrityForm/delete/' + idIntegrityForm);
        };

        /**
         * Aducerea unui Formular de Integritate pe baza ID-ului de procedura prezentat
         */
        service.findByAcqProcedureId = function (acqProcedureId) {
            return GenericService.GetFn('/integrityForm/findByAcqProcedureId/' + acqProcedureId);
        };

        /**
         * Salvare stare Formular de Integritate pe baza ID-ului de procedura prezentat
         */
        service.changePublicationState = function (idIntegrityForm, integrityFormState) {
            return GenericService.GetFn('/integrityForm/setPublicationState/' + idIntegrityForm + '/' + integrityFormState);
        };

        /**
         * sincronizare proceduri din SEAP
         */
        service.syncSeapAcqProcedures = function () {
            return GenericService.GetFn('/integrityForm/sync-seap-acqProcedures');
        };

        /**
         * sincronizare proceduri din SEAP
         */
        service.getDashboardCounters = function () {
            return GenericService.GetFn('/integrityForm/getDashboardCounters');
        };

        service.filterByUnpublishedMeasureTaken = function () {
            return GenericService.GetFn('/integrityForm/filterByUnpublishedMeasureTaken');
        };

        service.filterByIntegrityWarningsReceived = function () {
            return GenericService.GetFn('/integrityForm/filterByIntegrityWarningsReceived');
        };

        service.filterByUnpublishedAcqProcedures = function () {
            return GenericService.GetFn('/integrityForm/filterByUnpublishedAcqProcedures');
        };

        return service;
    }
})();
