/**
 * Created by florin.iacob on 5/16/2017.
 */
(function() {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .factory('IntegrityWarningService', IntegrityWarningService);

    IntegrityWarningService.$inject = ['$http','$q','API_BASE_URL', 'GenericService'];

    function IntegrityWarningService ($http, $q, API_BASE_URL, GenericService) {

        var service = {};


        /**
         * Aducerea unui Formular de Integritate pentru utilizatorul conectat
         */
        service.findById = function(idIntegrityWarning) {
            var deferred = $q.defer();
            // make server request
            $http({
                method: 'GET',
                url: API_BASE_URL + '/integrityWarning/findById/' + idIntegrityWarning,
                headers: {
                    'Content-Type': "application/json"
                }
            }).then(
                function success(response) {
                    if (!response.data) {
                        deferred.reject('No data returned!');
                    }
                    deferred.resolve(response.data);

                }, function error(response) {
                    deferred.reject(response.data.message);
                    console.error(' -- REST call Error: ' + API_BASE_URL + '/integrityWarning/findById/' + idIntegrityWarning);
                }
            );
            return deferred.promise;
        };

        /**
         * Aducerea unui Formular de Integritate pe baza ID-ului de formular de integritate
         */
        service.findByIntegrityFormId = function(integrityFormId) {
            var deferred = $q.defer();
            // make server request
            $http({
                method: 'GET',
                url: API_BASE_URL + '/integrityWarning/findByIntegrityFormId/' + integrityFormId,
                headers: {
                    'Content-Type': "application/json"
                }
            }).then(
                function success(response) {
                    if (!response.data) {
                        deferred.reject('No data returned!');
                    }
                    deferred.resolve(response.data);

                }, function error(response) {
                    deferred.reject(response.data.message);
                    console.error(' -- REST call Error: ' + API_BASE_URL + '/integrityWarning/findByIntegrityFormId/' + integrityFormId);
                }
            );
            return deferred.promise;
        };

        /**
         * Aducerea listei de avertismente pentru ultima versiune de formular publicata
         */
        service.getWarningListForLastVersion = function (integrityFormId, acqProcedureId) {
            var url = '/integrityWarning/getWarningListForLastVersion?';
            if (integrityFormId) {
                url += "integrityFormId=" + integrityFormId;
            } else {
                url += "acqProcedureId=" + acqProcedureId;
            }

            return GenericService.GetFn(url);
        };

        return service;
    }
})();
