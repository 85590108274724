/**
 * Confirm Modal Dialog
 */
(function () {
    'use strict';
    angular.module('seapAniFormsApp')
        .controller('NgConfirmCtrl', ['$scope', '$uibModalInstance', 'confirmClick', 'confirmMessage',
            function ($scope, $uibModalInstance, confirmClick, confirmMessage) {
                $scope.confirmMessage = confirmMessage;

                function _closeModal() {
                    $uibModalInstance.dismiss('cancel');
                }

                $scope.ok = function () {
                    confirmClick();
                    _closeModal();
                };

                $scope.cancel = function () {
                    _closeModal();
                };
            }]);

})();
