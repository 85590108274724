(function() {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .controller('PublicationConfirmCtrl', PublicationConfirmCtrl);

    PublicationConfirmCtrl.$inject = ['SeapAlertService', '$q', 'NomenclatorService', '$uibModalInstance', 'AcqProcedureLotService', 'publicationOptions'];

    function PublicationConfirmCtrl(SeapAlertService, $q, NomenclatorService, $uibModalInstance, AcqProcedureLotService, publicationOptions) {
        var vm = this;

        vm.clear = clear;
        vm.confirmPublication = confirmPublication;

        vm.publicationOptions = publicationOptions;

        /**
         * Initializeaza datele controller-ului
         */
        vm.initData = function() {
            vm.isLoadingData = false;
        };

        vm.initData();

        /**
         * Salveaza entitate AcqProcedureLot
         */
        function confirmPublication () {
            $uibModalInstance.close('succes');
        }

        /**
         * Close modal
         */
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }
    }
})();
