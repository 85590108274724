/**
 * Created by florin.iacob on 5/22/2017.
 */
(function() {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .factory('AcqProcLotTendererService', AcqProcLotTendererService);

    AcqProcLotTendererService.$inject = ['$q', '$http', 'API_BASE_URL'];

    function AcqProcLotTendererService($q, $http, API_BASE_URL) {

        var Service = {};

        /**
         * Aducerea listei de Loturi pentru Ofertantul specificat
         */
        Service.findByTendererId = function(tendererID) {
            var deferred = $q.defer();
            // make server request
            $http({
                method: 'GET',
                url: API_BASE_URL + '/acqProcLotTenderer/findByTendererId/' + tendererID,
                headers: {
                    'Content-Type': "application/json"
                }
            }).then(
                function success(response) {
                    deferred.resolve(response.data);

                }, function error(response) {
                    deferred.reject(response.data.message);
                    console.error(' -- REST call Error: ' + API_BASE_URL + '/acqProcLotTenderer/findByTendererId/' + tendererID);
                }
            );
            return deferred.promise;
        };

        /**
         * Actualizeaza lista de Loturi pentru Ofertantul specificat
         */
        Service.save = function(tendererID, acqProcLotTendererDTOList) {
            var deferred = $q.defer();
            // make server request
            $http({
                method: 'PUT',
                url: API_BASE_URL + '/acqProcLotTenderer/save/' + tendererID,
                headers: {
                    'Content-Type': "application/json"
                },
                data: JSON.stringify(acqProcLotTendererDTOList)
            }).then(
                function success(response) {
                    deferred.resolve(response.data);

                }, function error(response) {
                    var errMsg = 'Eroare la asocierea Listei de loturi!';
                    if (response.data && response.data.message) {
                        errMsg = response.data.message;
                    }
                    deferred.reject(errMsg);
                    console.error(' -- REST PUT call Error: /acqProcLotTenderer/save/' + tendererID);
                }
            );
            return deferred.promise;
        };

        return Service
    }
})();

