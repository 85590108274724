/**
 * Created by florin.iacob on 6/8/2017.
 */
(function() {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .factory('EvalCommitteMemberService', EvalCommitteMemberService);

    EvalCommitteMemberService.$inject = ['$http','$q','API_BASE_URL'];

    function EvalCommitteMemberService ($http, $q, API_BASE_URL) {

        var service = {};

        /**
         * Aduce un Membru comisie pe baza ID-ului specificat
         */
        service.findById = function(evalCommitteeMemberId) {
            var deferred = $q.defer();
            // make server request
            $http({
                method: 'GET',
                url: API_BASE_URL + '/evalCommitteeMember/findById/' + evalCommitteeMemberId,
                headers: {
                    'Content-Type': "application/json"
                }
            }).then(
                function success(response) {
                    if (!response.data) {
                        deferred.reject('No data returned!');
                    }
                    deferred.resolve(response.data);

                }, function error(response) {
                    deferred.reject(response.data.message);
                    console.error(' -- REST call Error: ' + API_BASE_URL + '/evalCommitteeMember/findById/' + evalCommitteeMemberId);
                }
            );
            return deferred.promise;
        };

        /**
         * Creaza un Membru comisie pentru o Procedura de Achizitie
         */
        service.create = function(acqProcedureId, evalCommitteeId, evalCommitteeMemberDTO) {
            var deferred = $q.defer();
            // make server request
            $http({
                method: 'POST',
                url: API_BASE_URL + '/evalCommitteeMember/create/' + acqProcedureId + '/' + evalCommitteeId,
                headers: {
                    'Content-Type': "application/json"
                },
                data: JSON.stringify(evalCommitteeMemberDTO)
            }).then(
                function success(response) {
                    deferred.resolve(response.data);

                }, function error(response) {
                    var errMsg = 'Eroare la salvarea Membrului comisie!';
                    if (response.data && response.data.message) {
                        errMsg = response.data.message;
                    }
                    deferred.reject(errMsg);
                    console.error(' -- REST POST call Error: /evalCommitteeMember/create/' + acqProcedureId + '/' + evalCommitteeId);
                }
            );
            return deferred.promise;
        };

        /**
         * Actualizeaza un Factor Decizional pentru o Procedura de Achizitie
         */
        service.update = function(acqProcedureId, evalCommitteeMemberDTO) {
            var deferred = $q.defer();
            // make server request
            $http({
                method: 'PUT',
                url: API_BASE_URL + '/evalCommitteeMember/update/' + acqProcedureId,
                headers: {
                    'Content-Type': "application/json"
                },
                data: JSON.stringify(evalCommitteeMemberDTO)
            }).then(
                function success(response) {
                    deferred.resolve(response.data);

                }, function error(response) {
                    var errMsg = 'Eroare la salvarea Membrului comisie!';
                    if (response.data && response.data.message) {
                        errMsg = response.data.message;
                    }
                    deferred.reject(errMsg);
                    console.error(' -- REST POST call Error: /evalCommitteeMember/update/' + acqProcedureId);
                }
            );
            return deferred.promise;
        };

        /**
         * Sterge un Factor decizional pentru o Procedura de Achizitie
         */
        service.delete = function(acqProcedureId, evalCommitteeMemberId) {
            var deferred = $q.defer();
            // make server request
            $http({
                method: 'DELETE',
                url: API_BASE_URL + '/evalCommitteeMember/delete/' + acqProcedureId + "/" + evalCommitteeMemberId,
                headers: {
                    'Content-Type': "application/json"
                }
            }).then(
                function success(response) {
                    deferred.resolve(response.data);

                }, function error(response) {
                    var errMsg = 'Eroare la stergerea Membrului comisie!';
                    if (response.data && response.data.message) {
                        errMsg = response.data.message;
                    }
                    deferred.reject(errMsg);
                    console.error(' -- REST POST call Error: /evalCommitteeMember/delete/' + acqProcedureId + "/" + evalCommitteeMemberId);
                }
            );
            return deferred.promise;
        };

        return service;
    }
})();
