/**
 * Created by florin.iacob on 5/16/2017.
 */
(function() {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .controller('AlegeLoturiCastigateFormCtrl', AlegeLoturiCastigateFormCtrl);

    AlegeLoturiCastigateFormCtrl.$inject = ['$q', 'SeapAlertService', 'AcqProcedureLotService', 'AcqProcLotTendererService', 'Constants', '$scope'];

    function AlegeLoturiCastigateFormCtrl($q, SeapAlertService, AcqProcedureLotService, AcqProcLotTendererService, Constants, $scope) {

        var vm = this;
        /*
            acqProcedureId: '=',
            parentId: '=',
            parentName: '@',
            contractTendererList: '=',
            isWinner: '=',
            isReadOnly: '='
         */

        vm.INTEGRITY_FORM_STATE = Constants.INTEGRITY_FORM_STATE;

        vm.initData = function() {
            var serverRequestsArray = [
                AcqProcedureLotService.findByAcqProcedureId(vm.acqProcedureId)
            ];
            if (vm.parentId) {
                serverRequestsArray.push(AcqProcLotTendererService.findByTendererId(vm.parentId));
            }

            vm.data = {};

            // Aducere date Server
            vm.isLoadingData = true;
            $q.all(serverRequestsArray).then(
                function success(responseArray) {
                    vm.data.acqProcedureLotList = responseArray[0];

                    if (vm.parentId) {
                        vm.acqProcLotTendererList = responseArray[1];
                    } else {
                        vm.acqProcLotTendererList = [];
                    }

                    vm.isLoadingData = false;
                },
                function error(error) {
                    SeapAlertService.toastError('Eroare la aducere date despre Loturi!');
                    vm.isLoadingData = false;
                }
            );
        };

        vm.initData();

        vm.selectedLotId = null;

        // Adauga un nou Lot
        vm.addLot = function(selectedLotId) {
            var existent = false;
            angular.forEach(vm.acqProcLotTendererList, function(acqProcLotTenderer, index) {
                if (!acqProcLotTenderer.isDeleted && acqProcLotTenderer.acqProcedureLotDTO.acqProcedureLotId === parseInt(selectedLotId)) {
                    existent = true;
                    return;
                }
            });

            if (existent) {
                SeapAlertService.toastError('Lot deja existent!');
            } else {
                angular.forEach(vm.data.acqProcedureLotList, function (acqProcedureLot, index) {
                    if (acqProcedureLot.acqProcedureLotId === parseInt(selectedLotId)) {
                        vm.acqProcLotTendererList.push({
                            acqProcedureLotDTO: acqProcedureLot,
                            isWinner: false
                        });
                        $scope.formLoturiCastigate.$pristine = false;
                        return;
                    }
                });
            }

        };

        // Sterge un Lot
        vm.deleteLot = function(selectedAcqProcLotTenderer) {
            $scope.formLoturiCastigate.$pristine = false;
            angular.forEach(vm.acqProcLotTendererList, function(acqProcLotTenderer, index) {
                if (selectedAcqProcLotTenderer.acqProcLotTendererId) {
                    if (acqProcLotTenderer.acqProcLotTendererId === selectedAcqProcLotTenderer.acqProcLotTendererId) {
                        vm.acqProcLotTendererList[index].isDeleted = true;
                        return;
                    }
                } else {
                    if (acqProcLotTenderer.acqProcedureLotDTO.acqProcedureLotId === selectedAcqProcLotTenderer.acqProcedureLotDTO.acqProcedureLotId) {
                        vm.acqProcLotTendererList.splice(index, 1);
                        return;
                    }
                }
            });
        };

        // Editable
        vm.isAlegeLoturiFormEditable = false;
        vm.toggleAlegeLoturiFormEdit = function() {
            vm.isAlegeLoturiFormEditable = !vm.isAlegeLoturiFormEditable;
        };

        /**
         * Salveaza informatiile depre formular Alege Loturi
         */
        vm.saveAlegeLoturiForm = function() {
            var serverRequestArray = [];
            serverRequestArray.push(AcqProcLotTendererService.save(vm.parentId, vm.acqProcLotTendererList));

            vm.isLoadingData = true;
            $q.all(serverRequestArray).then(
                function success(responseArray) {
                    if (responseArray[0].acqProcLotTendererDTOList) {
                        vm.acqProcLotTendererList = responseArray[0].acqProcLotTendererDTOList;
                        vm.contractTendererList = responseArray[0].contractTendererDTOList;
                    } else {
                        vm.acqProcLotTendererList = responseArray[0];
                    }
                    vm.isWinner = false;
                    angular.forEach(vm.acqProcLotTendererList, function(acqProcLotTenderer, index) {
                        if (acqProcLotTenderer.isWinner) {
                            vm.isWinner = true;
                        }
                    });
                    vm.isAlegeLoturiFormEditable = false;
                    vm.isLoadingData = false;
                    $scope.formLoturiCastigate.$setPristine();
                    SeapAlertService.toastSuccess("Asocierea loturilor salvata cu succes!", "Succes");

                }, function error(errMsg) {
                    vm.isLoadingData = false;
                    vm.initData();
                    SeapAlertService.toastError("Eroare la asocierea listei de loturi! Mesaj: " + errMsg, "Eroare");
                }
            );
        };
    }
})();
