(function() {
    'use strict';

    var validationErrorPrinter = {
        template:
            '<label for="errorNo">{{vm.completeMessage | limitTo: vm.completeMessage.lastIndexOf(\', \')}}</label>' +
            '<br/>' +
            '<span id="errorNo">' +
            '   <i class="fa fa-caret-right"></i> ' +
            '   {{vm.completeMessage | limitTo: (vm.completeMessage.length - vm.completeMessage.lastIndexOf(\', \')) : (vm.completeMessage.lastIndexOf(\', \') + 1)}}' +
            '</span>',
        controller: validationErrorPrinterController,
        controllerAs: 'vm',
        bindings: {
            completeMessage: '@'
        }
    };

    angular
        .module('seapAniFormsApp')
        .component('validationErrorPrinter', validationErrorPrinter);

    validationErrorPrinterController.$inject = ['$scope', 'AlertService', '$rootScope'];

    function validationErrorPrinterController ($scope, AlertService, $rootScope) {
        var vm = this;
    }
})();
