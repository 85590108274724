/**
 * Created by florin.iacob on 6/26/2017.
 */
(function() {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .factory('FiltreCautareProceduriService', FiltreCautareProceduriService);

    FiltreCautareProceduriService.$inject = ['$http','$q','API_BASE_URL', 'Principal'];

    function FiltreCautareProceduriService ($http, $q, API_BASE_URL, Principal) {

        var Service = {};

        var filterDTO = null;

        var isFilterApplicable = false;

        var currentPage = 1;
        var itemsPerPage = null;

        /**
         * Initializare obiect filtrare
         *
         * @returns {*}
         */
        Service.initFilterDTO = function() {
            filterDTO = {
                procedureCode: null,
                nomFormStateDTO: {id: null},
                nomPublicationTypeDTO: {id: null},
                nomAcqProcedureStateDTO: {id: 3, name: "In desfasurare"},
                publicationDateStart: null,
                publicationDateEnd: null,
                hasReceivedWarnings: Principal.hasAnyAuthority(['ROLE_OP_ANAP_FI_WARNING']),
                hasWarningsWithoutMeasures: false,
                hasUnpublishedMeasures: false,
                hasTenderSubmitted: false
            };
            return filterDTO;
        };

        /**
         * Setare obiect de filtrare Proceduri
         *
         * @param currentFilterDTO
         */
        Service.setFilterDTO = function(currentFilterDTO) {
            filterDTO = currentFilterDTO;
            if (filterDTO != null) {
                filterByUnpublishedAcqProceduresActivated = false;
                filterByIntegrityWarningsReceivedActivated = false;
                filterByUnpublishedMeasureTakenActivated = false ;
            }
        };

        /**
         * Aducere obiect de filtrare Proceduri
         *
         * @returns {*}
         */
        Service.getFilterDTO = function() {
            return filterDTO;
        };

        /**
         * Setare pagina curenta pentru Paginare
         *
         * @param currentFilterDTO
         */
        Service.setCurrentPage = function(currentPageNo) {
            currentPage = currentPageNo;
        };

        /**
         * Aducere pagina curenta pentru Paginare
         *
         * @returns {*}
         */
        Service.getCurrentPage = function() {
            return currentPage;
        };

        /**
         * Setare numar de elemente per pagina
         *
         * @param currentFilterDTO
         */
        Service.setItemsPerPage = function(itemsPerPageVal) {
            itemsPerPage = itemsPerPageVal;
        };

        /**
         * Aducere numar de elemente per pagina
         *
         * @returns {*}
         */
        Service.getItemsPerPage = function() {
            return itemsPerPage;
        };

        /**
         * Verifica daca filtrul este aplicabil in momentul apelarii
         *
         * @returns {boolean}
         */
        Service.isFilterApplicable = function() {
            return isFilterApplicable != null ? isFilterApplicable : (filterDTO == null ? false : true);
        };

        /**
         * Seteaza valoare pentru aplicarea filtrului
         *
         * @returns {boolean}
         */
        Service.setIsFilterApplicable = function(isFilterOpen) {
            isFilterApplicable = isFilterOpen;
        };

        var filterByUnpublishedAcqProceduresActivated = false;
        var filterByIntegrityWarningsReceivedActivated = false;
        var filterByUnpublishedMeasureTakenActivated = false ;

        Service.isFilterByUnpublishedAcqProceduresActivated = function() {
            return filterByUnpublishedAcqProceduresActivated;
        };

        Service.setFilterByUnpublishedAcqProceduresActivated = function(isFilterByUnpublishedAcqProceduresActivated) {
            filterByUnpublishedAcqProceduresActivated = isFilterByUnpublishedAcqProceduresActivated;
            if (filterByUnpublishedAcqProceduresActivated) {
                var filterDTOinit = Service.initFilterDTO();
                filterDTOinit.nomFormStateDTO.id = 0;
                filterDTOinit.hasTenderSubmitted = true;
                filterDTOinit.hasReceivedWarnings = null;
                filterDTOinit.nomAcqProcedureStateDTO = {id: null, name: null};
                isFilterApplicable = true;
                Service.setFilterDTO(filterDTOinit);
            }
            filterByIntegrityWarningsReceivedActivated = false;
            filterByUnpublishedMeasureTakenActivated = false ;
        };

        Service.isFilterByIntegrityWarningsReceivedActivated = function() {
            return filterByIntegrityWarningsReceivedActivated;
        };

        Service.setFilterByIntegrityWarningsReceivedActivated = function(isFilterByIntegrityWarningsReceivedActivated) {
            filterByIntegrityWarningsReceivedActivated = isFilterByIntegrityWarningsReceivedActivated;
            if (filterByIntegrityWarningsReceivedActivated) {
                var filterDTOinit = Service.initFilterDTO();
                filterDTOinit.hasWarningsWithoutMeasures = true;
                filterDTOinit.hasReceivedWarnings = null;
                filterDTOinit.nomAcqProcedureStateDTO = {id: null, name: null};
                isFilterApplicable = true;
                Service.setFilterDTO(filterDTOinit);
            }
            filterByUnpublishedAcqProceduresActivated = false;
            filterByUnpublishedMeasureTakenActivated = false ;
        };

        Service.isFilterByUnpublishedMeasureTakenActivated = function() {
            return filterByUnpublishedMeasureTakenActivated;
        };

        Service.setFilterByUnpublishedMeasureTakenActivated = function(isFilterByUnpublishedMeasureTakenActivated) {
            filterByUnpublishedMeasureTakenActivated = isFilterByUnpublishedMeasureTakenActivated;
            if (filterByUnpublishedMeasureTakenActivated) {
                var filterDTOinit = Service.initFilterDTO();
                filterDTOinit.hasUnpublishedMeasures = true;
                filterDTOinit.hasReceivedWarnings = null;
                filterDTOinit.nomAcqProcedureStateDTO = {id: null, name: null};
                isFilterApplicable = true;
                Service.setFilterDTO(filterDTOinit);
            }
            filterByUnpublishedAcqProceduresActivated = false;
            filterByIntegrityWarningsReceivedActivated = false;
        };

        return Service;
    }
})();
