/**
 * Created by florin.iacob on 5/16/2017.
 */
(function() {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('integrity-form-listare', {
                parent: 'seap',
                url: '/integrity-from-listare',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'FORMULARE INTEGRITATE - lista'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/_seap/entities/integrity-form/listare/integrity-form-listare.html',
                        controller: 'IntegrityFormListareCtrl',
                        controllerAs: 'vm'
                    }
                },
                params: {

                },
                resolve: {
                    urlParams: ['$stateParams', function ($stateParams) {
                        return {};
                    }]
                }
            })
            .state('integrity-form-save', {
                parent: 'seap',
                url: '/integrity-form-save?form&proc',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'Formular integritate - salvare'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/_seap/entities/integrity-form/save/integrity-form-save.html',
                        controller: 'IntegrityFormSaveCtrl',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    form: {
                        value: '',
                        squash: true
                    },
                    proc: {
                        value: '',
                        squash: true
                    }
                },
                resolve: {
                    urlParams: ['$stateParams', function ($stateParams) {
                        return {
                            idIntegrityForm: $stateParams.form,
                            idAcqProcedure: $stateParams.proc
                        };
                    }]
                }
            })
            .state('integrity-form-print', {
                parent: 'seap',
                url: '/integrity-form-print?form&proc',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'Imprimare versiune formular'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/_seap/entities/integrity-form/print/integrity-form-print.html',
                        controller: 'IntegrityFormPrintCtrl',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    form: {
                        value: '',
                        squash: true
                    },
                    proc: {
                        value: '',
                        squash: true
                    }
                },
                resolve: {
                    urlParams: ['$stateParams', function ($stateParams) {
                        return {
                            idIntegrityForm: $stateParams.form,
                            idAcqProcedure: $stateParams.proc
                        };
                    }]
                }
            });
    }

})();
