/**
 * Created by florin.iacob on 5/16/2017.
 */
(function () {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .controller('IntegrityFormSaveCtrl', IntegrityFormSaveCtrl);

    IntegrityFormSaveCtrl.$inject = ['$state', '$q', '$uibModal', 'SeapAlertService', 'urlParams', 'IntegrityFormService', 'IntegrityWarningService', 'Constants', 'Principal', 'SicapSyncService', 'PublicationValidationService', 'AutoritateContractantaViewService'];

    function IntegrityFormSaveCtrl($state, $q, $uibModal, SeapAlertService, urlParams, IntegrityFormService, IntegrityWarningService, Constants, Principal, SicapSyncService, PublicationValidationService, AutoritateContractantaViewService) {

        var vm = this;
        vm.isEditMode = false;
        vm.isLoadingData = false;
        vm.isAC = Principal.hasAnyAuthority(['ROLE_AC']);
        vm.isVerifyInProgress = false;
        vm.isPublishInProgress = false;

        vm.enableImportDateDinSICAP = false;
        vm.INTEGRITY_FORM_STATE = Constants.INTEGRITY_FORM_STATE;
        vm.INTEGRITY_FORM_SECTION = Constants.INTEGRITY_FORM_SECTION;

        vm.hasRoleManualAddAcqProcedure = Principal.hasAnyAuthority(['ROLE_MANUAL_ADD_ALL', 'ROLE_MANUAL_ADD_ACQ_PROCEDURE']);

        // TODO: de completat in IntegrityFormDTO cu lastIntegrityFormVersion si initializat cu tipul de publicare de pe ultima versiune de formular de integritate publicata
        vm.integrityFormState;
        // o stare de publicare in urma pentru a putea face rollback in cazul in care salvarea nu se permite din cauza unor constrangeri de business
        vm.integrityFormStatePrev = vm.integrityFormState;
        // schema de stari in care poate trece formulatul de integritate
        vm.integrityFormStateScheme = {};

        vm.contractAuth = {
            isPublicAuthority: true
        };

        vm.idIntegrityForm = urlParams.idIntegrityForm;
        vm.idAcqProcedure = urlParams.idAcqProcedure;
        if (vm.idIntegrityForm) {
            vm.idIntegrityForm = parseInt(vm.idIntegrityForm);
            vm.isEditMode = true;
        }
        if (vm.idAcqProcedure) {
            vm.idAcqProcedure = parseInt(vm.idAcqProcedure);
            vm.isEditMode = true;
        }

        if (vm.isEditMode) {
            vm.isLoadingData = true;

            var serverRequest = [];
            if (vm.idIntegrityForm) {
                serverRequest.push(IntegrityFormService.findById(vm.idIntegrityForm));
            } else {
                serverRequest.push(IntegrityFormService.findByAcqProcedureId(vm.idAcqProcedure));
            }


            $q.all(serverRequest).then(
                function success(integrityFormArray) {
                    var integrityForm = integrityFormArray[0];
                    vm.integrityForm = integrityForm;
                    vm.integrityFormState = vm.integrityForm.nomStatePublicationTypeDTO ? vm.integrityForm.nomStatePublicationTypeDTO.code : null;
                    vm.idIntegrityForm = integrityForm.integrityFormId;
                    vm.idProcedura = integrityForm.acqProcedureDTO.acqProcedureId;

                    updateIntegrityFormStateScheme(integrityForm);

                    if (PublicationValidationService.isValidationTriggerActive()) {
                        vm.verify();
                    }

                    AutoritateContractantaViewService.findByAcqProcedureId(vm.idProcedura).then(
                        function success(response) {
                            vm.contractAuth = response;
                        }
                    );

                    vm.isLoadingData = false;
                },
                function error(errorMsg) {
                    SeapAlertService.toastError(errorMsg);
                    vm.isLoadingData = false;
                }
            );
        } else {
            // Se va adauga procedura
            $state.go('form-procedura-achizitie', {proc: vm.idProcedura});
        }

        var updateIntegrityFormStateScheme = function (obj) {
            vm.integrityFormStateScheme = obj.publicationStateScheme;
            vm.editableSectionScheme = obj.editableSectionScheme;
        };

        vm.changeIntegrityFormState = function (integrityFormState) {
            IntegrityFormService.changePublicationState(vm.idIntegrityForm, integrityFormState).then(
                function success(data) {
                    vm.isLoadingData = false;

                    if (!data.error) {
                        // actualizam noua stare a formularului
                        vm.integrityFormStatePrev = vm.integrityFormState;

                        // schimbam schema de stari in care se poate face publicare
                        updateIntegrityFormStateScheme(data);

                        console.log('-- salvare stare publicare cu succes');
                    } else {
                        // rollback stare curenta a formularului
                        vm.integrityFormState = vm.integrityFormStatePrev;
                    }

                },
                function error(errorMsg) {
                    vm.isLoadingData = false;

                    // rollback stare curenta a formularului
                    vm.integrityFormState = vm.integrityFormStatePrev;

                    SeapAlertService.toastError(errorMsg);
                }
            );
        };

        vm.openIntegrityWarnings = function () {
            $state.go('integrity-warning-listare', {form: vm.idIntegrityForm});
        };

        vm.openIntegrityFormVersions = function () {
            $state.go('integrity-form-version-listare', {form: vm.idIntegrityForm, proc: vm.idProcedura});
        };

        /**
         * Deschide sectiunea corespunzatoare din formularul de integritate
         * @param section
         */
        vm.openSection = function (section) {
            if (section === 'I.1') {
                $state.go('form-procedura-achizitie', {proc: vm.idProcedura});
            } else if (section === 'I.2') {
                $state.go('form-factori-decizionali-lista', {proc: vm.idProcedura});
            } else if (section === 'I.3') {
                $state.go('form-consultanti-documentatie-lista', {proc: vm.idProcedura});
            } else if (section === 'I.4') {
                $state.go('form-comisie-evaluare-listare', {proc: vm.idProcedura});
            } else if (section === 'II') {
                $state.go('form-ofertanti-listare', {proc: vm.idProcedura});
            } else {
                console.error(" -- Invalid Section {" + section + "}");
            }
        };

        var successPubFn = function (data, successMsg, callbackFn) {
            vm.isLoadingData = false;

            if (data.errors) {
                if (Array.isArray(data.errors)) {
                    PublicationValidationService.openPublicationValidationErrorsModal(data.errors, vm.idProcedura);
                } else {
                    SeapAlertService.toastError(data.errors);
                }
            } else if (data.error) {
                SeapAlertService.toastError(data.code + ': ' + data.message);
            } else {
                SeapAlertService.toastSuccess(successMsg);
                PublicationValidationService.unsetValidationTrigger();
                if (callbackFn) {
                    callbackFn();
                }
            }
        };

        vm.verify = function () {
            console.log('-- INTEGRITY FORM verification -- (idIntegrityForm=' + vm.idIntegrityForm + ")");
            vm.isVerifyInProgress = true;
            openPublicationInProgressModal("Verificare inceputa!", "Verificarea datelor pentru proceduri cu " +
                "date complexe si/sau multe loturi adaugate poate dura pana la cateva minute. ")
            PublicationValidationService.op(vm.idIntegrityForm, 'verify').then(
                function success(data) {
                    successPubFn(data, 'Verificare in vederea publicarii realizata cu succes!');
                    vm.isVerifyInProgress = false;
                    closePublicationInProgressModal();
                },
                function error(errorMsg) {
                    vm.isLoadingData = false;

                    SeapAlertService.toastError(errorMsg);
                    if (errorMsg != null && errorMsg.indexOf("esiune inactiva") > -1) {
                        $state.go('home-login', {error: 'Acces interzis!'});
                    }
                    vm.isVerifyInProgress = false;
                    closePublicationInProgressModal();
                }
            );

        };

        vm.publish = function () {
            console.log('-- INTEGRITY FORM publication -- (formUid=' + vm.idIntegrityForm + ")");
            vm.isPublishInProgress = true;
            openPublicationInProgressModal("Publicare inceputa!", "Publicarea datelor pentru proceduri cu " +
                "date complexe si/sau multe loturi adaugate poate dura pana la cateva minute. ");
            PublicationValidationService.op(vm.idIntegrityForm, 'publish').then(
                function success(data) {
                    successPubFn(data, 'Publicare realizata cu succes!', function () {
                        $state.reload($state.current.name);
                    });
                    vm.isPublishInProgress = false;
                    closePublicationInProgressModal();
                },
                function error(errorMsg) {
                    vm.isLoadingData = false;

                    SeapAlertService.toastError(errorMsg);
                    if (errorMsg != null && errorMsg.indexOf("esiune inactiva") > -1) {
                        $state.go('home-login', {error: 'Acces interzis!'});
                    }
                    vm.isPublishInProgress = false;
                    closePublicationInProgressModal();
                }
            );
        };

        /**
         * Printeaza versiunea curenta a formularului de integritate (snapshot)
         */
        vm.printeazaVersiuneaCurenta = function() {
            $state.go('integrity-form-print', {form: vm.idIntegrityForm, proc: vm.idProcedura});
        };

        var extractPublicationStateString = function(integrityForm, integrityFormStateCode) {
            if (!integrityFormStateCode) {
                return '(neselectat)';
            } else if (integrityFormStateCode == vm.INTEGRITY_FORM_STATE.DESCHIDERE_OFERTA) {
                return vm.integrityForm.lastPublicationDate ? 'O NOUA DESCHIDERE OFERTE' : 'DESCHIDERE OFERTE';
            } else if (integrityFormStateCode == vm.INTEGRITY_FORM_STATE.ACTUALIZARE) {
                return 'ACTUALIZARE';
            } else if (integrityFormStateCode == vm.INTEGRITY_FORM_STATE.RAPORT_PROCEDURA) {
                return 'RAPORT FINAL';
            } else if (integrityFormStateCode == vm.INTEGRITY_FORM_STATE.ATRIBUIRE) {
                return 'ATRIBUIRE';
            } else {
                return '(nerecunoscut)';
            }
        };

        vm.publishAttempt = function() {
            $uibModal.open({
                templateUrl: 'app/_seap/modals/publication-confirm/publication-confirm.html',
                controller: 'PublicationConfirmCtrl',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'md',
                resolve: {
                    publicationOptions: function() {
                        return {
                            type: extractPublicationStateString(vm.integrityForm, vm.integrityFormState),
                            lastModDate: vm.integrityForm.lastModDate,
                            lastSyncDate: vm.integrityForm.lastSyncDate
                        };
                    }
                }
            }).result.then(
                function success(response) {
                    vm.publish();
                }, function error(response) {
                    console.error(' -- S-a renuntat la publicarea catre ANI!');
                }
            );
        };

		// Sincronizare date sectiuni din sicap
        vm.syncAllSectionsForAcqProcedureFromSicap = function() {
            vm.isLoadingData = true;
            SicapSyncService.syncAllSectionsForAcqProcedure(vm.idProcedura).then(
                function success(response) {
                    vm.isLoadingData = false;
                    $state.reload();
                    SeapAlertService.toastSuccess(response.message);
                },
                function error(errorMsg) {
                    SeapAlertService.toastError(errorMsg);
                    vm.isLoadingData = false;
                }
            );
        };

        // Modal publication progress
        var modalPublicationProgress = null;
        var openPublicationInProgressModal = function(title, message) {
            closePublicationInProgressModal();
            modalPublicationProgress = $uibModal.open({
                templateUrl: 'app/_seap/modals/publication-progress/publication-progress.html',
                controller: 'PublicationProgressCtrl',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    title: function () {
                        return title;
                    }, message: function () {
                        return message;
                    }
                }
            });
        };
        var closePublicationInProgressModal = function() {
            if (modalPublicationProgress) {
                modalPublicationProgress.close()
            }
        }
    }
})();
