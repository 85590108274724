/**
 * Created by florin.iacob on 5/16/2017.
 */
(function() {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .controller('FormComisieEvaluareSaveExpertCtrl', FormComisieEvaluareSaveExpertCtrl);

    FormComisieEvaluareSaveExpertCtrl.$inject = ['$q', 'SeapAlertService', 'urlParams', 'EvalCommitteExpertService', 'FormProceduraAchizitieService', 'Constants'];

    function FormComisieEvaluareSaveExpertCtrl($q, SeapAlertService, urlParams, EvalCommitteExpertService, FormProceduraAchizitieService, Constants) {

        var vm = this;
        if (urlParams.acqProcedureId) {
            vm.acqProcedureId = parseInt(urlParams.acqProcedureId);
        }
        if (urlParams.evalCommitteeId) {
            vm.evalCommitteeId = parseInt(urlParams.evalCommitteeId);
        }
        vm.isEditMode = false;
        if (urlParams.evalCommitteeExpertId) {
            vm.isEditMode = true;
            vm.evalCommitteeExpertId = parseInt(urlParams.evalCommitteeExpertId);
        }
        vm.validationMessage = urlParams.validationMessage;
        vm.INTEGRITY_FORM_SECTION = Constants.INTEGRITY_FORM_SECTION;
        vm.INTEGRITY_FORM_SUBSECTION = Constants.INTEGRITY_FORM_SUBSECTION;

        /**
         * Verificare daca formularul este doar in modul de citire date, dara editare
         */
        vm.checkIfReadonly = function() {
            vm.isReadOnlyMode = false;

            if (vm.acqProcedure.isReadOnly
                || !vm.acqProcedure.editableSectionScheme[vm.INTEGRITY_FORM_SECTION.I_4]) {

                vm.isReadOnlyMode = true;
            }
        };

        vm.isLoadingData = false;

        /**
         * Intializeaza date Controller
         */
        vm.initData = function() {
            var serverRequestArray = [];
            serverRequestArray.push(FormProceduraAchizitieService.findById(vm.acqProcedureId));
            serverRequestArray.push(FormProceduraAchizitieService.getEditableSubsectionTypeList(vm.acqProcedureId, vm.INTEGRITY_FORM_SECTION.I_4));
            if (vm.isEditMode) {
                serverRequestArray.push(EvalCommitteExpertService.findById(vm.evalCommitteeExpertId));
            }

            vm.isLoadingData = true;
            $q.all(serverRequestArray).then(
                function success(responseArray) {
                    vm.acqProcedure = responseArray[0];
                    vm.editableSubsectionTypeList = responseArray[1];

                    if (vm.isEditMode) {
                        vm.evalCommitteeExpert = responseArray[2];
                    } else {
                        vm.evalCommitteeExpert = {
                            isMandatoryStmtAsset: true,
                            isActive: true
                        };
                    }
                    vm.checkIfReadonly();
                    vm.isLoadingData = false;
                }, function(errMsg) {
                    vm.isLoadingData = false;
                    SeapAlertService.toastError("Eroare la aducere date!", "Eroare");
                }
            );
        };

        vm.initData();

        /**
         * Function used to create or update decision Maker
         */
        vm.saveEvalCommitteeExpert = function() {
            var deferred = $q.defer();

            var serverRequestArray = [];
            if (vm.isEditMode) {
                serverRequestArray.push(EvalCommitteExpertService.update(vm.acqProcedureId, vm.evalCommitteeExpert));
            } else {
                serverRequestArray.push(EvalCommitteExpertService.create(vm.acqProcedureId, vm.evalCommitteeId, vm.evalCommitteeExpert));
            }

            $q.all(serverRequestArray).then(
                function success(responseArray) {
                    vm.evalCommitteeExpert = responseArray[0];
                    vm.isEditMode = true;
                    deferred.resolve(responseArray);
                }, function error(errMsg) {
                    deferred.reject(errMsg);
                }
            );
            return deferred.promise;
        };

        vm.STARE_ACTIV = 'ACTIV';
        vm.STARE_INACTIV = 'INACTIV';

        // Inactiveaza
        vm.activeazaExpert = function() {
            if (vm.evalCommitteeExpert.evalCommitteeExpertId) {
                vm.evalCommitteeExpert.isActive = true;
                vm.saveEvalCommitteeExpert().then(
                    function success(responseArray) {
                        SeapAlertService.toastSuccess('Activare cu succes!', 'Succes');
                    }, function error(errMsg) {
                        SeapAlertService.toastError('Eroare la activare expert! Mesaj eroare: ' + errMsg, 'Eroare');
                        vm.evalCommitteeExpert.isActive = false;
                    }
                );
            }
        };

        // Inactiveaza
        vm.inactiveazaExpert = function() {
            if (vm.evalCommitteeExpert.evalCommitteeExpertId) {
                vm.evalCommitteeExpert.isActive = false;
                vm.saveEvalCommitteeExpert().then(
                    function success(responseArray) {
                        SeapAlertService.toastSuccess('Inactivare cu succes!', 'Succes');
                    }, function error(errMsg) {
                        SeapAlertService.toastError('Eroare la inactivare expert! Mesaj eroare: ' + errMsg, 'Eroare');
                        vm.evalCommitteeExpert.isActive = true;
                    }
                );
            }
        };

        // Schimba stare semnatar raport Procedura
        vm.onToggleSwitch = function(toggleName) {
            if (vm.evalCommitteeExpert.evalCommitteeExpertId) {
                vm.saveEvalCommitteeExpert().then(
                    function success(responseArray) {
                        SeapAlertService.toastSuccess('Salvare cu succes!', 'Succes');
                    }, function error(errMsg) {
                        SeapAlertService.toastError('Eroare la modificare date despre Expert! Mesaj eroare: ' + errMsg, 'Eroare');
                        if ('isMandatoryStmtAsset') {
                            vm.evalCommitteeExpert.isMandatoryStmtAsset = !vm.evalCommitteeExpert.isMandatoryStmtAsset;
                        }
                    }
                );
            }
        };

    }
})();
