/**
 * Created by florin.iacob on 5/16/2017.
 */
(function() {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .component('persoanaFizica', {
            templateUrl: 'app/_seap/components/persoana/fizica/persoana-fizica.html',
            controller: 'PersoanaFizicaCtrl',
            controllerAs: 'vm',
            bindings: {
                formTitle: '@',
                naturalPersonEntity: '=',
                onSaveCallback: '&',
                isContractAuth: '=',
                isReadOnly: '=',
                isSicapImport: '='
            }
        });
})();
