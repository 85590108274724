/**
 * Created by florin.iacob on 5/16/2017.
 */
(function() {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .controller('PersoanaJuridicaCtrl', PersoanaJuridicaCtrl);

    PersoanaJuridicaCtrl.$inject = ['$q', 'SeapAlertService', 'LegalPersonService', '$scope'];

    function PersoanaJuridicaCtrl($q, SeapAlertService, LegalPersonService, $scope) {

        var vm = this;
        /*
            formTitle
            legalPersonEntity: '=',
            onSaveCallback: '&',
            isReadOnly: '=',
            isSicapImport: '='
         */

        vm.isEditMode = false;

        vm.hasCIF = true;
        if(!vm.legalPersonEntity) {
            vm.isEditMode = false;
            vm.legalPersonEntity = {
                personName: null,
                cif: null
            };
        } else {
            vm.isEditMode = true;
            if (vm.legalPersonEntity.tin) {
                vm.hasCIF = false;
            }
        }

        vm.isLoadingData = false;

        // Editable
        vm.isPersoanaJuridicaFormEditable = false;
        vm.togglePersoanaJuridicaFormEdit = function() {
            vm.isPersoanaJuridicaFormEditable = !vm.isPersoanaJuridicaFormEditable;
        };

        /**
         * Salveaza informatiile depre Persoana Fizica
         */
        vm.savePersoanaJuridicaForm = function() {
            if (vm.hasCIF) {
                vm.legalPersonEntity.tin = null;
            } else {
                vm.legalPersonEntity.cif = null;
            }

            vm.isLoadingData = true;
            vm.onSaveCallback().then(
                function success(responseArray) {
                    vm.isPersoanaJuridicaFormEditable = false;
                    vm.isEditMode = true;
                    vm.isLoadingData = false;
                    $scope.formLegalPerson.$pristine = true;
                    SeapAlertService.toastSuccess('Date despre Persoana Juridica salvate cu succes!', 'Succes');
                }, function error(errMsg) {
                    SeapAlertService.toastError('Eroare la salvare date despre Persoana Juridica! Mesaj: ' + errMsg, 'Eroare');
                    vm.isLoadingData = false;
                }
            );
        };

        //ng-confirm="vm.preluareDatePJ()" ng-confirm-message="{{vm.preluareDateMessage}}"
        vm.preluareDateMessage = "Doriti preluarea datelor pentru persoana juridica pe baza identificatorului completat?";

        // Preluare date Persoana Juridica
        vm.preluareDatePJ = function() {
            if (!vm.legalPersonEntity.cif && !vm.legalPersonEntity.tin) {
                SeapAlertService.toastError('Preluarea se poate face doar daca identificatorul CIF/TIN este completat!');
                return;
            }

            var serverRequests = [
                LegalPersonService.findByIdentificator(vm.legalPersonEntity.cif, vm.legalPersonEntity.tin)
            ];

            // Aducere date Server
            vm.isLoadingData = true;
            $q.all(serverRequests).then(
                function success(responseArray) {
                    var currentLegalPersonId = vm.legalPersonEntity.legalPersonId;
                    vm.legalPersonEntity = responseArray[0];
                    vm.legalPersonEntity.personDTO.personId = null;
                    vm.legalPersonEntity.originalLegalPersonId = vm.legalPersonEntity.legalPersonId;
                    vm.legalPersonEntity.legalPersonId = currentLegalPersonId;

                    $scope.formLegalPerson.$pristine = false;
                    SeapAlertService.toastSuccess('Date despre Persoana Juridica preluate cu succes!', 'Succes');
                    vm.isLoadingData = false;
                },
                function error(errMsg) {
                    SeapAlertService.toastError('Nu s-au putut prelua datele! Mesaj: ' + errMsg);
                    vm.isLoadingData = false;
                }
            );
        };

        vm.onChangeTipIdentificator = function(isCif) {
            if (vm.isSicapImport) {
                if (isCif && !vm.legalPersonEntity.cif && vm.legalPersonEntity.tin) {
                    vm.legalPersonEntity.cif = vm.legalPersonEntity.tin;
                } else if (!isCif && vm.legalPersonEntity.cif && !vm.legalPersonEntity.tin) {
                    vm.legalPersonEntity.tin = vm.legalPersonEntity.cif;
                }
            }
        };

    }
})();
