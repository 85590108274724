(function() {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .controller('PublicationValidationErrorsCtrl', PublicationValidationErrorsCtrl);

    PublicationValidationErrorsCtrl.$inject = ['$uibModalInstance', 'PublicationValidationService', 'errors', 'acqProcedureId', 'Principal'];

    function PublicationValidationErrorsCtrl($uibModalInstance, PublicationValidationService, errors, acqProcedureId, Principal) {
        var vm = this;
        vm.errors = errors;
        vm.acqProcedureId = acqProcedureId;

        vm.isAC = Principal.hasAnyAuthority(['ROLE_AC']);

        /**
         * Rezolva problema de validare
         */
        vm.rezolva = function(errorObj) {
            PublicationValidationService.goToStateWithValidationError(errorObj, vm.acqProcedureId);
            PublicationValidationService.setValidationTrigger();
            $uibModalInstance.close('solve');
        };

        vm.close = function () {
            PublicationValidationService.unsetValidationTrigger();
            $uibModalInstance.close('close');
        }
    }
})();
