(function() {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .controller('CreateLotCtrl', CreateLotCtrl);

    CreateLotCtrl.$inject = ['SeapAlertService', '$q', 'NomenclatorService', '$uibModalInstance', 'AcqProcedureLotService', 'lotEntity', 'acqProcedureId', 'acqProcedure', 'isTenderOpenDateMandatory', 'Principal'];

    function CreateLotCtrl(SeapAlertService, $q, NomenclatorService, $uibModalInstance, AcqProcedureLotService, lotEntity, acqProcedureId, acqProcedure, isTenderOpenDateMandatory, Principal) {
        var vm = this;

        vm.clear = clear;
        vm.save = save;

        vm.isEditMode = !!lotEntity;
        vm.isTenderOpenDateMandatory = isTenderOpenDateMandatory;

        if (vm.isEditMode) {
            vm.acqProcedureLot = lotEntity;
            if(vm.acqProcedureLot.tenderOpenDate) {
                vm.acqProcedureLot.tenderOpenDate = new Date(vm.acqProcedureLot.tenderOpenDate);
            }
        } else {
            vm.acqProcedureLot = {
                lotNumber: null,
                lotName: null,
                estimatedValue: null,
                nomCurrencyDTO: {id: null},
                tenderOpenDate: null,
                isTenderOpen: true,
                nomAcqProcedureStateDTO: {id: 3, code: "DESFASURARE", name: "In desfasurare"}
            };
        }

        vm.hasRoleManualAddAcqProcedureLot = acqProcedure.isLocal ? true: Principal.hasAnyAuthority(['ROLE_MANUAL_ADD_ALL', 'ROLE_MANUAL_ADD_ACQ_PROCEDURE_LOT']);

        /**
         * Initializeaza datele controller-ului
         */
        vm.initData = function() {
            vm.isLoadingData = false;
            vm.data = {};
            var serverRequestArray = [
                NomenclatorService.findByKey('NomCurrency'),
                NomenclatorService.findByKey('NomAcqProcedureState')
            ];

            $q.all(serverRequestArray).then(
                function success(responseArray) {
                    vm.data.nomCurrencyList = responseArray[0];
                    vm.data.nomAcqProcedureStateList = responseArray[1];
                    vm.isLoadingData = false;
                }, function error(errorMsg) {
                    SeapAlertService.toastError('Eroare la aducere date!');
                    vm.isLoadingData = false;
                }
            );
        };

        vm.initData();

        /**
         * Salveaza entitate AcqProcedureLot
         */
        function save () {
            if (!vm.validateAndWarn(vm.acqProcedureLot)) {
                return;
            }

            vm.isLoadingData = true;
            var serverRequestArray = [];
            if (vm.isEditMode) {
                serverRequestArray.push(AcqProcedureLotService.update(acqProcedureId, vm.acqProcedureLot, vm.isTenderOpenDateMandatory));
            } else {
                serverRequestArray.push(AcqProcedureLotService.create(acqProcedureId, vm.acqProcedureLot));
            }

            $q.all(serverRequestArray).then(
                function success(responseArray) {
                    $uibModalInstance.close(responseArray[0]);
                    SeapAlertService.toastSuccess('Lot salvat cu succes!', 'Succes');
                    vm.isLoadingData = false;
                }, function error(errorMsg) {
                    vm.isLoadingData = false;
                    SeapAlertService.toastError('Eroare la salvarea Lotului. Mesaj: ' + errorMsg, 'Eroare');
                }
            );

        }

        /**
         * Valideaza Lotul si afiseaza eroare daca este cazul
         */
        vm.validateAndWarn = function(acqProcedureLot) {
            if (!acqProcedureLot.nomCurrencyDTO.id) {
                SeapAlertService.toastError('Trebuie completata valuta pentru VALOARE ESTIMATA!', 'Atentie');
                return false;
            }
            if (!acqProcedureLot.nomAcqProcedureStateDTO.id) {
                SeapAlertService.toastError('Nu a fost aleasa o STARE LOT!', 'Atentie');
                return false;
            }
            return true;
        };

        /**
         * Schimbare NomCurrency
         */
        vm.onNomCurrencyChange = function() {
            angular.forEach(vm.data.nomCurrencyList, function(nomCurrencyItem, index) {
                if (nomCurrencyItem.id === vm.acqProcedureLot.nomCurrencyDTO.id) {
                    vm.acqProcedureLot.nomCurrencyDTO = nomCurrencyItem;
                    return;
                }
            });
        };

        /**
         * Schimbare NomAcqProcedureState
         */
        vm.onNomAcqProcedureStateChange = function() {
            angular.forEach(vm.data.nomAcqProcedureStateList, function(nomAcqProcedureStateItem, index) {
                if (nomAcqProcedureStateItem.id === vm.acqProcedureLot.nomAcqProcedureStateDTO.id) {
                    vm.acqProcedureLot.nomAcqProcedureStateDTO = nomAcqProcedureStateItem;
                    return;
                }
            });
        };

        /**
         * Close modal
         */
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        // DATE PICKER
        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.dataDeschidereOferte = false;
        vm.openCalendar = function(date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
