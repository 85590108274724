/**
 * Created by florin.iacob on 5/16/2017.
 */
(function () {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('integrity-form-version-listare', {
                parent: 'seap',
                url: '/integrity-form-version-listare?form&proc',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'FORMULARE INTEGRITATE - ISTORIC VERSIUNI PUBLICATE'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/_seap/entities/integrity-form-version/listare/integrity-form-version-listare.html',
                        controller: 'IntegrityFormVersionListareCtrl',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    form: {
                        value: '',
                        squash: true
                    },
                    proc: {
                        value: '',
                        squash: true
                    }
                },
                resolve: {
                    urlParams: ['$stateParams', function ($stateParams) {
                        return {
                            idIntegrityForm: $stateParams.form,
                            idProcedure: $stateParams.proc
                        };
                    }]
                }
            })
            .state('integrity-form-version-view', {
                parent: 'seap',
                url: '/integrity-form-version-view?form&proc&vers',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'ISTORIC VERSIUNE PUBLICATA'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/_seap/entities/integrity-form-version/view/view-integrity-form-version.html',
                        controller: 'ViewIntegrityFormVersionCtrl',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    form: {
                        value: '',
                        squash: true
                    },
                    proc: {
                        value: '',
                        squash: true
                    },
                    vers: {
                        value: '',
                        squash: true
                    }
                },
                resolve: {
                    urlParams: ['$stateParams', function ($stateParams) {
                        return {
                            idIntegrityForm: $stateParams.form,
                            idProcedure: $stateParams.proc,
                            idIntegrityFormVersion: $stateParams.vers
                        };
                    }]
                }
            });
    }

})();
