/**
 * Created by florin.iacob on 5/22/2017.
 */
(function() {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .factory('EvalCmteMemberFctCatService', EvalCmteMemberFctCatService);

    EvalCmteMemberFctCatService.$inject = ['$q', '$http', 'API_BASE_URL'];

    function EvalCmteMemberFctCatService($q, $http, API_BASE_URL) {

        var Service = {};

        /**
         * Aducerea listei de Categorii Functii pentru Factorul decizional specificat
         */
        Service.findByEvalCmteMemberId = function(evalCmteMemberID) {
            var deferred = $q.defer();
            // make server request
            $http({
                method: 'GET',
                url: API_BASE_URL + '/evalCmteMemberFctCat/findByEvalCmteMemberId/' + evalCmteMemberID,
                headers: {
                    'Content-Type': "application/json"
                }
            }).then(
                function success(response) {
                    deferred.resolve(response.data);

                }, function error(response) {
                    deferred.reject(response.data.message);
                    console.error(' -- REST call Error: ' + API_BASE_URL + '/evalCmteMemberFctCat/findByEvalCmteMemberId/' + evalCmteMemberID);
                }
            );
            return deferred.promise;
        };

        /**
         * Actualizeaza lista de Categorii Functii pentru Factorul deciszional specificat
         */
        Service.save = function(evalCmteMemberID, evalCmteMemberFctCatDTOList) {
            var deferred = $q.defer();
            // make server request
            $http({
                method: 'PUT',
                url: API_BASE_URL + '/evalCmteMemberFctCat/save/' + evalCmteMemberID,
                headers: {
                    'Content-Type': "application/json"
                },
                data: JSON.stringify(evalCmteMemberFctCatDTOList)
            }).then(
                function success(response) {
                    deferred.resolve(response.data);

                }, function error(response) {
                    var errMsg = 'Eroare la salvarea functiei!';
                    if (response.data && response.data.message) {
                        errMsg = response.data.message;
                    }
                    deferred.reject(errMsg);
                    console.error(' -- REST PUT call Error: /evalCmteMemberFctCat/save/' + evalCmteMemberID);
                }
            );
            return deferred.promise;
        };

        return Service
    }
})();

