/**
 * Created by florin.iacob on 5/16/2017.
 */
(function() {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('form-procedura-achizitie', {
                parent: 'seap',
                url: '/form-procedura-achizitie?proc&validationMessage',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'Procedura achizitie'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/_seap/entities/form-procedura-achizitie/form-procedura-achizitie.html',
                        controller: 'FormProceduraAchizitieCtrl',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    proc: {
                        value: '',
                        squash: true
                    },
                    validationMessage: {
                        value: '',
                        squash: true
                    }
                },
                resolve: {
                    urlParams: ['$stateParams', function ($stateParams) {
                        return {
                            idProcedura: $stateParams.proc,
                            validationMessage: $stateParams.validationMessage
                        };
                    }]
                }
            })
            .state('form-autoritate-contractanta', {
                parent: 'seap',
                url: '/form-autoritate-contractanta?state',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'Autoritate contactanta'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/_seap/entities/form-procedura-achizitie/autoritate-contractanta/form-autoritate-contractanta.html',
                        controller: 'FormAutoritateContractantaCtrl',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    state: {
                        value: '',
                        squash: true
                    }
                },
                resolve: {
                    urlParams: ['$stateParams', function ($stateParams) {
                        return {
                            prevState: $stateParams.state
                        };
                    }]
                }
            })
            .state('delete-procedura-achizitie', {
                parent: 'form-procedura-achizitie',
                url: '/{id}/delete',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/job-history/job-history-delete-dialog.html',
                        controller: 'JobHistoryDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['JobHistory', function(JobHistory) {
                                return JobHistory.get({id : $stateParams.id}).$promise;
                            }]
                        }
                    }).result.then(function() {
                        $state.go('job-history', null, { reload: 'job-history' });
                    }, function() {
                        $state.go('^');
                    });
                }]
            });
    }

})();
