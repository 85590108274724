(function() {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .factory('StatisticsIntegrityFormsService', StatisticsIntegrityFormsService);

    StatisticsIntegrityFormsService.$inject = ['$rootScope', 'GenericService'];

    function StatisticsIntegrityFormsService ($rootScope, GenericService) {

        var service = {};

        /**
         * Aducerea listei de Formulare Integritate pentru utilizatorul conectat
         */
        service.getStatisticsForPeriod = function (startDateMillis, endDateMillis) {

            var httpUrl = '/statisticsIntegrityForms/getStatisticsForPeriod';
            if (startDateMillis) {
                httpUrl += '?startDateMillis=' + startDateMillis;
            }
            if (endDateMillis) {
                if (startDateMillis) {
                    httpUrl += '&';
                } else {
                    httpUrl += '?';
                }
                httpUrl += 'endDateMillis=' + endDateMillis;
            }
            return GenericService.GetFn(httpUrl);
        };

        return service;

    }
})();
