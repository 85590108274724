/**
 * Created by florin.iacob on 5/16/2017.
 */
(function() {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .controller('FormOfertantiSaveCtrl', FormOfertantiSaveCtrl);

    FormOfertantiSaveCtrl.$inject = ['$q', 'SeapAlertService', 'urlParams', 'FormOfertantiService', 'IntegrityFormService', 'FormProceduraAchizitieService', 'Constants', 'Principal'];

    function FormOfertantiSaveCtrl($q, SeapAlertService, urlParams, FormOfertantiService, IntegrityFormService, FormProceduraAchizitieService, Constants, Principal) {

        var vm = this;
        if (urlParams.idProcedura) {
            vm.acqProcedureId = parseInt(urlParams.idProcedura);
        }
        vm.isEditMode = false;
        if (urlParams.idOfertant) {
            vm.isEditMode = true;
            vm.tendererId = parseInt(urlParams.idOfertant);
        }
        vm.validationMessage = urlParams.validationMessage;

        vm.INTEGRITY_FORM_STATE = Constants.INTEGRITY_FORM_STATE;
        vm.INTEGRITY_FORM_SECTION = Constants.INTEGRITY_FORM_SECTION;
        vm.INTEGRITY_FORM_SUBSECTION = Constants.INTEGRITY_FORM_SUBSECTION;

        /**
         * Verificare daca formularul este doar in modul de citire date, dara editare
         */
        vm.checkIfReadonly = function() {
            vm.isReadOnlyMode = false;

            if (vm.acqProcedure.isReadOnly
                || !vm.acqProcedure.editableSectionScheme[vm.INTEGRITY_FORM_SECTION.II]) {

                vm.isReadOnlyMode = true;
            }
        };

        vm.hasRoleManualAddTenderer = Principal.hasAnyAuthority(['ROLE_MANUAL_ADD_ALL', 'ROLE_MANUAL_ADD_TENDERER']);
        vm.hasRoleManualAddContract = Principal.hasAnyAuthority(['ROLE_MANUAL_ADD_ALL', 'ROLE_MANUAL_ADD_CONTRACT']);

        vm.isLoadingData = false;

        /**
         * Intializeaza date Controller
         */
        vm.initData = function() {
            var serverRequestArray = [];
            serverRequestArray.push(IntegrityFormService.findByAcqProcedureId(vm.acqProcedureId));
            serverRequestArray.push(FormProceduraAchizitieService.getEditableSubsectionTypeList(vm.acqProcedureId, vm.INTEGRITY_FORM_SECTION.II));
            if (vm.isEditMode) {
                serverRequestArray.push(FormOfertantiService.findById(vm.tendererId));
            }

            vm.isLoadingData = true;
            $q.all(serverRequestArray).then(
                function success(responseArray) {
                    vm.integrityForm = responseArray[0];
                    vm.acqProcedure = vm.integrityForm.acqProcedureDTO;
                    vm.editableSubsectionTypeList = responseArray[1];
                    vm.integrityFormState = vm.integrityForm.nomStatePublicationTypeDTO ? vm.integrityForm.nomStatePublicationTypeDTO.code : null;
                    vm.isTenderOpenDateMandatory = vm.acqProcedure.tenderOpenDateMandatory;
                    vm.isOfflineForEdit = (vm.integrityFormState == vm.INTEGRITY_FORM_STATE.DESCHIDERE_OFERTA
                            || vm.integrityFormState == vm.INTEGRITY_FORM_STATE.ACTUALIZARE)
                        && vm.isTenderOpenDateMandatory;

                    if (vm.isEditMode) {
                        vm.tenderer = responseArray[2];
                    } else {
                        vm.tenderer = {
                            isActive: true,
                            isAssoc: false,
                            isWinner: false
                        };
                    }

                    vm.hasRoleManualAddContract = vm.hasRoleManualAddContract && vm.acqProcedure.isLocal;
                    vm.hasRoleManualAddTenderer = vm.hasRoleManualAddTenderer && vm.acqProcedure.isLocal;

                    vm.checkIfReadonly();
                    vm.isLoadingData = false;
                }, function(errMsg) {
                    vm.isLoadingData = false;
                    SeapAlertService.toastError("Eroare la aducere date!", "Eroare");
                }
            );
        };

        vm.initData();

        /**
         * Function used to create or update decision Maker
         */
        vm.saveTenderer = function() {
            var deferred = $q.defer();

            var serverRequestArray = [];
            if (vm.isEditMode) {
                serverRequestArray.push(FormOfertantiService.update(vm.acqProcedureId, vm.tenderer));
            } else {
                serverRequestArray.push(FormOfertantiService.create(vm.acqProcedureId, vm.tenderer));
            }

            $q.all(serverRequestArray).then(
                function success(responseArray) {
                    vm.tenderer = responseArray[0];
                    vm.isEditMode = true;
                    deferred.resolve(responseArray);
                }, function error(errMsg) {
                    deferred.reject(errMsg);
                }
            );
            return deferred.promise;
        };


        // Schimba stare semnatar raport Procedura
        vm.onToggleSwitch = function(toggleName) {
            if (vm.tenderer.tendererId) {
                vm.saveTenderer().then(
                    function success(responseArray) {
                        SeapAlertService.toastSuccess('Salvare cu succes!', 'Succes');
                    }, function error(errMsg) {
                        SeapAlertService.toastError('Eroare la modificare date despre ofertant ('+ errMsg +')!', 'Eroare');
                    }
                );
            }
        };

        vm.STARE_ACTIV = 'ACTIV';
        vm.STARE_INACTIV = 'INACTIV';

        // Activeaza
        vm.activeazaOfertant = function() {
            if (vm.tenderer.tendererId) {
                vm.tenderer.isActive = true;
                vm.saveTenderer().then(
                    function success(responseArray) {
                        SeapAlertService.toastSuccess('Activare cu succes!', 'Succes');
                    }, function error(errMsg) {
                        SeapAlertService.toastError('Eroare la activare ofertant!', 'Eroare');
                        vm.tenderer.isActive = false;
                    }
                );
            }
        };

        // Inactiveaza
        vm.inactiveazaOfertant = function() {
            if (vm.tenderer.tendererId) {
                vm.tenderer.isActive = false;
                vm.saveTenderer().then(
                    function success(responseArray) {
                        SeapAlertService.toastSuccess('Inactivare cu succes!', 'Succes');
                    }, function error(errMsg) {
                        SeapAlertService.toastError('Eroare la inactivare ofertant!', 'Eroare');
                        vm.tenderer.isActive = true;
                    }
                );
            }
        };
    }
})();
