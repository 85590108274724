/**
 * Created by florin.iacob on 5/16/2017.
 */
(function() {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .factory('FormComisieEvaluareService', FormComisieEvaluareService);

    FormComisieEvaluareService.$inject = ['$http','$q','API_BASE_URL'];

    function FormComisieEvaluareService ($http, $q, API_BASE_URL) {

        var service = {};

        /**
         * Aducerea listei de Comisii Evaluare pentru Procedura de Achzitie specificata
         */
        service.findByAcqProcedureId = function(acqProcedureId) {
            var deferred = $q.defer();
            // make server request
            $http({
                method: 'GET',
                url: API_BASE_URL + '/evalCommittee/findByAcqProcedureId/' + acqProcedureId,
                headers: {
                    'Content-Type': "application/json"
                }
            }).then(
                function success(response) {
                    if (!response.data) {
                        deferred.reject('No data returned!');
                    }
                    deferred.resolve(response.data);

                }, function error(response) {
                    deferred.reject(response.data.message);
                    console.error(' -- REST call Error: ' + API_BASE_URL + '/evalCommittee/findByAcqProcedureId/' + acqProcedureId);
                }
            );
            return deferred.promise;
        };

        /**
         * Creaza o Comisie de evaluare pentru o Procedura de Achizitie
         */
        service.create = function(acqProcedureId, evalCommitteeDTO) {
            var deferred = $q.defer();
            // make server request
            $http({
                method: 'POST',
                url: API_BASE_URL + '/evalCommittee/create/' + acqProcedureId,
                headers: {
                    'Content-Type': "application/json"
                },
                data: JSON.stringify(evalCommitteeDTO)
            }).then(
                function success(response) {
                    deferred.resolve(response.data);

                }, function error(response) {
                    var errMsg = 'Eroare la salvarea Comisiei de evaluare!';
                    if (response.data && response.data.message) {
                        errMsg = response.data.message;
                    }
                    deferred.reject(errMsg);
                    console.error(' -- REST POST call Error: /evalCommittee/create/' + acqProcedureId);
                }
            );
            return deferred.promise;
        };

        /**
         * Actualizeaza o Comisie de evaluare pentru o Procedura de Achizitie
         */
        service.update = function(acqProcedureId, evalCommitteeDTO) {
            var deferred = $q.defer();
            // make server request
            $http({
                method: 'PUT',
                url: API_BASE_URL + '/evalCommittee/update/' + acqProcedureId,
                headers: {
                    'Content-Type': "application/json"
                },
                data: JSON.stringify(evalCommitteeDTO)
            }).then(
                function success(response) {
                    deferred.resolve(response.data);

                }, function error(response) {
                    var errMsg = 'Eroare la salvarea Comisiei de evaluare!';
                    if (response.data && response.data.message) {
                        errMsg = response.data.message;
                    }
                    deferred.reject(errMsg);
                    console.error(' -- REST POST call Error: /evalCommittee/update/' + acqProcedureId);
                }
            );
            return deferred.promise;
        };

        /**
         * Sterge un Factor decizional pentru o Procedura de Achizitie
         */
        service.delete = function(acqProcedureId, evalCommitteeId) {
            var deferred = $q.defer();
            // make server request
            $http({
                method: 'DELETE',
                url: API_BASE_URL + '/evalCommittee/delete/' + acqProcedureId + "/" + evalCommitteeId,
                headers: {
                    'Content-Type': "application/json"
                }
            }).then(
                function success(response) {
                    deferred.resolve(response.data);

                }, function error(response) {
                    var errMsg = 'Eroare la stergerea Comisiei de evaluare!';
                    if (response.data && response.data.message) {
                        errMsg = response.data.message;
                    }
                    deferred.reject(errMsg);
                    console.error(' -- REST POST call Error: /evalCommittee/delete/' + acqProcedureId + "/" + evalCommitteeId);
                }
            );
            return deferred.promise;
        };

        return service;
    }
})();
