/**
 * Created by florin.iacob on 5/16/2017.
 */
(function() {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .factory('AutoritateContractantaViewService', AutoritateContractantaViewService);

    AutoritateContractantaViewService.$inject = ['$http','$q','API_BASE_URL', 'GenericService'];

    function AutoritateContractantaViewService ($http, $q, API_BASE_URL, GenericService) {

        var service = {};

        /**
         * Aducerea unei entitati ContractAuth pe baza ID-ului de procedura
         */
        service.findByAcqProcedureId = function(acqProcedureId) {
            var deferred = $q.defer();
            // make server request
            $http({
                method: 'GET',
                url: API_BASE_URL + '/contractAuth/findByAcqProcedureId/' + acqProcedureId,
                headers: {
                    'Content-Type': "application/json"
                }
            }).then(
                function success(response) {
                    if (!response.data) {
                        deferred.reject('No data returned!');
                    }
                    deferred.resolve(response.data);

                }, function error(response) {
                    deferred.reject(response.data.message);
                    console.error(' -- REST call Error: ' + API_BASE_URL + '/contractAuth/findByAcqProcedureId/' + acqProcedureId);
                }
            );
            return deferred.promise;
        };

        /**
         * Aducerea unei entitati ContractAuth pentru utilzatorul logat
         */
        service.findByCurrentUser = function() {
            var deferred = $q.defer();
            // make server request
            $http({
                method: 'GET',
                url: API_BASE_URL + '/contractAuth/findByCurrentUser',
                headers: {
                    'Content-Type': "application/json"
                }
            }).then(
                function success(response) {
                    if (!response.data) {
                        deferred.reject('No data returned!');
                    }
                    deferred.resolve(response.data);

                }, function error(response) {
                    deferred.reject(response.data.message);
                    console.error(' -- REST call Error: ' + API_BASE_URL + '/contractAuth/findBySessionUser');
                }
            );
            return deferred.promise;
        };

        /**
         * Actualizeaza date despre reprezentantul legal al autoritatii Contractante
         *
         * @param naturalPersonDTO
         */
        service.updatePF = function(naturalPersonDTO) {
            return GenericService.PutFn('/contractAuth/updatePF', naturalPersonDTO);
        };

        /**
         * Se va aduce lista de Categorii pentru entitatea Autoritate Contractanta asociata utlizatorului curent.
         */
        service.findContractAuthCategDtoList = function() {
            return GenericService.GetFn('/contractAuth/findContractAuthCategDtoList');
        };

        /**
         * Se va actualiza lista de Categorii pentru entitatea Autoritate Contractanta asociata utlizatorului curent.
         */
        service.updateContractAuthCategDtoList = function(contractAuthCategDTOList) {
            return GenericService.PutFn('/contractAuth/updateContractAuthCategDtoList', contractAuthCategDTOList);
        };

        return service;
    }
})();
