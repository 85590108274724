(function() {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .controller('ViewIntegrityFormVersionCtrl', ViewIntegrityFormVersionCtrl);

    ViewIntegrityFormVersionCtrl.$inject = ['GenericService', '$q', 'SeapAlertService', 'urlParams', '$state'];

    function ViewIntegrityFormVersionCtrl(GenericService, $q, SeapAlertService, urlParams, $state) {
        var vm = this;

        vm.isLoadingData = false;

        vm.idIntegrityForm = urlParams.idIntegrityForm;
        if (vm.idIntegrityForm) {
            vm.idIntegrityForm = parseInt(vm.idIntegrityForm);
        }
        vm.idProcedure = urlParams.idProcedure;
        if (vm.idProcedure) {
            vm.idProcedure = parseInt(vm.idProcedure);
        }
        vm.integrityFormVersionId = urlParams.idIntegrityFormVersion;
        if (vm.integrityFormVersionId) {
            vm.integrityFormVersionId = parseInt(vm.integrityFormVersionId);
        }

        /**
         * Functie folosita pentru transformarea si afisarea documentului XML
         *
         * @param xml
         * @param xsl
         */
        function transformXMLAsHTML(xml, xsl) {
            // code for IE

            var isIE = (navigator.appName == 'Microsoft Internet Explorer' ||  !!(navigator.userAgent.match(/Trident/)
                            || navigator.userAgent.match(/rv:11/)) || (typeof $.browser !== "undefined" && $.browser.msie == 1));

            if (window.ActiveXObject || isIE)
            {
                if (typeof (xml.transformNode) != "undefined") {
                    // IE6, IE7, IE8
                    var ex = xml.transformNode(xsl);
                    document.getElementById("transformResult").innerHTML = ex;
                } else {
                    // IE9 and grater
                    var xmlDoc = new ActiveXObject("Microsoft.XMLDOM");
                    xmlDoc.async = false;
                    var xmlAsString = new XMLSerializer().serializeToString(xml.documentElement);
                    xmlDoc.loadXML(xmlAsString);
                    var xslDoc = new ActiveXObject("MSXML2.FreeThreadedDOMDocument");
                    xslDoc.async = false;
                    xslDoc.load('app/_seap/entities/integrity-form-version/view/view-integrity-form-version.xsl');

                    var processor = new ActiveXObject("Msxml2.XSLTemplate");
                    processor.stylesheet = xslDoc;

                    var objXSLTProc = processor.createProcessor();
                    objXSLTProc.input = xmlDoc;
                    objXSLTProc.transform();

                    document.getElementById("transformResult").innerHTML = objXSLTProc.output;
                }
            }
            // code for Chrome, Firefox, Opera, etc.
            else if (document.implementation && document.implementation.createDocument)
            {
                var xsltProcessor = new XSLTProcessor();
                xsltProcessor.importStylesheet(xsl);
                var resultDocument = xsltProcessor.transformToFragment(xml, document);
                document.getElementById("transformResult").appendChild(resultDocument);
            }
        };

        /**
         * Functie aplicata pentru a transforma continutul XML in HTML
         */
        vm.applyXMLTransform = function() {
            vm.isLoadingData = true;

            var requestArray = [];
            requestArray.push(GenericService.GetFn('/integrityFormVersion/getXmlContent/'+ vm.integrityFormVersionId));
            requestArray.push(GenericService.GetAsXMLFn('app/_seap/entities/integrity-form-version/view/view-integrity-form-version.xsl'));

            $q.all(requestArray).then(
                function (responseArray) {
                    var xmlDoc = jQuery.parseXML(responseArray[0]);
                    transformXMLAsHTML(xmlDoc, responseArray[1]);
                    vm.isLoadingData = false;
                }, function (errMsg) {
                    SeapAlertService.toastError('Eroare la aducere date!');
                    vm.isLoadingData = false;
                    console.error(" EROARE aducere date (XML + XSL): " + errMsg);
                }
            );
        };

        vm.applyXMLTransform();

        /**
         * Intoarcere in lista de optiuni
         */
        vm.openIntegrityFormVersions = function () {
            $state.go('integrity-form-version-listare', {form: vm.idIntegrityForm, proc: vm.idProcedura});
        };

        /**
         * Functie apelata pentru printarea paginii accesate
         */
        vm.printPage = function() {
            window.print();
        }
    }
})();
