(function() {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .controller('AuditsController', AuditsController);

    AuditsController.$inject = ['$filter', 'AuditsService', 'SeapAuditsService', 'ParseLinks', 'SeapAlertService', 'NomenclatorService'];

    function AuditsController ($filter, AuditsService, SeapAuditsService, ParseLinks, SeapAlertService, NomenclatorService) {
        var vm = this;

        vm.isLoadingData = false;

        vm.audits = null;
        vm.links = null;
        vm.loadPage = loadPage;
        vm.onFilterApply = onFilterApply;
        vm.page = 1;
        vm.previousMonth = previousMonth;
        vm.previousWeek = previousWeek;
        vm.today = today;
        vm.totalItems = null;

        vm.fromDate = null;
        vm.toDate = null;
        vm.auditFilterDTO = {
            fromDate: null,
            toDate: null,
            eventType: null,
            eventTypeId: null,
            eventValue: null,
            principal: null,
            principalSeapUid: null,
            acPjDenumire: null,
            acPjCif: null
        };
        vm.eventNoScroll = false;

        vm.today();
        vm.previousWeek();
        vm.onFilterApply();

        SeapAuditsService.getNomEventTypeList().then(
            function success(response) {
                vm.data = {
                    nomEventTypeList: response
                };
                vm.data.nomEventTypeList.unshift({id: null, name: "< toate >"});
            }, function error(response) {
                SeapAlertService.toastError("Eroare la aducere nomencloator evenimente!");
            }
        );

        function onFilterApply () {
            var dateFormat = 'yyyy-MM-dd';
            vm.auditFilterDTO.fromDate = $filter('date')(vm.fromDate, dateFormat);
            vm.auditFilterDTO.toDate = $filter('date')(vm.toDate, dateFormat);

            vm.isLoadingData = true;
            SeapAuditsService.findByFilters(
                {
                    page: vm.page -1,
                    size: 20
                },
                vm.auditFilterDTO
            ).then(
                function success(response){
                    vm.audits = response.data;
                    vm.links = ParseLinks.parse(response.headers('link'));
                    vm.totalItems = response.headers('X-Total-Count');
                    vm.isLoadingData = false;
                }, function error(responseErr) {
                    vm.audits = [];
                    vm.totalItems = 0;
                    SeapAlertService.toastError("Eroare la aducere date! Mesaj: " + responseErr);
                    vm.isLoadingData = false;
                }
            );
        }

        // Date picker config
        function today () {
            // Today + 1 day - needed if the current day must be included
            var today = new Date();
            vm.toDate = new Date(today.getFullYear(), today.getMonth(), today.getDate() + 1);
        }

        function previousMonth () {
            var fromDate = new Date();
            if (fromDate.getMonth() === 0) {
                fromDate = new Date(fromDate.getFullYear() - 1, 11, fromDate.getDate());
            } else {
                fromDate = new Date(fromDate.getFullYear(), fromDate.getMonth() - 1, fromDate.getDate());
            }

            vm.fromDate = fromDate;
        }

        function previousWeek () {
            var oneWeekAgo = new Date();
            oneWeekAgo.setDate(oneWeekAgo.getDate() - 7);

            vm.fromDate = oneWeekAgo;
        }

        function loadPage (page) {
            vm.page = page;
            vm.onFilterApply();
        }

        vm.isFilterOpen = true;

        /**
         * Deschidere/inchidere filtre
         *
         * @param isFilterOpen
         */
        vm.toggleFilterOpen = function(isFilterOpen) {
            vm.isFilterOpen = isFilterOpen;
        };
    }
})();
