/**
 * Created by florin.iacob on 5/16/2017.
 */
(function() {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .controller('FormFactoriDecizionaliSaveCtrl', FormFactoriDecizionaliSaveCtrl);

    FormFactoriDecizionaliSaveCtrl.$inject = ['$q', 'SeapAlertService', 'urlParams', 'FormFactoriDecizionaliService', 'IntegrityFormService', 'FormProceduraAchizitieService', 'Constants', 'Principal'];

    function FormFactoriDecizionaliSaveCtrl($q, SeapAlertService, urlParams, FormFactoriDecizionaliService, IntegrityFormService, FormProceduraAchizitieService, Constants, Principal) {

        var vm = this;
        if (urlParams.acqProcedureId) {
            vm.acqProcedureId = parseInt(urlParams.acqProcedureId);
        }
        vm.isEditMode = false;
        if (urlParams.decisionMakerId) {
            vm.isEditMode = true;
            vm.decisionMakerId = parseInt(urlParams.decisionMakerId);
        }
        vm.validationMessage = urlParams.validationMessage;
        vm.isLoadingData = false;

        vm.INTEGRITY_FORM_SECTION = Constants.INTEGRITY_FORM_SECTION;
        vm.INTEGRITY_FORM_SUBSECTION = Constants.INTEGRITY_FORM_SUBSECTION;

        vm.hasRoleManualAddDecisionMaker = Principal.hasAnyAuthority(['ROLE_MANUAL_ADD_ALL', 'ROLE_MANUAL_ADD_DECISION_MAKER']);

        /**
         * Verificare daca formularul este doar in modul de citire date, dara editare
         */
        vm.checkIfReadonly = function() {
            vm.isReadOnlyMode = false;

            if (vm.acqProcedure.isReadOnly
                || !vm.acqProcedure.editableSectionScheme[vm.INTEGRITY_FORM_SECTION.I_2]) {

                vm.isReadOnlyMode = true;
            }
        };

        /**
         * Intializeaza date Controller
         */
        vm.initData = function() {
            var serverRequestArray = [];
            serverRequestArray.push(IntegrityFormService.findByAcqProcedureId(vm.acqProcedureId));
            serverRequestArray.push(FormProceduraAchizitieService.getEditableSubsectionTypeList(vm.acqProcedureId, vm.INTEGRITY_FORM_SECTION.I_2));
            if (vm.isEditMode) {
                serverRequestArray.push(FormFactoriDecizionaliService.findById(vm.decisionMakerId));
            }

            vm.isLoadingData = true;
            $q.all(serverRequestArray).then(
                function success(responseArray) {
                    vm.integrityForm = responseArray[0];
                    vm.acqProcedure = vm.integrityForm.acqProcedureDTO;
                    vm.editableSubsectionTypeList = responseArray[1];

                    if (vm.isEditMode) {
                        vm.decisionMaker = responseArray[2];
                    } else {
                        vm.decisionMaker = {
                            isActive: true,
                            isMandatoryStmtAsset: true,
                            isSignerProcRep: true,
                            isContractSigner: true
                        };
                    }

                    vm.hasRoleManualAddDecisionMaker = vm.hasRoleManualAddDecisionMaker && vm.acqProcedure.isLocal;

                    vm.checkIfReadonly();
                    vm.isLoadingData = false;
                }, function(errMsg) {
                    vm.isLoadingData = false;
                    SeapAlertService.toastError("Eroare la aducere date!", "Eroare");
                }
            );
        };

        vm.initData();

        /**
         * Function used to create or update decision Maker
         */
        vm.saveDecisionMaker = function() {
            var deferred = $q.defer();

            var serverRequestArray = [];
            if (vm.isEditMode) {
                serverRequestArray.push(FormFactoriDecizionaliService.update(vm.acqProcedureId, vm.decisionMaker));
            } else {
                serverRequestArray.push(FormFactoriDecizionaliService.create(vm.acqProcedureId, vm.decisionMaker));
            }

            $q.all(serverRequestArray).then(
                function success(responseArray) {
                    vm.decisionMaker = responseArray[0];
                    vm.isEditMode = true;
                    deferred.resolve(responseArray);
                }, function error(errMsg) {
                    deferred.reject(errMsg);
                }
            );
            return deferred.promise;
        };

        vm.STARE_ACTIV = 'ACTIV';
        vm.STARE_INACTIV = 'INACTIV';

        // Inactiveaza
        vm.activeazaFactor = function() {
            if (vm.decisionMaker.decisionMakerId) {
                vm.decisionMaker.isActive = true;
                vm.saveDecisionMaker().then(
                    function success(responseArray) {
                        SeapAlertService.toastSuccess('Activare cu succes!', 'Succes');
                    }, function error(errMsg) {
                        SeapAlertService.toastError('Eroare la activare factor!', 'Eroare');
                        vm.decisionMaker.isActive = false;
                    }
                );
            }
        };

        // Inactiveaza
        vm.inactiveazaFactor = function() {
            if (vm.decisionMaker.decisionMakerId) {
                vm.decisionMaker.isActive = false;
                vm.saveDecisionMaker().then(
                    function success(responseArray) {
                        SeapAlertService.toastSuccess('Inactivare cu succes!', 'Succes');
                    }, function error(errMsg) {
                        SeapAlertService.toastError('Eroare la inactivare factor!', 'Eroare');
                        vm.decisionMaker.isActive = true;
                    }
                );
            }
        };

        // Schimba stare semnatar raport Procedura
        vm.onToggleSwitch = function(toggleName) {
            if (vm.decisionMaker.decisionMakerId) {
                vm.saveDecisionMaker().then(
                    function success(responseArray) {
                        SeapAlertService.toastSuccess('Salvare cu succes!', 'Succes');
                    }, function error(errMsg) {
                        SeapAlertService.toastError('Eroare la modificare date despre factor! Mesaj: ' + errMsg, 'Eroare');
                        if (toggleName === 'isMandatoryStmtAsset') {
                            vm.decisionMaker.isMandatoryStmtAsset = !vm.decisionMaker.isMandatoryStmtAsset;
                        } else if (toggleName === 'isSignerProcRep') {
                            vm.decisionMaker.isSignerProcRep = !vm.decisionMaker.isSignerProcRep;
                        }
                    }
                );
            }
        };
    }
})();
