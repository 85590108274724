/**
 * Created by florin.iacob on 5/16/2017.
 */
(function() {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .controller('FondUeFormCtrl', FondUeFormCtrl);

    FondUeFormCtrl.$inject = ['$state', '$q', 'SeapAlertService', 'NomenclatorService', 'FormProceduraAchizitieService', 'EuFundService'];

    function FondUeFormCtrl($state, $q, SeapAlertService, NomenclatorService, FormProceduraAchizitieService, EuFundService) {

        var vm = this;

        /*{
            acqProcedure: '=',
            isReadOnly: '='
         }**/

        vm.acqProcedureId = vm.acqProcedure.acqProcedureId;

        vm.dataInitialized = false;
        /**
         * Initializare date
         */
        vm.initData = function() {
            var serverRequests = [
                NomenclatorService.findByKey('NomEuFund'),
                EuFundService.getNomEuProgramList(),
                EuFundService.getNomEuAxeList()
            ];

            vm.data = {};

            // Aducere date Server
            vm.isLoadingData = true;
            $q.all(serverRequests).then(
                function success(responseArray) {

                    vm.data.nomEuFund = responseArray[0];
                    vm.data.nomEuFund.unshift({id: null, name: ""});
                    vm.data.nomEuProgram = responseArray[1];
                    vm.data.nomEuAxe = responseArray[2];

                    vm.isLoadingData = false;
                    vm.dataInitialized = true;

                    if (vm.acqProcedure.nomEuFundDTO && vm.acqProcedure.nomEuFundDTO.id) {
                        vm.onNomEuFundChange(vm.acqProcedure.nomEuFundDTO.id, true);
                    }
                    if (vm.acqProcedure.nomEuProgramDTO && vm.acqProcedure.nomEuProgramDTO.id) {
                        vm.onNomEuProgramChange(vm.acqProcedure.nomEuProgramDTO.id, true);
                    }
                },
                function error(errorArray) {
                    SeapAlertService.toastError('Eroare la aducere date FOND UE!');
                    vm.isLoadingData = false;
                    vm.dataInitialized = true;
                }
            );
        };

        if (vm.acqProcedureId) {
            vm.initData();
        }

        // Editable
        vm.isFondUeFormEditable = false;
        vm.toggleFormUeFondEdit = function() {
            vm.isFondUeFormEditable = !vm.isFondUeFormEditable;
            if (vm.isFondUeFormEditable && !vm.dataInitialized) {
                vm.initData();
            }
        };

        /**
         * Salveaza informatiile depre fond UE
         */
        vm.saveFondUeForm = function() {
            vm.isLoadingData = true;
            if (!FormProceduraAchizitieService.validateAcqProcedureAndWarn(vm.acqProcedure)) {
                vm.isLoadingData = false;
                return;
            }

            if (vm.acqProcedure.nomEuFundDTO && !vm.acqProcedure.nomEuFundDTO.id) {
                vm.acqProcedure.nomEuFundDTO = null;
            }
            if (vm.acqProcedure.nomEuProgramDTO && !vm.acqProcedure.nomEuProgramDTO.id) {
                vm.acqProcedure.nomEuProgramDTO = null;
            }
            if (vm.acqProcedure.nomEuAxeDTO && !vm.acqProcedure.nomEuAxeDTO.id) {
                vm.acqProcedure.nomEuAxeDTO = null;
            }

            var saveProcedureRequest = [];
            saveProcedureRequest.push(FormProceduraAchizitieService.update(vm.acqProcedure));

            $q.all(saveProcedureRequest).then(
                function success(procedureArray) {
                    SeapAlertService.toastSuccess('Date despre FOND UE salvate cu succes!');
                    vm.isFondUeFormEditable = false;
                    vm.isLoadingData = false;
                }, function error(errorMsg) {
                    vm.isLoadingData = false;
                    SeapAlertService.toastError('Eroare la salvarea datelor despre FOND UE. Mesaj: ' + errorMsg, 'Eroare');
                }
            );
        };

        vm.nomEuProgramToChooseList = [];
        vm.nomEuAxeToChooseList = [];

        vm.onNomEuFundChange = function(nomEuFundId, shouldKeepSelectedValues) {
            vm.nomEuProgramToChooseList = [];
            vm.nomEuAxeToChooseList = [];
            angular.forEach(vm.data.nomEuProgram, function(nomEuProgramItem, index) {
                if (nomEuFundId == nomEuProgramItem.nomEuFundId) {
                    vm.nomEuProgramToChooseList.push(nomEuProgramItem);
                }
            });
            if (!shouldKeepSelectedValues) {
                if (vm.acqProcedure.nomEuProgramDTO) {
                    vm.acqProcedure.nomEuProgramDTO.id = null;
                }
                if (vm.acqProcedure.nomEuAxeDTO) {
                    vm.acqProcedure.nomEuAxeDTO.id = null;
                }
            }
        };

        vm.onNomEuProgramChange = function(nomEuProgramId, shouldKeepSelectedValues) {
            vm.nomEuAxeToChooseList = [];
            angular.forEach(vm.data.nomEuAxe, function(nomEuAxeItem, index) {
                if (nomEuProgramId == nomEuAxeItem.nomEuProgramId) {
                    vm.nomEuAxeToChooseList.push(nomEuAxeItem);
                }
            });
            if (!shouldKeepSelectedValues) {
                if (vm.acqProcedure.nomEuAxeDTO) {
                    vm.acqProcedure.nomEuAxeDTO.id = null;
                }
            }
        };
    }
})();
