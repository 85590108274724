/**
 * Created by florin.iacob on 5/16/2017.
 */
(function() {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .controller('FormConsultantiDocumentatieSavePjCtrl', FormConsultantiDocumentatieSavePjCtrl);

    FormConsultantiDocumentatieSavePjCtrl.$inject = ['$q', 'SeapAlertService', 'urlParams', 'FormProceduraAchizitieService', 'FormConsultantiDocumentatieService', 'Constants'];

    function FormConsultantiDocumentatieSavePjCtrl($q, SeapAlertService, urlParams, FormProceduraAchizitieService, FormConsultantiDocumentatieService, Constants) {

        var vm = this;
        if (urlParams.idProcedura) {
            vm.acqProcedureId = parseInt(urlParams.idProcedura);
        }
        vm.isEditMode = false;
        if (urlParams.idConsultant) {
            vm.isEditMode = true;
            vm.procurementConsultantId = parseInt(urlParams.idConsultant);
        }
        vm.validationMessage = urlParams.validationMessage;
        vm.isLoadingData = false;

        vm.INTEGRITY_FORM_SECTION = Constants.INTEGRITY_FORM_SECTION;
        vm.INTEGRITY_FORM_SUBSECTION = Constants.INTEGRITY_FORM_SUBSECTION;

        /**
         * Verificare daca formularul este doar in modul de citire date, dara editare
         */
        vm.checkIfReadonly = function() {
            vm.isReadOnlyMode = false;

            if (vm.acqProcedure.isReadOnly
                || !vm.acqProcedure.editableSectionScheme[vm.INTEGRITY_FORM_SECTION.I_3]) {

                vm.isReadOnlyMode = true;
            }
        };

        /**
         * Intializeaza date Controller
         */
        vm.initData = function() {
            var serverRequestArray = [];
            serverRequestArray.push(FormProceduraAchizitieService.findById(vm.acqProcedureId));
            serverRequestArray.push(FormProceduraAchizitieService.getEditableSubsectionTypeList(vm.acqProcedureId, vm.INTEGRITY_FORM_SECTION.I_3));
            if (vm.isEditMode) {
                serverRequestArray.push(FormConsultantiDocumentatieService.findById(vm.procurementConsultantId));
            }

            vm.isLoadingData = true;
            $q.all(serverRequestArray).then(
                function success(responseArray) {
                    vm.acqProcedure = responseArray[0];
                    vm.editableSubsectionTypeList = responseArray[1];

                    if (vm.isEditMode) {
                        vm.procurementConsultant = responseArray[2];
                    } else {
                        vm.procurementConsultant = {
                            isActive: true
                        };
                    }
                    vm.checkIfReadonly();
                    vm.isLoadingData = false;
                }, function(errMsg) {
                    vm.isLoadingData = false;
                    SeapAlertService.toastError("Eroare la aducere date!", "Eroare");
                }
            );
        };

        vm.initData();

        /**
         * Function used to create or update Procurement Consultant
         */
        vm.saveProcurementConsultant = function() {
            var deferred = $q.defer();

            var serverRequestArray = [];
            if (vm.isEditMode) {
                serverRequestArray.push(FormConsultantiDocumentatieService.update(vm.acqProcedureId, vm.procurementConsultant));
            } else {
                serverRequestArray.push(FormConsultantiDocumentatieService.create(vm.acqProcedureId, vm.procurementConsultant));
            }

            $q.all(serverRequestArray).then(
                function success(responseArray) {
                    vm.procurementConsultant = responseArray[0];
                    vm.isEditMode = true;
                    deferred.resolve(responseArray);
                }, function error(errMsg) {
                    deferred.reject(errMsg);
                }
            );
            return deferred.promise;
        };

        vm.STARE_ACTIV = 'ACTIV';
        vm.STARE_INACTIV = 'INACTIV';

        // Inactiveaza
        vm.activeazaConsultantPJ = function() {
            if (vm.procurementConsultant.procurementConsultantId) {
                vm.procurementConsultant.isActive = true;
                vm.saveProcurementConsultant().then(
                    function success(responseArray) {
                        SeapAlertService.toastSuccess('Activare cu succes!', 'Succes');
                    }, function error(errMsg) {
                        SeapAlertService.toastError('Eroare la activare consultant! Mesaj eroare: ' + errMsg, 'Eroare');
                        vm.procurementConsultant.isActive = false;
                    }
                );
            }
        };

        // Inactiveaza
        vm.inactiveazaConsultantPJ = function() {
            if (vm.procurementConsultant.procurementConsultantId) {
                vm.procurementConsultant.isActive = false;
                vm.saveProcurementConsultant().then(
                    function success(responseArray) {
                        SeapAlertService.toastSuccess('Inactivare cu succes!', 'Succes');
                    }, function error(errMsg) {
                        SeapAlertService.toastError('Eroare la inactivare consultant! Mesaj eroare: ' + errMsg, 'Eroare');
                        vm.procurementConsultant.isActive = true;
                    }
                );
            }
        };
    }
})();
