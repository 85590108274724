/**
 * Created by florin.iacob on 5/16/2017.
 */
(function () {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .controller('IntegrityFormVersionListareCtrl', IntegrityFormVersionListareCtrl);

    IntegrityFormVersionListareCtrl.$inject = ['$state', '$window', '$q', 'API_BASE_URL', 'SeapAlertService', 'urlParams', 'GenericService', '$uibModal', 'IntegrityFormService'];

    function IntegrityFormVersionListareCtrl($state, $window, $q, API_BASE_URL, SeapAlertService, urlParams, GenericService, $uibModal, IntegrityFormService) {

        var vm = this;

        vm.idIntegrityForm = urlParams.idIntegrityForm;
        if (vm.idIntegrityForm) {
            vm.idIntegrityForm = parseInt(vm.idIntegrityForm);
        }

        vm.idProcedure = urlParams.idProcedure;
        if (vm.idProcedure) {
            vm.idProcedure = parseInt(vm.idProcedure);
        }

        vm.integrityFormVersionList = null;
        vm.isLoadingData = true;

        var serverRequest = [];
        serverRequest.push(GenericService.GetFn('/integrityFormVersion/findAllByIntegrityFormId/'+ vm.idIntegrityForm));
        if (vm.idIntegrityForm) {
            serverRequest.push(IntegrityFormService.findById(vm.idIntegrityForm));
        } else {
            serverRequest.push(IntegrityFormService.findByAcqProcedureId(vm.idProcedure));
        }

        $q.all(serverRequest).then(
            function success(responseArray) {
                vm.integrityFormVersionList = responseArray[0];
                vm.integrityForm = responseArray[1];
                if (!vm.idProcedure) {
                    vm.idProcedure = vm.integrityForm.acqProcedureDTO.acqProcedureId;
                }
                vm.isLoadingData = false;
            },
            function error(errorMsg) {
                SeapAlertService.toastError(errorMsg);
                vm.isLoadingData = false;
            }
        );

        /**
         * Download Versiune Formular in format XML
         *
         * @param integrityFormVersionId
         */
        vm.getXmlContent = function (integrityFormVersionId){
            $window.location.href = API_BASE_URL +'/integrityFormVersion/getXmlContent/'+ integrityFormVersionId;
        };

        /**
         * Deschidere pagina pentru vizualizare Vesriune Formular HTML
         *
         * @param integrityFormVersionId
         */
        vm.viewHtml = function(integrityFormVersionId) {
            $state.go('integrity-form-version-view', {form: vm.idIntegrityForm, proc: vm.idProcedura, vers: integrityFormVersionId});
        };
    }
})();
