/**
 * Created by florin.iacob on 5/16/2017.
 */
(function() {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .controller('FormComisieEvaluareSaveMembruCtrl', FormComisieEvaluareSaveMembruCtrl);

    FormComisieEvaluareSaveMembruCtrl.$inject = ['$q', 'SeapAlertService', 'urlParams', 'EvalCommitteMemberService', 'IntegrityFormService', 'FormProceduraAchizitieService', 'Constants'];

    function FormComisieEvaluareSaveMembruCtrl($q, SeapAlertService, urlParams, EvalCommitteMemberService, IntegrityFormService, FormProceduraAchizitieService, Constants) {

        var vm = this;
        if (urlParams.acqProcedureId) {
            vm.acqProcedureId = parseInt(urlParams.acqProcedureId);
        }
        if (urlParams.evalCommitteeId) {
            vm.evalCommitteeId = parseInt(urlParams.evalCommitteeId);
        }
        vm.isEditMode = false;
        if (urlParams.evalCommitteeMemberId) {
            vm.isEditMode = true;
            vm.evalCommitteeMemberId = parseInt(urlParams.evalCommitteeMemberId);
        }
        vm.validationMessage = urlParams.validationMessage;
        vm.INTEGRITY_FORM_SECTION = Constants.INTEGRITY_FORM_SECTION;
        vm.INTEGRITY_FORM_SUBSECTION = Constants.INTEGRITY_FORM_SUBSECTION;

        /**
         * Verificare daca formularul este doar in modul de citire date, dara editare
         */
        vm.checkIfReadonly = function() {
            vm.isReadOnlyMode = false;

            if (vm.acqProcedure.isReadOnly
                || !vm.acqProcedure.editableSectionScheme[vm.INTEGRITY_FORM_SECTION.I_4]) {

                vm.isReadOnlyMode = true;
            }
        };

        vm.isLoadingData = false;

        /**
         * Intializeaza date Controller
         */
        vm.initData = function() {
            var serverRequestArray = [];
            serverRequestArray.push(IntegrityFormService.findByAcqProcedureId(vm.acqProcedureId));
            serverRequestArray.push(FormProceduraAchizitieService.getEditableSubsectionTypeList(vm.acqProcedureId, vm.INTEGRITY_FORM_SECTION.I_4));
            if (vm.isEditMode) {
                serverRequestArray.push(EvalCommitteMemberService.findById(vm.evalCommitteeMemberId));
            }

            vm.isLoadingData = true;
            $q.all(serverRequestArray).then(
                function success(responseArray) {
                    vm.integrityForm = responseArray[0];
                    vm.acqProcedure = vm.integrityForm.acqProcedureDTO;
                    vm.editableSubsectionTypeList = responseArray[1];

                    if (vm.isEditMode) {
                        vm.evalCommitteeMember = responseArray[2];
                    } else {
                        vm.evalCommitteeMember = {
                            isActive: true,
                            isMandatoryStmtAsset: true,
                            isSignerProcRep: true,
                            isTenderOpenParticipant: true,
                            isContractSigner: true
                        };
                    }
                    vm.checkIfReadonly();
                    vm.isLoadingData = false;
                }, function(errMsg) {
                    vm.isLoadingData = false;
                    SeapAlertService.toastError("Eroare la aducere date!", "Eroare");
                }
            );
        };

        vm.initData();

        /**
         * Function used to create or update decision Maker
         */
        vm.saveEvalCommitteeMember = function() {
            var deferred = $q.defer();

            var serverRequestArray = [];
            if (vm.isEditMode) {
                serverRequestArray.push(EvalCommitteMemberService.update(vm.acqProcedureId, vm.evalCommitteeMember));
            } else {
                serverRequestArray.push(EvalCommitteMemberService.create(vm.acqProcedureId, vm.evalCommitteeId, vm.evalCommitteeMember));
            }

            $q.all(serverRequestArray).then(
                function success(responseArray) {
                    vm.evalCommitteeMember = responseArray[0];
                    vm.isEditMode = true;
                    deferred.resolve(responseArray);
                }, function error(errMsg) {
                    deferred.reject(errMsg);
                }
            );
            return deferred.promise;
        };

        vm.STARE_ACTIV = 'ACTIV';
        vm.STARE_INACTIV = 'INACTIV';

        // Inactiveaza
        vm.activeazaMembru = function() {
            if (vm.evalCommitteeMember.evalCommitteeMemberId) {
                vm.evalCommitteeMember.isActive = true;
                vm.saveEvalCommitteeMember().then(
                    function success(responseArray) {
                        SeapAlertService.toastSuccess('Activare cu succes!', 'Succes');
                    }, function error(errMsg) {
                        SeapAlertService.toastError('Eroare la activare membru! Mesaj eroare: ' + errMsg, 'Eroare');
                        vm.evalCommitteeMember.isActive = false;
                    }
                );
            }
        };

        // Inactiveaza
        vm.inactiveazaMembru = function() {
            if (vm.evalCommitteeMember.evalCommitteeMemberId) {
                vm.evalCommitteeMember.isActive = false;
                vm.saveEvalCommitteeMember().then(
                    function success(responseArray) {
                        SeapAlertService.toastSuccess('Inactivare cu succes!', 'Succes');
                    }, function error(errMsg) {
                        SeapAlertService.toastError('Eroare la inactivare membru! Mesaj eroare: ' + errMsg, 'Eroare');
                        vm.evalCommitteeMember.isActive = true;
                    }
                );
            }
        };

        // Schimba stare semnatar raport Procedura
        vm.onToggleSwitch = function(toggleName) {
            if (vm.evalCommitteeMember.evalCommitteeMemberId) {
                vm.saveEvalCommitteeMember().then(
                    function success(responseArray) {
                        SeapAlertService.toastSuccess('Salvare cu succes!', 'Succes');
                    }, function error(errMsg) {
                        SeapAlertService.toastError('Eroare la modificare date despre Membru comisie! Mesaj eroare: ' + errMsg, 'Eroare');
                        if (toggleName === 'isMandatoryStmtAsset') {
                            vm.evalCommitteeMember.isMandatoryStmtAsset = !vm.evalCommitteeMember.isMandatoryStmtAsset;
                        } else if (toggleName === 'isSignerProcRep') {
                            vm.evalCommitteeMember.isSignerProcRep = !vm.evalCommitteeMember.isSignerProcRep;
                        } else if (toggleName === 'isTenderOpenParticipant') {
                            vm.evalCommitteeMember.isTenderOpenParticipant = !vm.evalCommitteeMember.isTenderOpenParticipant;
                        }
                    }
                );
            }
        };

    }
})();
