/**
 * Created by florin.iacob on 5/22/2017.
 */
(function() {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .factory('AddressService', AddressService);

    AddressService.$inject = ['$q', '$http', 'API_BASE_URL'];

    function AddressService($q, $http, API_BASE_URL) {

        var Service = {};

        /**
         * Aducerea unei adrese pentru Persoana specificata
         */
        Service.findByPersonId = function(personId) {
            var deferred = $q.defer();
            // make server request
            $http({
                method: 'GET',
                url: API_BASE_URL + '/address/findByPersonId/' + personId,
                headers: {
                    'Content-Type': "application/json"
                }
            }).then(
                function success(response) {
                    deferred.resolve(response.data);

                }, function error(response) {
                    deferred.reject(response.data.message);
                    console.error(' -- REST call Error: ' + API_BASE_URL + '/address/findByPersonId/' + personId);
                }
            );
            return deferred.promise;
        };

        /**
         * Creaza o adresa pentru persoana specificata
         */
        Service.create = function(entityName, personId, addressDTO) {
            var deferred = $q.defer();
            // make server request
            $http({
                method: 'POST',
                url: API_BASE_URL + '/address/create/' + entityName + "/" + personId,
                headers: {
                    'Content-Type': "application/json"
                },
                data: JSON.stringify(addressDTO)
            }).then(
                function success(response) {
                    deferred.resolve(response.data);

                }, function error(response) {
                    var errMsg = 'Eroare la salvarea Adresei!';
                    if (response.data && response.data.message) {
                        errMsg = response.data.message;
                    }
                    deferred.reject(errMsg);
                    console.error(' -- REST POST call Error: /address/create/' + personId);
                }
            );
            return deferred.promise;
        };

        /**
         * Actualizeaza o adresa pentru persoana specificata
         */
        Service.update = function(entityName, personId, addressDTO) {
            var deferred = $q.defer();
            // make server request
            $http({
                method: 'PUT',
                url: API_BASE_URL + '/address/update/' + entityName + "/" + personId,
                headers: {
                    'Content-Type': "application/json"
                },
                data: JSON.stringify(addressDTO)
            }).then(
                function success(response) {
                    deferred.resolve(response.data);

                }, function error(response) {
                    var errMsg = 'Eroare la salvarea Adresei!';
                    if (response.data && response.data.message) {
                        errMsg = response.data.message;
                    }
                    deferred.reject(errMsg);
                    console.error(' -- REST POST call Error: /address/update/' + personId);
                }
            );
            return deferred.promise;
        };

        /**
         * Intoarce o adresa in format string pentru adresa specificata
         *
         * @param addressObj
         */
        Service.addressToString = function(addressObj) {
            var addressString = "TARA: " + addressObj.nomCountryDTO.name;
            if (addressObj.nomCountryDTO.code === "RO") {
                addressString += ", JUDET: " + addressObj.nomCountyDTO.name;
                addressString += ", LOC: " + addressObj.locality;
                addressString += ", STR: " + addressObj.streetName;
                if(addressObj.streetNumber) {
                    addressString += ", NR: " + addressObj.streetNumber;
                }
                if (addressObj.building) {
                    addressString += ", BLOC: " + addressObj.building;
                }
                if (addressObj.floor) {
                    addressString += ", ETAJ: " + addressObj.floor;
                }
                if (addressObj.entrance) {
                    addressString += ", SC: " + addressObj.entrance;
                }
                if (addressObj.apartment) {
                    addressString += ", APT: " + addressObj.apartment;
                }
                if (addressObj.district) {
                    addressString += ", SECTOR: " + addressObj.district;
                }
                if (addressObj.postalCode) {
                    addressString += ", COD POSTAL: " + addressObj.postalCode;
                }
                if (addressObj.addressDesc) {
                    // adresa externa
                    addressString += ", (ADRESA COMPLETA: " + addressObj.addressDesc +")";
                }
            } else {
                // adresa externa
                addressString += ", ADRESA EXTERNA: " + addressObj.addressDesc;
            }



            return addressString;
        };

        return Service
    }
})();

