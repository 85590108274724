/**
 * Created by florin.iacob on 5/16/2017.
 */
(function() {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .controller('FormOfertantiSaveMembruCtrl', FormOfertantiSaveMembruCtrl);

    FormOfertantiSaveMembruCtrl.$inject = ['$q', 'SeapAlertService', 'urlParams', 'FormProceduraAchizitieService', 'TendererAssocMemberService', 'Constants', 'Principal'];

    function FormOfertantiSaveMembruCtrl($q, SeapAlertService, urlParams, FormProceduraAchizitieService, TendererAssocMemberService, Constants, Principal) {

        var vm = this;
        if (urlParams.idProcedura) {
            vm.acqProcedureId = parseInt(urlParams.idProcedura);
        }
        if (urlParams.idOfertant) {
            vm.tendererId = parseInt(urlParams.idOfertant);
        }
        vm.isEditMode = false;
        if (urlParams.idMembru) {
            vm.isEditMode = true;
            vm.tendererAssocMemberId = parseInt(urlParams.idMembru);
        }
        vm.validationMessage = urlParams.validationMessage;

        vm.INTEGRITY_FORM_SECTION = Constants.INTEGRITY_FORM_SECTION;
        vm.INTEGRITY_FORM_SUBSECTION = Constants.INTEGRITY_FORM_SUBSECTION;

        vm.hasRoleManualAddTendererMember = Principal.hasAnyAuthority(['ROLE_MANUAL_ADD_ALL', 'ROLE_MANUAL_ADD_TENDERER_MEMBER']);

        /**
         * Verificare daca formularul este doar in modul de citire date, dara editare
         */
        vm.checkIfReadonly = function() {
            vm.isReadOnlyMode = false;

            if (vm.acqProcedure.isReadOnly
                || !vm.acqProcedure.editableSectionScheme[vm.INTEGRITY_FORM_SECTION.II]) {

                vm.isReadOnlyMode = true;
            }
        };

        vm.isLoadingData = false;

        /**
         * Initializeaza date Controller
         */
        vm.initData = function() {
            var serverRequestArray = [];
            serverRequestArray.push(FormProceduraAchizitieService.findById(vm.acqProcedureId));
            serverRequestArray.push(FormProceduraAchizitieService.getEditableSubsectionTypeList(vm.acqProcedureId, vm.INTEGRITY_FORM_SECTION.II));
            if (vm.isEditMode) {
                serverRequestArray.push(TendererAssocMemberService.findById(vm.tendererAssocMemberId));
            }

            vm.isLoadingData = true;
            $q.all(serverRequestArray).then(
                function success(responseArray) {
                    vm.acqProcedure = responseArray[0];
                    vm.editableSubsectionTypeList = responseArray[1];

                    if (vm.isEditMode) {
                        vm.tendererAssocMember = responseArray[2];
                    } else {
                        vm.tendererAssocMember = {
                        };
                    }

                    vm.hasRoleManualAddTendererMember = vm.hasRoleManualAddTendererMember && vm.acqProcedure.isLocal;

                    vm.checkIfReadonly();
                    vm.isLoadingData = false;
                }, function(errMsg) {
                    vm.isLoadingData = false;
                    SeapAlertService.toastError("Eroare la aducere date!", "Eroare");
                }
            );
        };

        vm.initData();

        /**
         * Function used to create or update Assoc Member
         */
        vm.saveTendererAssocMember = function() {
            var deferred = $q.defer();

            var serverRequestArray = [];
            if (vm.isEditMode) {
                serverRequestArray.push(TendererAssocMemberService.update(vm.acqProcedureId, vm.tendererAssocMember));
            } else {
                serverRequestArray.push(TendererAssocMemberService.create(vm.acqProcedureId, vm.tendererId, vm.tendererAssocMember));
            }

            $q.all(serverRequestArray).then(
                function success(responseArray) {
                    vm.tendererAssocMember = responseArray[0];
                    vm.isEditMode = true;
                    deferred.resolve(responseArray);
                }, function error(errMsg) {
                    deferred.reject(errMsg);
                }
            );
            return deferred.promise;
        };

    }
})();
