/**
 * Created by florin.iacob on 5/16/2017.
 */
(function() {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .controller('AdresaFormCtrl', AdresaFormCtrl);

    AdresaFormCtrl.$inject = ['$q', 'SeapAlertService', '$uibModal', 'AddressService', 'NomenclatorService', '$scope'];

    function AdresaFormCtrl($q, SeapAlertService, $uibModal, AddressService, NomenclatorService, $scope) {

        var vm = this;
        /*
            formTitle = '@',
            personId: '=',
            entityName: '@',
            isReadOnly: '='
        */
        vm.taraRomaniaId = 1;
        vm.adresaRomania = true;
        vm.isLoadingData = false;
        vm.isEditMode = false;

        /**
         * Intializare date Controller
         */
        vm.initData = function() {
            var serverRequests = [
                NomenclatorService.findByKey('NomCountry'),
                NomenclatorService.findByKey('NomCounty'),
            ];
            if (vm.personId) {
                serverRequests.push(AddressService.findByPersonId(vm.personId))
            }
            vm.data = {};

            // Aducere date Server
            vm.isLoadingData = true;
            $q.all(serverRequests).then(
                function success(responseArray) {

                    vm.data.nomCountryList = responseArray[0];
                    vm.data.nomCountyList = responseArray[1];

                    if (vm.personId && responseArray[2] && responseArray[2].nomCountryDTO) {
                        vm.isEditMode = true;
                        vm.address = responseArray[2];
                        vm.adresaRomania = vm.address.nomCountryDTO.id == 1;
                    } else {
                        vm.address = {
                            nomCountryDTO: {id: vm.taraRomaniaId, code: 'RO'},
                            nomCountyDTO: {id: null},
                            locality: null,
                            streetName: null,
                            streetNumber: null,
                            building: null,
                            entrance: null,
                            floor: null,
                            apartment: null,
                            postalCode: null,
                            addressDesc: null
                        };
                        vm.adresaRomania = true;
                    }

                    vm.isLoadingData = false;
                },
                function error(error) {
                    SeapAlertService.toastError('Eroare la aducere date despre Adresa!');
                    vm.isLoadingData = false;
                }
            );
        };

        vm.initData();


        // Schimbarea tarii
        vm.onCountryChange = function() {
            if (vm.address.nomCountryDTO.id == vm.taraRomaniaId) {
                vm.adresaRomania = true;
            } else {
                vm.adresaRomania = false;
                vm.address.nomCountyDTO = null;
            }
            angular.forEach(vm.data.nomCountryList, function(nomCountry, index) {
                if (nomCountry.id === vm.address.nomCountryDTO.id) {
                    vm.address.nomCountryDTO.name = nomCountry.name;
                    vm.address.nomCountryDTO.code = nomCountry.code;
                }
            });
        };

        // Editable
        vm.isAdresaFormEditable = false;
        vm.toggleAdresaFormEdit = function() {
            vm.isAdresaFormEditable = !vm.isAdresaFormEditable;
        };

        /**
         * Salveaza informatiile depre Adresa
         */
        vm.saveAdresaForm = function() {
            var serverRequestArray = [];
            if (vm.isEditMode) {
                serverRequestArray.push(AddressService.update(vm.entityName, vm.personId, vm.address));
            } else {
                serverRequestArray.push(AddressService.create(vm.entityName, vm.personId, vm.address));
            }

            vm.isLoadingData = true;
            $q.all(serverRequestArray).then(
                function success(responseArray) {
                    vm.address = responseArray[0];
                    vm.isAdresaFormEditable = false;
                    vm.isEditMode = true;
                    vm.isLoadingData = false;
                    $scope.formAddress.$pristine = true;
                    SeapAlertService.toastSuccess("Adresa salvata!", "Succes");

                }, function error(errMsg) {
                    vm.isLoadingData = false;
                    SeapAlertService.toastError("Eroare la salvare adresa! Mesaj: " + errMsg, "Eroare");
                }
            );
        };

        $scope.$watch('vm.personId', function(newValue, oldValue) {
            console.log(" - $scope.$watch('vm.personId' = " + newValue);
            if (newValue != null && oldValue == null) {
                vm.initData();
            }

        });

    }
})();
