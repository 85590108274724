/**
 * Created by florin.iacob on 5/16/2017.
 */
(function() {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .controller('PersoanaFizicaCtrl', PersoanaFizicaCtrl);

    PersoanaFizicaCtrl.$inject = ['$q', 'SeapAlertService', 'NaturalPersonService', 'NomenclatorService', '$scope'];

    function PersoanaFizicaCtrl($q, SeapAlertService, NaturalPersonService, NomenclatorService, $scope) {

        var vm = this;
        /*
            formTitle
            naturalPersonEntity: '=',
            onSaveCallback: '&',
            isContractAuth: '=',
            isReadOnly: '=',
            isSicapImport: '='
        */

        vm.isEditMode = false;

        vm.isCnpEditable = true;
        vm.hasCNP = true;
        if (!vm.naturalPersonEntity) {
            vm.isEditMode = false;
            vm.naturalPersonEntity = {
                sex: "M",
                citizenshipDTOs: []
            };
        } else {
            vm.isEditMode = true;
            if (vm.naturalPersonEntity.pin) {
                vm.hasCNP = false;
            } else {
                vm.hasCNP = true;
            }
            if (vm.isSicapImport) {
                vm.isCnpEditable = false;
            }
        }

        vm.initData = function() {
            var serverRequests = [
                NomenclatorService.findByKey('NomCountry')
            ];
            vm.data = {};

            // Aducere date Server
            vm.isLoadingData = true;
            $q.all(serverRequests).then(
                function success(responseArray) {

                    vm.data.nomCountryList = responseArray[0];

                    vm.isLoadingData = false;
                },
                function error(error) {
                    SeapAlertService.toastError('Eroare la aducere date despre Persoana Fizica!');
                    vm.isLoadingData = false;
                }
            );
        };

        vm.initData();
        vm.selectedCountry = null;

        /**
         * Adauga cetatenie pentru persoana fizica
         * @param selectedCetatenie
         */
        vm.addCetatenie = function(selectedCountryID) {
            var isAlredyPresent = false;
            angular.forEach(vm.naturalPersonEntity.citizenshipDTOs, function(citizenshipDTO, index) {
                if (citizenshipDTO.nomCountryDTO.id === parseInt(selectedCountryID) && !citizenshipDTO.isDeleted) {
                    isAlredyPresent = true;
                    SeapAlertService.toastError("Cetatenie deja selectata!", "Atentie");
                    return;
                }
            });

            if (!isAlredyPresent) {
                angular.forEach(vm.data.nomCountryList, function (countryItem, index) {
                    if (countryItem.id === parseInt(selectedCountryID)) {
                        vm.naturalPersonEntity.citizenshipDTOs.push({
                            citizenshipId: null,
                            nomCountryDTO: countryItem
                        });
                        $scope.formNaturalPerson.$pristine = false;
                        return;
                    }
                });
            }
        };

        /**
         * Sterge cetatenie
         * @param cetatenie
         */
        vm.deleteCetatenie = function(citizenship) {
            angular.forEach(vm.naturalPersonEntity.citizenshipDTOs, function(citizenshipItem, index) {
                if (citizenship.citizenshipId) {
                    if (citizenshipItem.citizenshipId === citizenship.citizenshipId) {
                        vm.naturalPersonEntity.citizenshipDTOs[index].isDeleted = true;
                        return;
                    }
                } else {
                    if (citizenshipItem.nomCountryDTO.id === citizenship.nomCountryDTO.id) {
                        vm.naturalPersonEntity.citizenshipDTOs.splice(index, 1);
                        return;
                    }
                }
            });
            $scope.formNaturalPerson.$pristine = false;
        };

        // Editable
        vm.isPersoanaFizicaFormEditable = false;
        vm.togglePersoanaFizicaFormEdit = function() {
            vm.isPersoanaFizicaFormEditable = !vm.isPersoanaFizicaFormEditable;
        };

        /**
         * Salveaza informatiile depre Persoana Fizica
         */
        vm.savePersoanaFizicaForm = function () {
            if (vm.hasCNP) {
                vm.naturalPersonEntity.pin = null;
            } else {
                vm.naturalPersonEntity.cnp = null;
            }

            if (!vm.naturalPersonEntity.citizenshipDTOs || !vm.naturalPersonEntity.citizenshipDTOs.length) {
                SeapAlertService.toastError('Selectati si adaugati CETATENIE pentru persoana fizica!');
                return;
            }

            vm.isLoadingData = true;
            vm.onSaveCallback().then(
                function success(responseArray) {
                    vm.isPersoanaFizicaFormEditable = false;
                    vm.isLoadingData = false;
                    vm.isEditMode = true;
                    $scope.formNaturalPerson.$pristine = true;
                    vm.naturalPersonEntity.originalNaturalPersonId = null;
                    SeapAlertService.toastSuccess('Date despre Persoana Fizica salvate cu succes!', 'Succes');
                }, function error(errMsg) {
                    SeapAlertService.toastError('Eroare la salvare date despre Persoana Fizica! Mesaj eroare: ' + errMsg, 'Eroare');
                    vm.isLoadingData = false;
                }
            );
        };

        //ng-confirm="vm.preluareDatePF()" ng-confirm-message="{{vm.preluareDateMessage}}"
        vm.preluareDateMessage = "Doriti preluarea datelor pentru persoana fizica pe baza identificatorului completat?";

        // Preluare date Persoana Fizica
        vm.preluareDatePF = function() {
            if (!vm.naturalPersonEntity.cnp && !vm.naturalPersonEntity.pin) {
                SeapAlertService.toastError('Preluarea se poate face doar daca identificatorul CNP/PIN este completat!');
                return;
            }

            var serverRequests = [
                NaturalPersonService.findByIdentificator(vm.naturalPersonEntity.cnp, vm.naturalPersonEntity.pin)
            ];

            // Aducere date Server
            vm.isLoadingData = true;
            $q.all(serverRequests).then(
                function success(responseArray) {
                    var currentNaturalPersonId = vm.naturalPersonEntity.naturalPersonId;
                    vm.naturalPersonEntity = responseArray[0];
                    vm.naturalPersonEntity.personDTO.personId = null;
                    vm.naturalPersonEntity.originalNaturalPersonId = vm.naturalPersonEntity.naturalPersonId;
                    vm.naturalPersonEntity.naturalPersonId = currentNaturalPersonId;

                    angular.forEach(vm.naturalPersonEntity.citizenshipDTOs, function (citizenshipDTO, index) {
                        vm.naturalPersonEntity.citizenshipDTOs[index].citizenshipId = null;
                    });

                    SeapAlertService.toastSuccess('Date despre Persoana Fizica preluate cu succes!', 'Succes');
                    $scope.formNaturalPerson.$pristine = false;
                    vm.isLoadingData = false;
                },
                function error(errMsg) {
                    SeapAlertService.toastError('Nu s-au putut prelua datele! Mesaj: ' + errMsg);
                    vm.isLoadingData = false;
                }
            );
        };

        vm.onChangeTipIdentificator = function(isCNP) {
            if (!vm.isCnpEditable) {
                if (isCNP && !vm.naturalPersonEntity.cnp && vm.naturalPersonEntity.pin) {
                    vm.naturalPersonEntity.cnp = vm.naturalPersonEntity.pin;
                } else if (!isCNP && vm.naturalPersonEntity.cnp && !vm.naturalPersonEntity.pin) {
                    vm.naturalPersonEntity.pin = vm.naturalPersonEntity.cnp;
                }
            }
        };

    }
})();
