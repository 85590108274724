/**
 * Created by florin.iacob on 5/16/2017.
 */
(function () {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .controller('MeasureTakenCtrl', MeasureTakenCtrl);

    MeasureTakenCtrl.$inject = ['$q', '$uibModalInstance', 'SeapAlertService', 'integrityWarning', 'MeasureTakenService'];

    function MeasureTakenCtrl($q, $uibModalInstance, SeapAlertService, integrityWarning, MeasureTakenService) {
        var vm = this;
        vm.integrityWarning = integrityWarning;
        vm.isEditMode = !!(integrityWarning.measureTakenDTOs && integrityWarning.measureTakenDTOs.length > 0);
        vm.measureTaken = vm.isEditMode  ? integrityWarning.measureTakenDTOs[0] : {};


        vm.close = function () {
            $uibModalInstance.close();
        };

        /**
         * Inchide fereastra
         */
        vm.clear = function () {
            $uibModalInstance.dismiss('cancel');
        };

        /**
         * Salveaza Contract
         */
        vm.save = function () {
            vm.isLoadingData = true;
            var serverRequestArray = [];
            if (vm.isEditMode) {
                serverRequestArray.push(MeasureTakenService.update(vm.measureTaken));
            } else {
                serverRequestArray.push(MeasureTakenService.create(vm.integrityWarning.integrityWarningId, vm.measureTaken));
            }

            $q.all(serverRequestArray).then(
                function success(rsp) {
                    vm.measureTaken = rsp[0];
                    $uibModalInstance.close(vm.measureTaken);
                    SeapAlertService.toastSuccess('Masura dispusa salvata cu succes!', 'Succes');
                    vm.isLoadingData = false;
                }, function error(errorMsg) {
                    vm.isLoadingData = false;
                    SeapAlertService.toastError('Eroare la salvarea Masurii dispuse. Mesaj: ' + errorMsg, 'Eroare');
                }
            );
        }

    }
})();
