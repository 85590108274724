/**
 * Created by florin.iacob on 5/16/2017.
 */
(function() {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .controller('FormConsultantiDocumentatieListaCtrl', FormConsultantiDocumentatieListaCtrl);

    FormConsultantiDocumentatieListaCtrl.$inject = ['$q', '$state', 'SeapAlertService', 'urlParams', 'FormProceduraAchizitieService', 'FormConsultantiDocumentatieService', 'Constants', 'IntegrityWarningService', 'Principal'];

    function FormConsultantiDocumentatieListaCtrl($q, $state, SeapAlertService, urlParams, FormProceduraAchizitieService, FormConsultantiDocumentatieService, Constants, IntegrityWarningService, Principal) {

        var vm = this;
        if (urlParams.idProcedura) {
            vm.acqProcedureId = parseInt(urlParams.idProcedura);
        }
        vm.validationMessage = urlParams.validationMessage;
        vm.isLoadingData = false;

        vm.INTEGRITY_FORM_SECTION = Constants.INTEGRITY_FORM_SECTION;
        vm.INTEGRITY_FORM_SUBSECTION = Constants.INTEGRITY_FORM_SUBSECTION;

        vm.isAC = Principal.hasAnyAuthority(['ROLE_AC']);
        vm.isOpSeap = Principal.hasAnyAuthority(['ROLE_OP_SEAP']);

        /**
         * Verificare daca formularul este doar in modul de citire date, dara editare
         */
        vm.checkIfReadonly = function() {
            vm.isReadOnlyMode = false;

            if (vm.acqProcedure.isReadOnly
                || !vm.acqProcedure.editableSectionScheme[vm.INTEGRITY_FORM_SECTION.I_3]) {

                vm.isReadOnlyMode = true;
            }
        };

        /**
         * Se va face asocierea avertismentelor pentru ultima versiune cu persoanele din lista
         */
        vm.asociereAvertismenteCuPersoane = function() {
            if (vm.warningList && vm.warningList.length) {
                angular.forEach(vm.warningList, function(warningItem, warningIndex) {
                    if (warningItem.identificatorDtoList && warningItem.identificatorDtoList.length) {
                        angular.forEach(warningItem.identificatorDtoList, function(identificatorDtoItem, identificatorDtoIndex) {
                            angular.forEach(vm.consultantiPFList, function(consultantiPFItem, consultantiPFIndex) {
                                var hasWarning = false;
                                if (identificatorDtoItem.cnp) {
                                    if (identificatorDtoItem.cnp === consultantiPFItem.naturalPersonDTO.cnp) {
                                        hasWarning = true;
                                    }
                                } else {
                                    if (identificatorDtoItem.pin === consultantiPFItem.naturalPersonDTO.pin) {
                                        hasWarning = true;
                                    }
                                }
                                if (hasWarning) {
                                    vm.consultantiPFList[consultantiPFIndex].warningDesc = vm.warningList[warningIndex].warningDesc;
                                    vm.consultantiPFList[consultantiPFIndex].warningDate = vm.warningList[warningIndex].warningDate;
                                }
                            });
                        });
                    }
                });
            }
        };

        /**
         * Intializeaza date Controller
         */
        vm.initData = function() {
            var serverRequestArray = [];
            serverRequestArray.push(FormProceduraAchizitieService.findById(vm.acqProcedureId));
            serverRequestArray.push(FormConsultantiDocumentatieService.findByAcqProcedureId(vm.acqProcedureId));
            serverRequestArray.push(FormProceduraAchizitieService.getEditableSubsectionTypeList(vm.acqProcedureId, vm.INTEGRITY_FORM_SECTION.I_3));
            if (vm.isAC || vm.isOpSeap) {
                serverRequestArray.push(IntegrityWarningService.getWarningListForLastVersion(null, vm.acqProcedureId));
            }

            vm.isLoadingData = true;
            $q.all(serverRequestArray).then(
                function success(responseArray) {
                    vm.acqProcedure = responseArray[0];

                    vm.consultantiPFList = [];
                    vm.consultantiPJList = [];
                    vm.procurementConsultantDTOList = responseArray[1];
                    angular.forEach(vm.procurementConsultantDTOList, function(procurementConsultantDTO, index) {
                        if (procurementConsultantDTO.naturalPersonDTO) {
                            vm.consultantiPFList.push(procurementConsultantDTO);
                        } else {
                            vm.consultantiPJList.push(procurementConsultantDTO);
                        }
                    });

                    vm.editableSubsectionTypeList = responseArray[2];
                    if (vm.isAC || vm.isOpSeap) {
                        vm.warningList = responseArray[3];
                        vm.asociereAvertismenteCuPersoane();
                    }

                    vm.checkIfReadonly();
                    vm.isLoadingData = false;
                }, function(errMsg) {
                    vm.isLoadingData = false;
                    SeapAlertService.toastError("Eroare la aducere date!", "Eroare");
                }
            );
        };

        vm.initData();

        // Adaugare Consultant PF
        vm.saveConsultantPF = function(consultantPF) {
            if (consultantPF) {
                $state.go('form-consultanti-documentatie-save-pf', {proc: vm.acqProcedureId, cons: consultantPF.procurementConsultantId, validationMessage: vm.validationMessage});
            } else {
                $state.go('form-consultanti-documentatie-save-pf', {proc: vm.acqProcedureId});
            }
        };

        // Sterge Consultant PF
        vm.deleteConsultantPF = function(consultantPF) {
            FormConsultantiDocumentatieService.delete(vm.acqProcedureId, consultantPF.procurementConsultantId).then(
                function success(response) {
                    SeapAlertService.toastSuccess('Consultant sters cu succes!', 'Succes');
                    angular.forEach(vm.consultantiPFList, function(consPFItem, index) {
                        if (consultantPF.procurementConsultantId === consPFItem.procurementConsultantId) {
                            vm.consultantiPFList.splice(index, 1);
                            return;
                        }
                    });
                }, function error(errorMsg) {
                    SeapAlertService.toastError('Eroare la stergerea consultantului. Mesaj: ' + errorMsg, 'Eroare');
                }
            );
        };

        // Adaugare Consultant PJ
        vm.saveConsultantPJ = function(consultantPJ) {
            if (consultantPJ) {
                $state.go('form-consultanti-documentatie-save-pj', {proc: vm.acqProcedureId, cons: consultantPJ.procurementConsultantId, validationMessage: vm.validationMessage});
            } else {
                $state.go('form-consultanti-documentatie-save-pj', {proc: vm.acqProcedureId});
            }
        };

        // Sterge Consultant PJ
        vm.deleteConsultantPJ = function(consultantPJ) {
            FormConsultantiDocumentatieService.delete(vm.acqProcedureId, consultantPJ.procurementConsultantId).then(
                function success(response) {
                    SeapAlertService.toastSuccess('Consultant sters cu succes!', 'Succes');
                    angular.forEach(vm.consultantiPJList, function(consPJItem, index) {
                        if (consultantPJ.procurementConsultantId === consPJItem.procurementConsultantId) {
                            vm.consultantiPJList.splice(index, 1);
                            return;
                        }
                    });
                }, function error(errorMsg) {
                    SeapAlertService.toastError('Eroare la stergerea consultantului. Mesaj: ' + errorMsg, 'Eroare');
                }
            );
        };
    }
})();
