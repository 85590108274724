(function () {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .constant('API_BASE_URL', "api")
        .constant('TOAST_DEFAULT_POS', 'toast-top-right')
        .constant('Constants', {
            INTEGRITY_FORM_STATE: {
                // DEFINIRE: 'DEFINIRE',
                DESCHIDERE_OFERTA: 'DESCHIDERE_OFERTA',
                ACTUALIZARE: 'ACTUALIZARE',
                RAPORT_PROCEDURA: 'RAPORT_PROCEDURA',
                ATRIBUIRE: 'ATRIBUIRE'
            },
            INTEGRITY_FORM_SECTION: {
                I_1: 'Sectiune_I_1',
                I_2: 'Sectiune_I_2',
                I_3: 'Sectiune_I_3',
                I_4: 'Sectiune_I_4',
                II: 'Sectiune_II'
            },
            INTEGRITY_FORM_SUBSECTION: {
                Sectiune_I_1__IDENTIFICARE_PROCEDURA_ACHIZITIE: 'Sectiune_I_1__IDENTIFICARE_PROCEDURA_ACHIZITIE',
                Sectiune_I_1__FOND_UE: 'Sectiune_I_1__FOND_UE',
                Sectiune_I_1__DESCHIDERE_OFERTE: 'Sectiune_I_1__DESCHIDERE_OFERTE',
                Sectiune_I_1__RAPORT_FINAL_PROCEDURA: 'Sectiune_I_1__RAPORT_FINAL_PROCEDURA',
                Sectiune_I_1__CONTRACTE: 'Sectiune_I_1__CONTRACTE',
                Sectiune_I_2__LIST__ADD: 'Sectiune_I_2__LIST__ADD',
                Sectiune_I_2__LIST__EDIT: 'Sectiune_I_2__LIST__EDIT',
                Sectiune_I_2__LIST__DELETE: 'Sectiune_I_2__LIST__DELETE',
                Sectiune_I_2__SAVE__IDENTIFICARE: 'Sectiune_I_2__SAVE__IDENTIFICARE',
                Sectiune_I_2__SAVE__ADRESA_DE_DOMICILIU: 'Sectiune_I_2__SAVE__ADRESA_DE_DOMICILIU',
                Sectiune_I_2__SAVE__CATEGORIE_FUNCTIE: 'Sectiune_I_2__SAVE__CATEGORIE_FUNCTIE',
                Sectiune_I_2__SAVE__OFERTANTI_CU_CARE_ARE_RELATIE_DE_MUNCA: 'Sectiune_I_2__SAVE__OFERTANTI_CU_CARE_ARE_RELATIE_DE_MUNCA',
                Sectiune_I_2__SAVE__ASOCIATII_UNDE_ESTE_MEMBRU: 'Sectiune_I_2__SAVE__ASOCIATII_UNDE_ESTE_MEMBRU',
                Sectiune_I_2__SAVE__CONTRACTE_SEMNATE: 'Sectiune_I_2__SAVE__CONTRACTE_SEMNATE',
                Sectiune_I_2__SAVE__SEMNATAR_RAPORT_PROCEDURA: 'Sectiune_I_2__SAVE__SEMNATAR_RAPORT_PROCEDURA',
                Sectiune_I_3__LIST_PF__ADD: 'Sectiune_I_3__LIST_PF__ADD',
                Sectiune_I_3__LIST_PF__EDIT: 'Sectiune_I_3__LIST_PF__EDIT',
                Sectiune_I_3__LIST_PF__DELETE: 'Sectiune_I_3__LIST_PF__DELETE',
                Sectiune_I_3__SAVE_PF__IDENTIFICARE: 'Sectiune_I_3__SAVE_PF__IDENTIFICARE',
                Sectiune_I_3__SAVE_PF__ADRESA_DE_DOMICILIU: 'Sectiune_I_3__SAVE_PF__ADRESA_DE_DOMICILIU',
                Sectiune_I_3__SAVE_PF__ANGAJATORI: 'Sectiune_I_3__SAVE_PF__ANGAJATORI',
                Sectiune_I_3__LIST_PJ__ADD: 'Sectiune_I_3__LIST_PJ__ADD',
                Sectiune_I_3__LIST_PJ__EDIT: 'Sectiune_I_3__LIST_PJ__EDIT',
                Sectiune_I_3__LIST_PJ__DELETE: 'Sectiune_I_3__LIST_PJ__DELETE',
                Sectiune_I_3__SAVE_PJ__IDENTIFICARE: 'Sectiune_I_3__SAVE_PJ__IDENTIFICARE',
                Sectiune_I_3__SAVE_PJ__ADRESA_SEDIU_SOCIAL: 'Sectiune_I_3__SAVE_PJ__ADRESA_SEDIU_SOCIAL',
                Sectiune_I_4__LIST__ADD: 'Sectiune_I_4__LIST__ADD',
                Sectiune_I_4__LIST__EDIT: 'Sectiune_I_4__LIST__EDIT',
                Sectiune_I_4__LIST__DELETE: 'Sectiune_I_4__LIST__DELETE',
                Sectiune_I_4__LIST_MEMBRU__ADD: 'Sectiune_I_4__LIST_MEMBRU__ADD',
                Sectiune_I_4__LIST_MEMBRU__EDIT: 'Sectiune_I_4__LIST_MEMBRU__EDIT',
                Sectiune_I_4__LIST_MEMBRU__DELETE: 'Sectiune_I_4__LIST_MEMBRU__DELETE',
                Sectiune_I_4__SAVE_MEMBRU__IDENTIFICARE: 'Sectiune_I_4__SAVE_MEMBRU__IDENTIFICARE',
                Sectiune_I_4__SAVE_MEMBRU__ADRESA_DE_DOMICILIU: 'Sectiune_I_4__SAVE_MEMBRU__ADRESA_DE_DOMICILIU',
                Sectiune_I_4__SAVE_MEMBRU__CALITATE_IN_COMISIE: 'Sectiune_I_4__SAVE_MEMBRU__CALITATE_IN_COMISIE',
                Sectiune_I_4__SAVE_MEMBRU__CATEGORIE_FUNCTIE: 'Sectiune_I_4__SAVE_MEMBRU__CATEGORIE_FUNCTIE',
                Sectiune_I_4__SAVE_MEMBRU__OFERTANTI_CU_CARE_ARE_RELATIE_DE_MUNCA: 'Sectiune_I_4__SAVE_MEMBRU__OFERTANTI_CU_CARE_ARE_RELATIE_DE_MUNCA',
                Sectiune_I_4__SAVE_MEMBRU__ASOCIATII_UNDE_ESTE_MEMBRU: 'Sectiune_I_4__SAVE_MEMBRU__ASOCIATII_UNDE_ESTE_MEMBRU',
                Sectiune_I_4__SAVE_MEMBRU__CONTRACTE_SEMNATE: 'Sectiune_I_4__SAVE_MEMBRU__CONTRACTE_SEMNATE',
                Sectiune_I_4__LIST_EXPERT__ADD: 'Sectiune_I_4__LIST_EXPERT__ADD',
                Sectiune_I_4__LIST_EXPERT__EDIT: 'Sectiune_I_4__LIST_EXPERT__EDIT',
                Sectiune_I_4__LIST_EXPERT__DELETE: 'Sectiune_I_4__LIST_EXPERT__DELETE',
                Sectiune_I_4__SAVE_EXPERT__IDENTIFICARE: 'Sectiune_I_4__SAVE_EXPERT__IDENTIFICARE',
                Sectiune_I_4__SAVE_EXPERT__ADRESA_DE_DOMICILIU: 'Sectiune_I_4__SAVE_EXPERT__ADRESA_DE_DOMICILIU',
                Sectiune_I_4__SAVE_EXPERT__CALITATE_IN_COMISIE: 'Sectiune_I_4__SAVE_EXPERT__CALITATE_IN_COMISIE',
                Sectiune_I_4__SAVE_EXPERT__CATEGORIE_FUNCTIE: 'Sectiune_I_4__SAVE_EXPERT__CATEGORIE_FUNCTIE',
                Sectiune_I_4__SAVE_MEMBRU__SEMNATAR_RAPORT_PROCEDURA: 'Sectiune_I_4__SAVE_MEMBRU__SEMNATAR_RAPORT_PROCEDURA',
                Sectiune_II__LIST__ADD: 'Sectiune_II__LIST__ADD',
                Sectiune_II__LIST__EDIT: 'Sectiune_II__LIST__EDIT',
                Sectiune_II__LIST__DELETE: 'Sectiune_II__LIST__DELETE',
                Sectiune_II__SAVE__IDENTIFICARE: 'Sectiune_II__SAVE__IDENTIFICARE',
                Sectiune_II__SAVE__ADRESA_SEDIU_SOCIAL: 'Sectiune_II__SAVE__ADRESA_SEDIU_SOCIAL',
                Sectiune_II__SAVE__LOTURI_PENTRU_CARE_A_DEPUS_OFERTELE: 'Sectiune_II__SAVE__LOTURI_PENTRU_CARE_A_DEPUS_OFERTELE',
                Sectiune_II__SAVE__CONTRACTE_SEMNATE: 'Sectiune_II__SAVE__CONTRACTE_SEMNATE',
                Sectiune_II__LIST_MEMBRU__ADD: 'Sectiune_II__LIST_MEMBRU__ADD',
                Sectiune_II__LIST_MEMBRU__EDIT: 'Sectiune_II__LIST_MEMBRU__EDIT',
                Sectiune_II__LIST_MEMBRU__DELETE: 'Sectiune_II__LIST_MEMBRU__DELETE',
                Sectiune_II__SAVE_MEMBRU__IDENTIFICARE: 'Sectiune_II__SAVE_MEMBRU__IDENTIFICARE',
                Sectiune_II__SAVE_MEMBRU__ADRESA_SEDIU_SOCIAL: 'Sectiune_II__SAVE_MEMBRU__ADRESA_SEDIU_SOCIAL',
                Sectiune_II__SAVE_MEMBRU__CALITATE_IN_RELATIA_CU_OFERTANTUL: 'Sectiune_II__SAVE_MEMBRU__CALITATE_IN_RELATIA_CU_OFERTANTUL',
                Sectiune_II__LIST_PARTICIPANT__ADD: 'Sectiune_II__LIST_PARTICIPANT__ADD',
                Sectiune_II__LIST_PARTICIPANT__EDIT: 'Sectiune_II__LIST_PARTICIPANT__EDIT',
                Sectiune_II__LIST_PARTICIPANT__DELETE: 'Sectiune_II__LIST_PARTICIPANT__DELETE',
                Sectiune_II__SAVE_PARTICIPANT__IDENTIFICARE: 'Sectiune_II__SAVE_PARTICIPANT__IDENTIFICARE',
                Sectiune_II__SAVE_PARTICIPANT__ADRESA_SEDIU_SOCIAL: 'Sectiune_II__SAVE_PARTICIPANT__ADRESA_SEDIU_SOCIAL',
                Sectiune_II__SAVE_PARTICIPANT__CALITATE_IN_RELATIA_CU_OFERTANTUL: 'Sectiune_II__SAVE_PARTICIPANT__CALITATE_IN_RELATIA_CU_OFERTANTUL'
            }
        })
    ;
})();
