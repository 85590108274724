/**
 * Created by florin.iacob on 5/16/2017.
 */
(function() {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .factory('FormProceduraAchizitieService', FormProceduraAchizitieService);

    FormProceduraAchizitieService.$inject = ['$http','$q','API_BASE_URL', 'SeapAlertService', 'GenericService'];

    function FormProceduraAchizitieService ($http, $q, API_BASE_URL, SeapAlertService, GenericService) {

        var service = {};

        /**
         * Aducerea unei Proceduri de Achzitie pentru utilizatorul conectat
         */
        service.findById = function(acqProcedureId) {
            var deferred = $q.defer();
            // make server request
            $http({
                method: 'GET',
                url: API_BASE_URL + '/acqProcedure/findById/' + acqProcedureId,
                headers: {
                    'Content-Type': "application/json"
                }
            }).then(
                function success(response) {
                    if (!response.data) {
                        deferred.reject('No data returned!');
                    }
                    deferred.resolve(response.data);

                }, function error(response) {
                    deferred.reject(response.data.message);
                    console.error(' -- REST call Error: ' + API_BASE_URL + '/acqProcedure/findById/' + acqProcedureId);
                }
            );
            return deferred.promise;
        };

        /**
         * Aducerea liste cu cele mai noi proceduri pentru utilizatorul curent
         */
        service.findNewest = function(limit, exceptAcqProcedureId) {
            return GenericService.GetFn('/acqProcedure/findNewest/' + limit + '/' + exceptAcqProcedureId);
        };

        /**
         * Creaza o Procedura de Achizitie
         */
        service.create = function(acqProcedureDTO) {
            var deferred = $q.defer();
            // make server request
            $http({
                method: 'POST',
                url: API_BASE_URL + '/acqProcedure/create',
                headers: {
                    'Content-Type': "application/json"
                },
                data: JSON.stringify(acqProcedureDTO)
            }).then(
                function success(response) {
                    deferred.resolve(response.data);

                }, function error(response) {
                    var errMsg = 'Eroare la salvarea procedurii!';
                    if (response.data && response.data.message) {
                        errMsg = response.data.message;
                    }
                    deferred.reject(errMsg);
                    console.error(' -- REST POST call Error: /acqProcedure/create');
                }
            );
            return deferred.promise;
        };

        /**
         * Actualizeaza o Procedura de Achizitie
         */
        service.update = function(acqProcedureDTO, isModifDeschidereOferte) {
            var deferred = $q.defer();
            // make server request
            $http({
                method: 'PUT',
                url: API_BASE_URL + '/acqProcedure/update' + (isModifDeschidereOferte != null ? '?isModifDeschidereOferte=' + isModifDeschidereOferte : ''),
                headers: {
                    'Content-Type': "application/json"
                },
                data: JSON.stringify(acqProcedureDTO)
            }).then(
                function success(response) {
                    deferred.resolve(response.data);

                }, function error(response) {
                    var errMsg = 'Eroare la salvarea procedurii!';
                    if (response.data && response.data.message) {
                        errMsg = response.data.message;
                    }
                    deferred.reject(errMsg);
                    console.error(' -- REST POST call Error: /acqProcedure/update' + (isModifDeschidereOferte != null ? '?isModifDeschidereOferte=' + isModifDeschidereOferte : ''));
                }
            );
            return deferred.promise;
        };

        /**
         * Valideaza datele procedurii de achzitie
         *
         * @param acqProcedure
         * @returns {boolean}
         */
        service.validateAcqProcedureAndWarn = function(acqProcedure) {
            if (!acqProcedure.nomAcqProcedureTypeDTO.id) {
                SeapAlertService.toastError('Alegeti TIP PROCEDURA!');
                return false;
            }
            if (!acqProcedure.nomContractTypeDTO.id) {
                SeapAlertService.toastError('Alegeti TIP CONTRACT!');
                return false;
            }
            if (!acqProcedure.nomNoticeTypeDTO.id) {
                SeapAlertService.toastError('Alegeti TIP ANUNT!');
                return false;
            }
            if (!acqProcedure.nomSourceFinanceDTO.id) {
                SeapAlertService.toastError('Alegeti SURSA FINANTARE!');
                return false;
            }
            if (!acqProcedure.nomCurrencyDTO.id) {
                SeapAlertService.toastError('Alegeti Valuta pentru VALOARE ESTIMATA TOTALA!');
                return false;
            }
            if (!acqProcedure.nomAcqProcedureStateDTO || !acqProcedure.nomAcqProcedureStateDTO.id) {
                SeapAlertService.toastError('Alegeti STARE PROCEDURA!');
                return false;
            }
            return true;
        };

        /**
         * Aduce lista de rapoarte corespounzatoare Procedurii specificate
         *
         * @param acqProcedureId
         * @returns {*|promise}
         */
        service.getAcqProcedureReportList = function(acqProcedureId) {
            var deferred = $q.defer();
            // make server request
            $http({
                method: 'GET',
                url: API_BASE_URL + '/acqProcedure/getAcqProcedureReportList/' + acqProcedureId,
                headers: {
                    'Content-Type': "application/json"
                }
            }).then(
                function success(response) {
                    if (!response.data) {
                        deferred.reject('No data returned!');
                    }
                    deferred.resolve(response.data);

                }, function error(response) {
                    deferred.reject(response.data.message);
                    console.error(' -- REST call Error: ' + API_BASE_URL + '/acqProcedure/getAcqProcedureReportList/' + acqProcedureId);
                }
            );
            return deferred.promise;
        };

        /**
         * Salveaza lista de rapoarte corespounzatoare Procedurii specificate
         *
         * @param acqProcedureId
         * @returns {*|promise}
         */
        service.saveAcqProcedureReportList = function(acqProcedureId, acqProcedureReportList) {
            var deferred = $q.defer();
            // make server request
            $http({
                method: 'PUT',
                url: API_BASE_URL + '/acqProcedure/saveAcqProcedureReportList/' + acqProcedureId,
                headers: {
                    'Content-Type': "application/json"
                },
                data: JSON.stringify(acqProcedureReportList)
            }).then(
                function success(response) {
                    if (!response.data) {
                        deferred.reject('No data returned!');
                    }
                    deferred.resolve(response.data);

                }, function error(response) {
                    deferred.reject(response.data.message);
                    console.error(' -- REST call Error: ' + API_BASE_URL + '/acqProcedure/saveAcqProcedureReportList/' + acqProcedureId);
                }
            );
            return deferred.promise;
        };

        /**
         * Aduce Lista de Subsectiuni pentru Sectiunea din Procedura specificata
         */
        service.getEditableSubsectionTypeList = function (acqProcedureId, editableSectionType) {
            return GenericService.GetFn('/acqProcedure/getEditableSubsectionTypeList/' + acqProcedureId + '/' + editableSectionType);
        };

        return service;
    }
})();
