(function() {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .controller('FmNavbarController', FmNavbarController);

    FmNavbarController.$inject = ['$state', '$window', 'API_BASE_URL', 'Auth', 'Principal', 'ProfileService', 'LoginService', '$rootScope', 'GenericService'];

    function FmNavbarController ($state, $window, API_BASE_URL, Auth, Principal, ProfileService, LoginService, $rootScope, GenericService) {
        var vm = this;

        vm.isNavbarCollapsed = true;
        vm.isAuthenticated = Principal.isAuthenticated;

        ProfileService.getProfileInfo().then(function(response) {
            vm.inProduction = response.inProduction;
            vm.swaggerEnabled = response.swaggerEnabled;
        });

         Principal.identity().then(
             function (identityResponse){
                 if (identityResponse) {
                     $rootScope.username = identityResponse.firstName + ' ' + identityResponse.lastName;
                 } else {
                     $rootScope.username = 'Utilizator';
                 }
             }
         );

        vm.logout = logout;
        vm.toggleNavbar = toggleNavbar;
        vm.collapseNavbar = collapseNavbar;
        vm.downloadTextBook = downloadTextBook;
        vm.downloadVideoBook = downloadVideoBook;
        vm.$state = $state;

        function logout() {
            collapseNavbar();
            Auth.logout();
            $state.go('home-login');
        }

        function toggleNavbar() {
            vm.isNavbarCollapsed = !vm.isNavbarCollapsed;
        }

        function collapseNavbar() {
            vm.isNavbarCollapsed = true;
        }

        function downloadTextBook() {
            $window.location.href = API_BASE_URL +'/guide/TEXT';
        }

        function downloadVideoBook() {
            $window.location.href = API_BASE_URL +'/guide/VIDEO';
        }

    }
})();
