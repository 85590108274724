(function() {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .controller('PreluareFactoriCtrl', PreluareFactoriCtrl);

    PreluareFactoriCtrl.$inject = ['SeapAlertService', '$q', 'FormFactoriDecizionaliService', '$uibModalInstance', 'FormProceduraAchizitieService', 'acqProcedureId'];

    function PreluareFactoriCtrl(SeapAlertService, $q, FormFactoriDecizionaliService, $uibModalInstance, FormProceduraAchizitieService, acqProcedureId) {
        var vm = this;

        vm.clear = clear;
        vm.preluareFactori = preluareFactori;

        vm.selectedAcqProcedureId = null;

        /******** FILTER OPTIONS *****************/
        vm.pageSelectionArray = [5, 10, 20];
        vm.itemsPerPage = vm.pageSelectionArray[0];

        vm.setItemsPerPage = function(num) {
            vm.itemsPerPage = num;
            vm.initData();
            vm.currentPage = 1; //reset to first page
            vm.pageChanged();
        };
        /******** FILTER OPTIONS - end ***********/

        /**
         * Initializeaza datele controller-ului
         */
        vm.initData = function() {
            vm.isLoadingData = true;
            vm.data = {};
            var serverRequestArray = [
                FormProceduraAchizitieService.findNewest(vm.itemsPerPage, acqProcedureId)
            ];

            $q.all(serverRequestArray).then(
                function success(responseArray) {
                    vm.acqProcedureList = responseArray[0];
                    vm.isLoadingData = false;
                }, function error(errorMsg) {
                    SeapAlertService.toastError('Eroare la aducere lista de proceduri! Mesaj: ' + errorMsg);
                    vm.isLoadingData = false;
                }
            );
        };

        vm.initData();

        /**
         * Salveaza entitate AcqProcedureLot
         */
        function preluareFactori() {

            var serverRequestArray = [];
            if (!vm.selectedAcqProcedureId) {
                SeapAlertService.toastError('Este obligatorie selectia procedurii din care se vor prelua factorii!', 'Atentie');
                return;
            }
            serverRequestArray.push(FormFactoriDecizionaliService.preluareFactori(vm.selectedAcqProcedureId, acqProcedureId));

            vm.isLoadingData = true;
            $q.all(serverRequestArray).then(
                function success(responseArray) {
                    $uibModalInstance.close(responseArray[0]);
                    SeapAlertService.toastSuccess('Preluarea factorilor decizionali s-a terminat cu succes!', 'Succes');
                    vm.isLoadingData = false;
                }, function error(errorMsg) {
                    vm.isLoadingData = false;
                    SeapAlertService.toastError('Eroare la preluarea factorilor decizionali!. Mesaj: ' + errorMsg, 'Eroare');
                }
            );

        }

        /**
         * Close modal
         */
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        // DATE PICKER
        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.dataDeschidereOferte = false;
        vm.openCalendar = function(date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
