/**
 * Created by florin.iacob on 5/16/2017.
 */
(function() {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .controller('FormAngajatoriCtrl', FormAngajatoriCtrl);

    FormAngajatoriCtrl.$inject = ['$q', 'SeapAlertService', '$uibModal', 'ProcConsultantEmployerService', '$state', 'Constants'];

    function FormAngajatoriCtrl($q, SeapAlertService, $uibModal, ProcConsultantEmployerService, $state, Constants) {

        var vm = this;
        /*
            acqProcedure: '=',
            parentId: '=',
            isReadOnly: '=',
            validationMessage: '@'
        */

        vm.INTEGRITY_FORM_SECTION = Constants.INTEGRITY_FORM_SECTION;

        /**
         * Verificare daca formularul este doar in modul de citire date, dara editare
         */
        vm.checkIfReadonly = function() {
            vm.isReadOnlyMode = false;

            if (vm.isReadOnly || vm.acqProcedure.isReadOnly
                || !vm.acqProcedure.editableSectionScheme[vm.INTEGRITY_FORM_SECTION.I_3]) {

                vm.isReadOnlyMode = true;
            }
        };

        vm.initData = function() {
            var serverRequestsArray = [];
            if (vm.parentId) {
                serverRequestsArray.push(ProcConsultantEmployerService.findByProcurementConsultantId(vm.parentId));
            }

            // Aducere date Server
            vm.isLoadingData = true;
            $q.all(serverRequestsArray).then(
                function success(responseArray) {

                    if (vm.parentId) {
                        vm.procConsultantEmployerList = responseArray[0];
                    } else {
                        vm.procConsultantEmployerList = [];
                    }

                    vm.checkIfReadonly();
                    vm.isLoadingData = false;
                },
                function error(error) {
                    SeapAlertService.toastError('Eroare la aducere date despre Angajati!');
                    vm.isLoadingData = false;
                }
            );
        };

        vm.initData();

        vm.legalPersonDTO = {
            cif: null,
            personName: null
        };

        /**
         * Adauga angajator
         * @param angajator
         */
        vm.addAngajator = function(legalPersonDTO) {
            if (!legalPersonDTO.cif || !legalPersonDTO.personName) {
                SeapAlertService.toastError('Completati CIF si DENUMIRE angajator!');
                return;
            }
            var isAlredyPresent = false;
            angular.forEach(vm.procConsultantEmployerList, function(procConsultantEmployerItem, index) {
                if (procConsultantEmployerItem.legalPersonDTO.cif === legalPersonDTO.cif) {
                    isAlredyPresent = true;
                    return;
                }
            });

            if (isAlredyPresent) {
                SeapAlertService.toastError('Angajator cu acelasi CIF deja adaugat!');
            } else {
                vm.procConsultantEmployerList.push({
                    legalPersonDTO: vm.legalPersonDTO
                });
                vm.legalPersonDTO = {
                    cif: null,
                    personName: null
                };
            }
        };

        /**
         * Sterge angajator
         * @param angajator
         */
        vm.deleteAngajator = function(procConsultantEmployer) {
            angular.forEach(vm.procConsultantEmployerList, function(procConsultantEmployerItem, index) {
                if (procConsultantEmployer.procConsultantEmployerId) {
                    if (procConsultantEmployer.procConsultantEmployerId === procConsultantEmployerItem.procConsultantEmployerId) {
                        vm.procConsultantEmployerList[index].isDeleted = true;
                        return;
                    }
                } else {
                    if (procConsultantEmployer.legalPersonDTO.cif === procConsultantEmployerItem.legalPersonDTO.cif) {
                        vm.procConsultantEmployerList.splice(index, 1);
                        return;
                    }
                }
            });
        };

        // Editable
        vm.isAngajatoriFormEditable = false;
        vm.toggleAngajatoriFormEdit = function() {
            vm.isAngajatoriFormEditable = !vm.isAngajatoriFormEditable;
        };

        /**
         * Salveaza informatiile depre Angajator
         */
        vm.saveAngajatoriForm = function() {
            var serverRequestArray = [];
            serverRequestArray.push(ProcConsultantEmployerService.save(vm.parentId, vm.procConsultantEmployerList));

            vm.isLoadingData = true;
            $q.all(serverRequestArray).then(
                function success(responseArray) {
                    vm.procConsultantEmployerList = responseArray[0];
                    vm.isAngajatoriFormEditable = false;
                    vm.isLoadingData = false;
                    SeapAlertService.toastSuccess("Lista de angajatori salvata cu succes!", "Succes");

                }, function error(errMsg) {
                    vm.isLoadingData = false;
                    SeapAlertService.toastError("Eroare la salvare lista de angajatori! Mesaj: " + errMsg, "Eroare");
                }
            );
        };

        /**
         * Functie de redirectare catre pagina de CREATE/UPDATE angajator
         *
         * @param idEmployer
         */
        vm.saveEmployer = function(employer) {
            if (employer) {
                $state.go('form-consultanti-documentatie-save-angajator',
                    {
                        proc: vm.acqProcedure.acqProcedureId,
                        cons: vm.parentId,
                        employer: employer.procConsultantEmployerId,
                        validationMessage: vm.validationMessage
                    });
            } else {
                $state.go('form-consultanti-documentatie-save-angajator',
                    {
                        proc: vm.acqProcedure.acqProcedureId,
                        cons: vm.parentId
                    });
            }
        };

        /**
         * Sterge Angajator din lista
         *
         * @param employer
         */
        vm.deleteEmployer = function(employer) {
            ProcConsultantEmployerService.delete(vm.acqProcedure.acqProcedureId, employer.procConsultantEmployerId).then(
                function success(response) {
                    SeapAlertService.toastSuccess('Angajator sters cu succes!', 'Succes');
                    angular.forEach(vm.procConsultantEmployerList, function(employerPJItem, index) {
                        if (employer.procConsultantEmployerId === employerPJItem.procConsultantEmployerId) {
                            vm.procConsultantEmployerList.splice(index, 1);
                            return;
                        }
                    });
                }, function error(errorMsg) {
                    SeapAlertService.toastError('Eroare la stergerea consultantului. Mesaj: ' + errorMsg, 'Eroare');
                }
            );
        };
    }
})();
