/**
 * Sursa de inspiratie:  http://blog.andreasgustafsson.se/javascript/angular-confirm-modal-angular-ui/
 */
(function () {
'use strict';

    angular.module('seapAniFormsApp').directive('ngConfirm', ['$uibModal', function ($uibModal) {
        return {
            restrict: 'A',
            scope: {
                ngConfirmMessage: '@',
                ngConfirmSize: '@',
                ngConfirm: '&'
            },
            link: function (scope, element) {
                element.bind('click', function () {
                    var modalInstance = $uibModal.open({
                        controller: 'NgConfirmCtrl',
                        size: scope.ngConfirmSize ? scope.ngConfirmSize : 'sm',
                        windowClass: 'confirm-window',
                        resolve: {
                            confirmClick: function () {
                                return scope.ngConfirm;
                            },
                            confirmMessage: function () {
                                return scope.ngConfirmMessage;
                            }
                        },
                        template:
                            '<br/>' +
                            '<div class="modal-body">' +
                            '   <b><p ng-bind-html="confirmMessage"></p></b>' +
                            '</div>' +
                            '<div class="modal-footer">' +
                            '   <button class="btn btn-primary dl-btn dl-btn-primary" ng-click="ok()">' +
                            '       &nbsp;&nbsp;<i class="fa fa-check"></i>&nbsp;OK&nbsp;&nbsp;' +
                            '   </button>' +
                            '   <button class="btn btn-danger dl-btn dl-btn-danger" ng-click="cancel()">ANULEAZA</button>' +
                            '</div>'
                    });
                });
            }
        }
    }]);

})();



