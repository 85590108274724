/**
 * Created by florin.iacob on 6/8/2017.
 */
(function() {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .factory('EvalCommitteExpertService', EvalCommitteExpertService);

    EvalCommitteExpertService.$inject = ['$http','$q','API_BASE_URL'];

    function EvalCommitteExpertService ($http, $q, API_BASE_URL) {

        var service = {};

        /**
         * Aduce un Expert comisie pe baza ID-ului specificat
         */
        service.findById = function(evalCommitteeExpertId) {
            var deferred = $q.defer();
            // make server request
            $http({
                method: 'GET',
                url: API_BASE_URL + '/evalCommitteeExpert/findById/' + evalCommitteeExpertId,
                headers: {
                    'Content-Type': "application/json"
                }
            }).then(
                function success(response) {
                    if (!response.data) {
                        deferred.reject('No data returned!');
                    }
                    deferred.resolve(response.data);

                }, function error(response) {
                    deferred.reject(response.data.message);
                    console.error(' -- REST call Error: ' + API_BASE_URL + '/evalCommitteeExpert/findById/' + evalCommitteeExpertId);
                }
            );
            return deferred.promise;
        };

        /**
         * Creaza un Expert comisie pentru o Procedura de Achizitie
         */
        service.create = function(acqProcedureId, evalCommitteeId, evalCommitteeExpertDTO) {
            var deferred = $q.defer();
            // make server request
            $http({
                method: 'POST',
                url: API_BASE_URL + '/evalCommitteeExpert/create/' + acqProcedureId + '/' + evalCommitteeId,
                headers: {
                    'Content-Type': "application/json"
                },
                data: JSON.stringify(evalCommitteeExpertDTO)
            }).then(
                function success(response) {
                    deferred.resolve(response.data);

                }, function error(response) {
                    var errMsg = 'Eroare la salvarea Expertului din comisie!';
                    if (response.data && response.data.message) {
                        errMsg = response.data.message;
                    }
                    deferred.reject(errMsg);
                    console.error(' -- REST POST call Error: /evalCommitteeExpert/create/' + acqProcedureId + '/' + evalCommitteeId);
                }
            );
            return deferred.promise;
        };

        /**
         * Actualizeaza un Factor Decizional pentru o Procedura de Achizitie
         */
        service.update = function(acqProcedureId, evalCommitteeExpertDTO) {
            var deferred = $q.defer();
            // make server request
            $http({
                method: 'PUT',
                url: API_BASE_URL + '/evalCommitteeExpert/update/' + acqProcedureId,
                headers: {
                    'Content-Type': "application/json"
                },
                data: JSON.stringify(evalCommitteeExpertDTO)
            }).then(
                function success(response) {
                    deferred.resolve(response.data);

                }, function error(response) {
                    var errMsg = 'Eroare la salvarea Expertului din comisie!';
                    if (response.data && response.data.message) {
                        errMsg = response.data.message;
                    }
                    deferred.reject(errMsg);
                    console.error(' -- REST POST call Error: /evalCommitteeExpert/update/' + acqProcedureId);
                }
            );
            return deferred.promise;
        };

        /**
         * Sterge un Factor decizional pentru o Procedura de Achizitie
         */
        service.delete = function(acqProcedureId, evalCommitteeExpertId) {
            var deferred = $q.defer();
            // make server request
            $http({
                method: 'DELETE',
                url: API_BASE_URL + '/evalCommitteeExpert/delete/' + acqProcedureId + "/" + evalCommitteeExpertId,
                headers: {
                    'Content-Type': "application/json"
                }
            }).then(
                function success(response) {
                    deferred.resolve(response.data);

                }, function error(response) {
                    var errMsg = 'Eroare la stergerea Expertului din comisie!';
                    if (response.data && response.data.message) {
                        errMsg = response.data.message;
                    }
                    deferred.reject(errMsg);
                    console.error(' -- REST POST call Error: /evalCommitteeExpert/delete/' + acqProcedureId + "/" + evalCommitteeExpertId);
                }
            );
            return deferred.promise;
        };

        return service;
    }
})();
