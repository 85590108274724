/**
 * Created by florin.iacob on 5/16/2017.
 */
(function() {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .controller('AutoritateContractantaViewCtrl', AutoritateContractantaViewCtrl);

    AutoritateContractantaViewCtrl.$inject = ['$state', '$q', 'SeapAlertService', 'AutoritateContractantaViewService', 'AddressService', '$location'];

    function AutoritateContractantaViewCtrl($state, $q, SeapAlertService, AutoritateContractantaViewService, AddressService, $location) {

        var vm = this;
        vm.contractAuth = {};
        /*
            acqProcedureId: '='
        */

        var serverRequest = [];
        if (vm.acqProcedureId) {
            serverRequest.push(AutoritateContractantaViewService.findByAcqProcedureId(vm.acqProcedureId));
        } else {
            serverRequest.push(AutoritateContractantaViewService.findByCurrentUser());
        }

        $q.all(serverRequest).then(
            function success(contractAuthRespArray) {
                vm.contractAuth = contractAuthRespArray[0];

                var legalPersonAddressArray = vm.contractAuth.legalPersonDTO.personDTO.addressDTOs;
                if (legalPersonAddressArray) {
                    vm.contractAuth.legalPersonDTO.addressString = AddressService.addressToString(legalPersonAddressArray[0]);
                }
                var naturalPersonAddressArray = vm.contractAuth.naturalPersonDTO.personDTO.addressDTOs;
                if (naturalPersonAddressArray) {
                    vm.contractAuth.naturalPersonDTO.addressString = AddressService.addressToString(naturalPersonAddressArray[0]);
                }
            }, function error(errMsg) {
                SeapAlertService.toastError('Eroare la preluarea Autoritatii Contractante. Mesaj: ' + errMsg, 'Eroare');
            }
        );

        /**
         * Actualizare informatii despre Autoritate Contractanta
         */
        vm.updateContractAuth = function() {
            var url = $location.url();
            $state.go('form-autoritate-contractanta', {state: url});
        };
    }
})();
