/**
 * Created by florin.iacob on 5/16/2017.
 */
(function() {
    'use strict';

    // eslint-disable-next-line angular/controller-name
    angular
        .module('seapAniFormsApp')
        .controller('IntegrityFormListareCtrl', IntegrityFormListareCtrl);

    IntegrityFormListareCtrl.$inject = ['$state', '$q', 'SeapAlertService', '$rootScope', 'IntegrityFormService', 'Principal', 'FiltreCautareProceduriService', 'PublicationValidationService', 'SicapSyncService', 'InitService', 'AutoritateContractantaViewService'];

    function IntegrityFormListareCtrl($state, $q, SeapAlertService, $rootScope, IntegrityFormService, Principal, FiltreCautareProceduriService, PublicationValidationService, SicapSyncService, InitService, AutoritateContractantaViewService) {

        var vm = this;
        vm.isAC = Principal.hasAnyAuthority(['ROLE_AC']);
        vm.isOpAnapForm = Principal.hasAnyAuthority(['ROLE_OP_ANAP_FI_FORM']);
        vm.isOpAnapWarning = Principal.hasAnyAuthority(['ROLE_OP_ANAP_FI_WARNING']);

        /******** FILTER OPTIONS *****************/
        vm.pageSelectionArray = [5, 10, 20, 50];
        vm.itemsPerPage = FiltreCautareProceduriService.getItemsPerPage();
        if (!vm.itemsPerPage) {
            vm.itemsPerPage = 20;
            FiltreCautareProceduriService.setItemsPerPage(vm.itemsPerPage);
        }
        vm.currentPage = FiltreCautareProceduriService.getCurrentPage();
        vm.maxSize = 5; //Number of pager buttons to show

        vm.setPage = function (pageNo) {
            vm.currentPage = pageNo;
        };

        vm.pageChanged = function() {
            FiltreCautareProceduriService.setCurrentPage(vm.currentPage);
            vm.initData();
            // eslint-disable-next-line angular/log,no-console
            console.log('Page changed to: ' + vm.currentPage);
        };

        vm.setItemsPerPage = function(num) {
            vm.itemsPerPage = num;
            FiltreCautareProceduriService.setItemsPerPage(vm.itemsPerPage);
            vm.currentPage = 1; //reset to first page
            vm.pageChanged();
        };
        /******** FILTER OPTIONS - end ***********/

        PublicationValidationService.unsetValidationTrigger();

        vm.filterByUnpublishedAcqProcedures = function () {
            FiltreCautareProceduriService.setFilterByUnpublishedAcqProceduresActivated(true);
            vm.filtersOpen = true;
            return vm.onApplyFilter(FiltreCautareProceduriService.getFilterDTO());
        };

        vm.filterByIntegrityWarningsReceived = function () {
            FiltreCautareProceduriService.setFilterByIntegrityWarningsReceivedActivated(true);
            vm.filtersOpen = true;
            return vm.onApplyFilter(FiltreCautareProceduriService.getFilterDTO());
        };

        vm.filterByUnpublishedMeasureTaken = function () {
            FiltreCautareProceduriService.setFilterByUnpublishedMeasureTakenActivated(true);
            vm.filtersOpen = true;
            return vm.onApplyFilter(FiltreCautareProceduriService.getFilterDTO());
        };

        vm.acqProcedureStateForList = null;

        /**
         * Functie apelata atunci cand se doreste aplicarea filtrelor setate
         *
         * @param filterDTO
         * @returns {*|promise}
         */
        vm.onApplyFilter = function(filterDTO) {
            var deferred = $q.defer();

            var serverRequestArray = [];
            vm.currentPage = 1;
            FiltreCautareProceduriService.setCurrentPage(vm.currentPage);
            serverRequestArray.push(IntegrityFormService.findAllByFilter(filterDTO, vm.currentPage, vm.itemsPerPage));

            vm.isLoadingData = true;
            $q.all(serverRequestArray).then(
                function success(responseArray) {
                    vm.integrityFormList = responseArray[0];
                    vm.totalItems = vm.integrityFormList.totalRecords;
                    vm.acqProcedureStateForList = filterDTO.nomAcqProcedureStateDTO.name ? filterDTO.nomAcqProcedureStateDTO.name : null;
                    vm.isLoadingData = false;
                    deferred.resolve(responseArray[0]);
                },
                function error(errMsg) {
                    vm.isLoadingData = false;
                    deferred.reject(errMsg);
                }
            );
            return deferred.promise;
        };

        /**
         * Initialize Controller Data
         */
        vm.initData = function() {
            vm.integrityFormList = null;
            vm.isLoadingData = true;

            var serverRequest = [];

            var filterDTOValue;
            if (FiltreCautareProceduriService.isFilterApplicable()) {
                vm.filtersOpen = true;
                filterDTOValue = FiltreCautareProceduriService.getFilterDTO();
                serverRequest.push(IntegrityFormService.findAllByFilter(filterDTOValue, vm.currentPage, vm.itemsPerPage));
            } else {
                vm.filtersOpen = false;
                filterDTOValue = FiltreCautareProceduriService.getFilterDTO();
                if (!filterDTOValue) {
                    filterDTOValue = FiltreCautareProceduriService.initFilterDTO();
                }
                serverRequest.push(IntegrityFormService.findAllByFilter(filterDTOValue, vm.currentPage, vm.itemsPerPage));
            }
            vm.acqProcedureStateForList = filterDTOValue.nomAcqProcedureStateDTO.name ? filterDTOValue.nomAcqProcedureStateDTO.name : null;

            serverRequest.push(IntegrityFormService.getDashboardCounters());
            serverRequest.push(AutoritateContractantaViewService.findByCurrentUser());

            $q.all(serverRequest).then(
                function success(responseArray) {
                    vm.integrityFormList = responseArray[0];
                    vm.totalItems = vm.integrityFormList.totalRecords;
                    vm.dashboardCounters = responseArray[1];
                    vm.contractAuth = responseArray[2];
                    vm.isLoadingData = false;
                },
                function error(errorMsg) {
                    SeapAlertService.toastError(errorMsg);
                    vm.isLoadingData = false;
                }
            );
        };

        vm.initData();


        /**
         * Adauga/modifica formular de integritate
         * @param integrityForm
         */
        vm.saveIntegrityForm = function(integrityForm) {
            if (integrityForm) {
                $state.go('integrity-form-save', {form: integrityForm.integrityFormId});
            } else {
                $state.go('integrity-form-save');
            }
        };

        vm.syncSeapAcqProcedures = function() {
            IntegrityFormService.syncSeapAcqProcedures().then(
                function success() {
                    $state.reload();
                    vm.isLoadingData = false;
                },
                function error(errorMsg) {
                    SeapAlertService.toastError(errorMsg);
                    vm.isLoadingData = false;
                }
            );
        };


        vm.openIntegrityWarnings = function(integrityFormId) {
            $state.go('integrity-warning-listare', {form: integrityFormId, list: true});
        };

        vm.openIntegrityFormVersions = function(integrityFormId, acqProcedureId) {
            $state.go('integrity-form-version-listare', {form: integrityFormId, proc: acqProcedureId});
        };

        vm.findAllForUser = function () {
            vm.isLoadingData = true;
            IntegrityFormService.findAllForUser().then(
                function success(response) {
                    vm.integrityFormList = response;
                    vm.isLoadingData = false;
                },
                function error(errorMsg) {
                    SeapAlertService.toastError(errorMsg);
                    vm.isLoadingData = false;
                }
            );
        };


        /**
         * Stergerea unui formular de integritate
         */
        vm.deleteIntegrityForm = function(integrityForm) {
            IntegrityFormService.delete(integrityForm.integrityFormId).then(
                function success(response) {
                    SeapAlertService.toastSuccess('Formular de integritate sters cu succes!', 'Succes');
                    angular.forEach(vm.integrityFormList.integrityFormDTOs, function(integrityFormItem, index) {
                        if (integrityFormItem.integrityFormId === integrityForm.integrityFormId) {
                            vm.integrityFormList.integrityFormDTOs.splice(index, 1);
                            return;
                        }
                    });
                    vm.totalItems--;
                    vm.integrityFormList.totalRecords--;
                    if (vm.totalItems % vm.itemsPerPage === 0) {
                        vm.currentPage--;
                        vm.initData();
                    }
                },
                function error(errorMsg) {
                    SeapAlertService.toastError('Eroare la stergerea Formularului de integritate. Mesaj: ' + errorMsg, 'Eroare');
                }
            );
        };

        // Sincronizare lista formulare din SICAP
        vm.syncIntegrityFormListFromSicap = function() {
            vm.isLoadingData = true;
            SicapSyncService.syncIntegrityFormList().then(
                function success(response) {
                    vm.initData();
                    SeapAlertService.toastSuccess("Sincronizarea procedurilor din aplicatia Noul SEAP s-a efectuat cu succes!");
                    vm.isLoadingData = false;
                },
                function error(errorMsg) {
                    SeapAlertService.toastError(errorMsg);
                    vm.isLoadingData = false;
                }
            );
        };

        if (!$rootScope.initialWarnMessagePrinted) {
            InitService.getWarningMessage().then(
                function success(responseMessage) {
                    if (responseMessage && responseMessage.message) {
                        SeapAlertService.toastWarning(
                            responseMessage.message,
                            'Informare!',
                            'bottom-right');
                    }
                    $rootScope.initialWarnMessagePrinted = true;
                },
                function error(errorMsg) {
                    // eslint-disable-next-line angular/log,no-console
                    console.error(" Cannot get initial warning message, err: " + errorMsg);
                }
            );
        }
    }
})();
