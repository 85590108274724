(function() {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('home-login', {
            parent: 'app',
            url: '/?error',
            data: {
                authorities: [],
                pageTitle: 'FORMULAR INTEGRITATE'
            },
            views: {
                'content@': {
                    templateUrl: 'app/_seap/layouts/home-login/home-login.html',
                    controller: 'HomeLoginController',
                    controllerAs: 'vm'
                }
            },
            params: {
                error: {
                    value: '',
                    squash: true
                }
            },
            resolve: {
                urlParams: ['$stateParams', function ($stateParams) {
                    return {
                        errorMessage: $stateParams.error
                    };
                }]
            }
        });
    }
})();
