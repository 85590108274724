(function() {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .controller('HomeLoginController', HomeLoginController);

    HomeLoginController.$inject = ['$scope', 'Principal', 'LoginService', '$state', 'Auth', '$rootScope', 'urlParams', 'SeapAlertService'];

    function HomeLoginController($scope, Principal, LoginService, $state, Auth, $rootScope, urlParams, SeapAlertService) {
        var vm = this;

        vm.account = null;
        vm.isAuthenticated = null;
        vm.loginInProgress = false;
        vm.login = login;
        vm.register = register;

        if (urlParams.errorMessage) {
            vm.errorMessage = urlParams.errorMessage;
            vm.authenticationError = true;
        }

        $scope.$on('authenticationSuccess', function() {
            getAccount();
        });

        getAccount();

        function getAccount() {
            Principal.identity().then(function(account) {
                vm.account = account;
                vm.isAuthenticated = Principal.isAuthenticated;
                if (vm.isAuthenticated() && !urlParams.errorMessage) {
                    var isAdmin = false;
                    if (vm.account.authorities) {
                        for (var i = 0; i < vm.account.authorities.length; i++) {
                            if (vm.account.authorities[i] === 'ROLE_ADMIN') {
                                isAdmin = true;
                                break;
                            }
                        }
                    }
                    if (!isAdmin) {
                        $state.go('integrity-form-listare');
                    } else {
                        $state.go('audits');
                    }
                }
                if (account) {
                    $rootScope.username = account.firstName + ' ' + account.lastName;
                }
            });
        }
        function register () {
            $state.go('register');
        }

        /**
         * login attempt function
         *
         * @param event
         */
        function login (event) {
            event.preventDefault();
            vm.loginInProgress = true;
            Auth.login({
                username: vm.username,
                password: vm.password,
                rememberMe: vm.rememberMe
            }).then(function () {
                vm.authenticationError = false;
                vm.loginInProgress = false;

                if ($state.current.name === 'register' || $state.current.name === 'activate' ||
                    $state.current.name === 'finishReset' || $state.current.name === 'requestReset') {
                    $state.go('home-login');
                }

                $rootScope.$broadcast('authenticationSuccess');

                var hasLoadState = false;
                // previousState was set in the authExpiredInterceptor before being redirected to login modal.
                // since login is successful, go to stored previousState and clear previousState
                if (Auth.getPreviousState()) {
                    var previousState = Auth.getPreviousState();
                    Auth.resetPreviousState();
                    $state.go(previousState.name, previousState.params);
                    hasLoadState = true;
                }

                if (!hasLoadState) {
                    $state.go('integrity-form-listare');
                }

            }).catch(function (response) {
                vm.errorMessage = 'Credentiale invalide';
                vm.authenticationError = true;
                vm.loginInProgress = false;
                if (response && response.data && response.data.AuthenticationException) {
                    if (response.data.AuthenticationException.toLowerCase().indexOf("bad credentials") < 0) {
                        SeapAlertService.displayModalAlert(response.data.AuthenticationException, "Atentie!");
                    }
                }
            });
        }
    }
})();
