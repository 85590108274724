/**
 * Loading icon
 */
(function () {
'use strict';

    angular.module('seapAniFormsApp')
        .directive('loadingSpinner', ['$state', function ($state) {

            return {
                restrict: 'EA',
                scope: {
                    spinnerWidth: '@'
                },
                template:
                // -------------------------------------------- TEMPLATE START -----------------------------------------
                '<div class="text-center" style="width:100%;">' +
                '   <i class="fa fa-spinner fa-spin text-warning" ' +
                '       style="font-size: {{spinnerWidth ? spinnerWidth : \'120px\'}};"></i></div>' +
                '</div>'
            }
    }]);

})();



