/**
 * Created by florin.iacob on 5/16/2017.
 */
(function () {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .factory('EuFundService', EuFundService);

    EuFundService.$inject = ['$http', '$q', 'API_BASE_URL', 'GenericService'];

    function EuFundService($http, $q, API_BASE_URL, GenericService) {

        var service = {};

        /**
         * Aducerea listei de valori pentru nomenclatorul - NomEuProgram
         */
        service.getNomEuProgramList = function () {
            return GenericService.GetFn('/euFund/getNomEuProgramList');
        };

        /**
         * Aducerea listei de valori pentru nomenclatorul - NomEuAxe
         */
        service.getNomEuAxeList = function () {
            return GenericService.GetFn('/euFund/getNomEuAxeList');
        };

        return service;
    }
})();
