/**
 * Created by florin.iacob on 5/22/2017.
 */
(function() {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .factory('ContractTendererService', ContractTendererService);

    ContractTendererService.$inject = ['$q', 'GenericService', 'API_BASE_URL'];

    function ContractTendererService($q, GenericService, API_BASE_URL) {

        var Service = {};

        /**
         * Aducerea listei de Contracte pentru Ofertantul specificat
         */
        Service.findByTendererId = function(tendererID) {
            return GenericService.GetFn('/contractTenderer/findByTendererId/' + tendererID);
        };

        /**
         * Actualizeaza lista de Contracte pentru Ofertantul specificat
         */
        Service.save = function(tendererID, contractTendererDTOList) {
            return GenericService.PutFn('/contractTenderer/save/' + tendererID, contractTendererDTOList);
        };

        return Service
    }
})();

