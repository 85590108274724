/**
 * Created by florin.iacob on 5/16/2017.
 */
(function() {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('integrity-warning-listare', {
                parent: 'seap',
                url: '/integrity-warning-listare?form&list',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'AVERTISMENTE SI MASURI'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/_seap/entities/integrity-warning/listare/integrity-warning-listare.html',
                        controller: 'IntegrityWarningListareCtrl',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    form: {
                        value: '',
                        squash: true
                    },
                    list: {
                        value: '',
                        squash: true
                    }
                },
                resolve: {
                    urlParams: ['$stateParams', function ($stateParams) {
                        return {
                            integrityFormId: $stateParams.form,
                            fromList: $stateParams.list
                        };
                    }]
                }
            })
    }

})();
