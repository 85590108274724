/**
 * Created by florin.iacob on 5/22/2017.
 */
(function() {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .factory('SicapSyncService', SicapSyncService);

    SicapSyncService.$inject = ['$q', '$http', '$uibModal', 'GenericService', 'API_BASE_URL', 'SeapAlertService'];

    function SicapSyncService($q, $http, $uibModal, GenericService, API_BASE_URL, SeapAlertService) {

        var Service = {};

        /**
         * Sincronizarea autoritatii contractante din SICAP
         */
        Service.syncContractAuthority = function () {
            return GenericService.PostFn('/syncFromSicap/syncContractAuthority');
        };

        /**
         * Sincronizarea listei de formulare cu aplicatia SICAP pentru utilizator
         */
        Service.syncIntegrityFormList = function () {
            return GenericService.PostFn('/syncFromSicap/syncIntegrityFormList');
        };

        /**
         * Sincronizarea din aplicatia SICAP a datelor pentru procedura specificata prin ID; sunt sicronizate datele de identificare pentru proc. din sect I.1
         *
         * @param acqProcedureId
         */
        Service.syncAcqProcedure = function (acqProcedureId) {
            return GenericService.PostFn('/syncFromSicap/syncAcqProcedure/' + acqProcedureId);
        };

        /**
         * Sincronizarea din aplicatia SICAP a tuturor datelor pentru procedura specificata prin ID; se vor sincroniza toate sectiunile care se pot prelua din SICAP
         *
         * @param acqProcedureId
         */
        Service.syncAllSectionsForAcqProcedure = function (acqProcedureId) {
            return GenericService.PostFn('/syncFromSicap/syncAllSectionsForAcqProcedure/' + acqProcedureId);
        };

        /**
         * Sincronizarea din aplicatia SICAP a loturilor pentru procedura specificata prin ID
         *
         * @param acqProcedureId
         */
        Service.syncAcqProcedureLotList = function (acqProcedureId) {
            return GenericService.PostFn('/syncFromSicap/syncAcqProcedureLotList/' + acqProcedureId);
        };

        /**
         * Sincronizarea din aplicatia SICAP a datelor pentru subsectiunea Raport Final
         *
         * @param acqProcedureId
         */
        Service.syncAcqProcedureReport = function (acqProcedureId) {
            return GenericService.PostFn('/syncFromSicap/syncAcqProcedureReport/' + acqProcedureId);
        };

        /**
         * Sincronizarea din aplicatia SICAP a Contractelor pentru procedura specificata prin ID;
         *
         * @param acqProcedureId
         */
        Service.syncContractList = function (acqProcedureId) {
            return GenericService.PostFn('/syncFromSicap/syncContractList/' + acqProcedureId);
        };

        /**
         * Sincronizarea din aplicatia SICAP a Listei de factori decizionali pentru procedura cu ID-ul specificat;
         *
         * @param acqProcedureId
         */
        Service.syncDecisionMakerList = function (acqProcedureId) {
            return GenericService.PostFn('/syncFromSicap/syncDecisionMakerList/' + acqProcedureId);
        };

        /**
         * Sincronizarea din aplicatia SICAP a listei de ofertanti pentru procedura cu ID-ul specificat;
         *
         * @param acqProcedureId
         */
        Service.syncTendererList = function (acqProcedureId) {
            return GenericService.PostFn('/syncFromSicap/syncTendererList/' + acqProcedureId);
        };

        /**
         * Sincronizarea din aplicatia SICAP a listei de membri pentru ofertant, in functie de ID-ul procedurii si ID-ul ofertantului specificat;
         *
         * @param acqProcedureId
         * @param tendererId
         */
        Service.syncTendererParticipantList = function (acqProcedureId, tendererId) {
            return GenericService.PostFn('/syncFromSicap/syncTendererParticipantList/' + acqProcedureId + '/' + tendererId);
        };

        /**
         * Sincronizarea din aplicatia SICAP a listei de participanti alaturi de ofertant, in functie de ID-ul procedurii si ID-ul ofertantului specificat;
         *
         * @param acqProcedureId
         * @param tendererId
         */
        Service.syncTendererAssocMemberList = function (acqProcedureId, tendererId) {
            return GenericService.PostFn('/syncFromSicap/syncTendererAssocMemberList/' + acqProcedureId + '/' + tendererId);
        };

        /**
         * Sincronizarea din aplicatia SICAP a listei de contracte atribuite ofertantului, in functie de ID-ul procedurii si ID-ul ofertantului specificat;
         *
         * @param acqProcedureId
         * @param tendererId
         */
        Service.syncContractTendererList = function (acqProcedureId, tendererId) {
            return GenericService.PostFn('/syncFromSicap/syncContractTendererList/' + acqProcedureId + '/' + tendererId);
        };

        return Service
    }
})();

