
(function () {
'use strict';

    angular.module('seapAniFormsApp').directive('fiRequiredText', ['$uibModal', function($uibModal) {
        return {
            restrict: 'AE',
            link: function(scope, elem, attrs, ctrl) {
                elem[0].oninvalid = function (e) {
                    e.target.setCustomValidity('Camp obligatoriu!');
                };
                elem[0].oninput = function (e) {
                    e.target.setCustomValidity('');
                };
            }
        }
    }])

})();



