/**
 * Created by florin.iacob on 5/16/2017.
 */
(function() {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .component('autoritateContractantaView', {
            templateUrl: 'app/_seap/components/autoritate-contractanta-view/autoritate-contractanta-view.html',
            controller: 'AutoritateContractantaViewCtrl',
            controllerAs: 'vm',
            bindings: {
                acqProcedureId: '='
            }
        });
})();
