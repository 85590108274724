/**
 * Created by florin.iacob on 6/8/2017.
 */
/**
 * Created by florin.iacob on 5/16/2017.
 */
(function () {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .factory('AcqProcedureLotService', AcqProcedureLotService);

    AcqProcedureLotService.$inject = ['$http', '$q', 'API_BASE_URL'];

    function AcqProcedureLotService($http, $q, API_BASE_URL) {

        var service = {};

        /**
         * Aducerea listei de Loturi pentru Procedura de Achzitie specificata
         */
        service.findByAcqProcedureId = function (acqProcedureId) {
            var deferred = $q.defer();
            // make server request
            $http({
                method: 'GET',
                url: API_BASE_URL + '/acqProcedureLot/findByAcqProcedureId/' + acqProcedureId,
                headers: {
                    'Content-Type': "application/json"
                }
            }).then(
                function success(response) {
                    if (!response.data) {
                        deferred.reject('No data returned!');
                    }
                    deferred.resolve(response.data);

                }, function error(response) {
                    deferred.reject(response.data.message);
                    console.error(' -- REST call Error: ' + API_BASE_URL + '/acqProcedureLot/findByAcqProcedureId/' + acqProcedureId);
                }
            );
            return deferred.promise;
        };

        /**
         * Creaza un Lot pentru o Procedura de Achizitie
         */
        service.create = function (acqProcedureId, acqProcedureLotDTO) {
            var deferred = $q.defer();
            // make server request
            $http({
                method: 'POST',
                url: API_BASE_URL + '/acqProcedureLot/create/' + acqProcedureId,
                headers: {
                    'Content-Type': "application/json"
                },
                data: JSON.stringify(acqProcedureLotDTO)
            }).then(
                function success(response) {
                    deferred.resolve(response.data);

                }, function error(response) {
                    var errMsg = 'Eroare la salvarea Lotului!';
                    if (response.data && response.data.message) {
                        errMsg = response.data.message;
                    }
                    deferred.reject(errMsg);
                    console.error(' -- REST POST call Error: /acqProcedureLot/create/' + acqProcedureId);
                }
            );
            return deferred.promise;
        };

        /**
         * Actualizeaza un Lot pentru o Procedura de Achizitie
         */
        service.update = function (acqProcedureId, acqProcedureLotDTO, isForOffline) {
            var deferred = $q.defer();
            // make server request
            $http({
                method: 'PUT',
                url: API_BASE_URL + '/acqProcedureLot/update' + (isForOffline ? 'ForOffline/' : '/') + acqProcedureId,
                headers: {
                    'Content-Type': "application/json"
                },
                data: JSON.stringify(acqProcedureLotDTO)
            }).then(
                function success(response) {
                    deferred.resolve(response.data);

                }, function error(response) {
                    var errMsg = 'Eroare la salvarea Lotului!';
                    if (response.data && response.data.message) {
                        errMsg = response.data.message;
                    }
                    deferred.reject(errMsg);
                    console.error(' -- REST POST call Error: /acqProcedureLot/update/' + acqProcedureId);
                }
            );
            return deferred.promise;
        };


        /**
         * Sterge un Lot pentru o Procedura de Achizitie
         */
        service.delete = function (acqProcedureLotId) {
            var deferred = $q.defer();
            // make server request
            $http({
                method: 'DELETE',
                url: API_BASE_URL + '/acqProcedureLot/delete/' + acqProcedureLotId,
                headers: {
                    'Content-Type': "application/json"
                }
            }).then(
                function success(response) {
                    deferred.resolve(response.data);

                }, function error(response) {
                    var errMsg = 'Eroare la stergerea Lotului!';
                    if (response.data && response.data.message) {
                        errMsg = response.data.message;
                    }
                    deferred.reject(errMsg);
                    console.error(' -- REST POST call Error: /acqProcedureLot/delete/' + acqProcedureLotId);
                }
            );
            return deferred.promise;
        };

        return service;
    }
})();
