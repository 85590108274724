(function() {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .controller('SettingsController', SettingsController);

    SettingsController.$inject = ['Principal', 'Auth', 'ProfileService', 'SeapAlertService', 'SicapSyncService', '$state'];

    function SettingsController (Principal, Auth, ProfileService, SeapAlertService, SicapSyncService, $state) {
        var vm = this;

        vm.error = null;
        vm.save = save;
        vm.updateSeapInfo = updateSeapInfo;
        vm.settingsAccount = null;
        vm.success = null;
        vm.isAC = Principal.hasAnyAuthority(['ROLE_AC']);


        /**
         * Store the "settings account" in a separate variable, and not in the shared "account" variable.
         */
        var copyAccount = function (account) {
            return {
                activated: account.activated,
                email: account.email,
                firstName: account.firstName,
                langKey: account.langKey,
                lastName: account.lastName,
                login: account.login
            };
        };

        Principal.identity().then(function(account) {
            vm.settingsAccount = copyAccount(account);
        });

        function save () {
            Auth.updateAccount(vm.settingsAccount).then(function() {
                vm.error = null;
                vm.success = 'OK';
                Principal.identity(true).then(function(account) {
                    vm.settingsAccount = copyAccount(account);
                });
            }).catch(function() {
                vm.success = null;
                vm.error = 'ERROR';
            });
        }

        // Sincronizare date pentru autoritate contractanta din SICAP
        vm.syncContractAutorityFromSicap = function() {
            vm.isLoadingData = true;
            SicapSyncService.syncContractAuthority().then(
                function success(response) {
                    SeapAlertService.toastSuccess('Datele de profil au fost actualizate cu succes!', 'Succes');
                    $state.reload();
                    vm.isLoadingData = false;
                },
                function error(errorMsg) {
                    SeapAlertService.toastError(errorMsg);
                    vm.isLoadingData = false;
                }
            );
        };

        function updateSeapInfo () {
            ProfileService.updateSeapInfo().then(
                function success(response) {
                    SeapAlertService.toastSuccess('Datele de profil au fost actualizate cu succes!', 'Succes');
                }, function error(errorMsg) {
                    SeapAlertService.toastError('Eroare la actualizarea datelor de profil. Mesaj: ' + errorMsg, 'Eroare');
                }
            );
        }
    }
})();
