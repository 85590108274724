/**
 * Created by florin.iacob on 6/8/2017.
 */
(function() {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .factory('TendererAssocMemberService', TendererAssocMemberService);

    TendererAssocMemberService.$inject = ['$http','$q','API_BASE_URL'];

    function TendererAssocMemberService ($http, $q, API_BASE_URL) {

        var service = {};

        /**
         * Aduce un Membru ofertant pe baza ID-ului specificat
         */
        service.findById = function(tendererAssocMemberId) {
            var deferred = $q.defer();
            // make server request
            $http({
                method: 'GET',
                url: API_BASE_URL + '/tendererAssocMember/findById/' + tendererAssocMemberId,
                headers: {
                    'Content-Type': "application/json"
                }
            }).then(
                function success(response) {
                    if (!response.data) {
                        deferred.reject('No data returned!');
                    }
                    deferred.resolve(response.data);

                }, function error(response) {
                    deferred.reject(response.data.message);
                    console.error(' -- REST call Error: ' + API_BASE_URL + '/tendererAssocMember/findById/' + tendererAssocMemberId);
                }
            );
            return deferred.promise;
        };

        /**
         * Creaza un Membru ofertant pentru o Procedura de Achizitie
         */
        service.create = function(acqProcedureId, tendererId, tendererAssocMemberDTO) {
            var deferred = $q.defer();
            // make server request
            $http({
                method: 'POST',
                url: API_BASE_URL + '/tendererAssocMember/create/' + acqProcedureId + '/' + tendererId,
                headers: {
                    'Content-Type': "application/json"
                },
                data: JSON.stringify(tendererAssocMemberDTO)
            }).then(
                function success(response) {
                    deferred.resolve(response.data);

                }, function error(response) {
                    var errMsg = 'Eroare la salvarea Membrului ofertant!';
                    if (response.data && response.data.message) {
                        errMsg = response.data.message;
                    }
                    deferred.reject(errMsg);
                    console.error(' -- REST POST call Error: /tendererAssocMember/create/' + acqProcedureId + '/' + tendererId);
                }
            );
            return deferred.promise;
        };

        /**
         * Actualizeaza un Membru asociat ofertantului
         */
        service.update = function(acqProcedureId, tendererAssocMemberDTO) {
            var deferred = $q.defer();
            // make server request
            $http({
                method: 'PUT',
                url: API_BASE_URL + '/tendererAssocMember/update/' + acqProcedureId,
                headers: {
                    'Content-Type': "application/json"
                },
                data: JSON.stringify(tendererAssocMemberDTO)
            }).then(
                function success(response) {
                    deferred.resolve(response.data);

                }, function error(response) {
                    var errMsg = 'Eroare la salvarea Membrului ofertant!';
                    if (response.data && response.data.message) {
                        errMsg = response.data.message;
                    }
                    deferred.reject(errMsg);
                    console.error(' -- REST POST call Error: /tendererAssocMember/update/' + acqProcedureId);
                }
            );
            return deferred.promise;
        };

        /**
         * Sterge un membru asociat pentru ofertant
         */
        service.delete = function(acqProcedureId, tendererAssocMemberId) {
            var deferred = $q.defer();
            // make server request
            $http({
                method: 'DELETE',
                url: API_BASE_URL + '/tendererAssocMember/delete/' + acqProcedureId + "/" + tendererAssocMemberId,
                headers: {
                    'Content-Type': "application/json"
                }
            }).then(
                function success(response) {
                    deferred.resolve(response.data);

                }, function error(response) {
                    var errMsg = 'Eroare la stergerea Membrului ofertant!';
                    if (response.data && response.data.message) {
                        errMsg = response.data.message;
                    }
                    deferred.reject(errMsg);
                    console.error(' -- REST POST call Error: /tendererAssocMember/delete/' + acqProcedureId + "/" + tendererAssocMemberId);
                }
            );
            return deferred.promise;
        };

        return service;
    }
})();
