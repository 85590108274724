/**
 * Created by florin.iacob on 5/16/2017.
 */
(function() {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('form-ofertanti-listare', {
                parent: 'seap',
                url: '/form-ofertanti-listare?proc&validationMessage',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'Ofertanti'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/_seap/entities/form-ofertanti/listare/form-ofertanti-listare.html',
                        controller: 'FormOfertantiListareCtrl',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    proc: {
                        value: '',
                        squash: true
                    },
                    validationMessage: {
                        value: '',
                        squash: true
                    }
                },
                resolve: {
                    urlParams: ['$stateParams', function ($stateParams) {
                        return {
                            idProcedura: $stateParams.proc,
                            validationMessage: $stateParams.validationMessage
                        };
                    }]
                }
            })
            .state('form-ofertanti-save', {
                parent: 'seap',
                url: '/form-ofertanti-save?proc&ofertant&validationMessage',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'Salveaza ofertant'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/_seap/entities/form-ofertanti/save/form-ofertanti-save.html',
                        controller: 'FormOfertantiSaveCtrl',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    proc: {
                        value: '',
                        squash: true
                    },
                    ofertant: {
                        value: '',
                        squash: true
                    },
                    validationMessage: {
                        value: '',
                        squash: true
                    }
                },
                resolve: {
                    urlParams: ['$stateParams', function ($stateParams) {
                        return {
                            idProcedura: $stateParams.proc,
                            idOfertant: $stateParams.ofertant,
                            validationMessage: $stateParams.validationMessage
                        };
                    }]
                }
            })
            .state('form-ofertanti-save-membru', {
                parent: 'seap',
                url: '/form-ofertanti-save-membru?proc&ofertant&membru&validationMessage',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'Salveaza membru'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/_seap/entities/form-ofertanti/save-membru/form-ofertanti-save-membru.html',
                        controller: 'FormOfertantiSaveMembruCtrl',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    proc: {
                        value: '',
                        squash: true
                    },
                    ofertant: {
                        value: '',
                        squash: true
                    },
                    membru: {
                        value: '',
                        squash: true
                    },
                    validationMessage: {
                        value: '',
                        squash: true
                    }
                },
                resolve: {
                    urlParams: ['$stateParams', function ($stateParams) {
                        return {
                            idProcedura: $stateParams.proc,
                            idOfertant: $stateParams.ofertant,
                            idMembru: $stateParams.membru,
                            validationMessage: $stateParams.validationMessage
                        };
                    }]
                }
            })
            .state('form-ofertanti-save-participant', {
                parent: 'seap',
                url: '/form-ofertanti-save-participant?proc&ofertant&participant&validationMessage',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'Salveaza participant'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/_seap/entities/form-ofertanti/save-participant/form-ofertanti-save-participant.html',
                        controller: 'FormOfertantiSaveParticipantCtrl',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    proc: {
                        value: '',
                        squash: true
                    },
                    ofertant: {
                        value: '',
                        squash: true
                    },
                    participant: {
                        value: '',
                        squash: true
                    },
                    validationMessage: {
                        value: '',
                        squash: true
                    }
                },
                resolve: {
                    urlParams: ['$stateParams', function ($stateParams) {
                        return {
                            idProcedura: $stateParams.proc,
                            idOfertant: $stateParams.ofertant,
                            idParticipant: $stateParams.participant,
                            validationMessage: $stateParams.validationMessage
                        };
                    }]
                }
            });
    }

})();
