/**
 * Created by florin.iacob on 5/16/2017.
 */
(function() {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .controller('AlegeAsociatiiFormCtrl', AlegeAsociatiiFormCtrl);

    AlegeAsociatiiFormCtrl.$inject = ['$q', 'SeapAlertService', 'NomenclatorService', 'DecisionMakerAssocService', 'EvalCmteMemberAssocService', '$scope'];

    function AlegeAsociatiiFormCtrl($q, SeapAlertService, NomenclatorService, DecisionMakerAssocService, EvalCmteMemberAssocService, $scope) {

        var vm = this;
        /*
            parentId: '=',
            parentName: '@',
            isReadOnly: '='
         */
        var entityName;

        vm.initData = function() {
            var serverRequestsArray = [];
            if (vm.parentId) {
                if (vm.parentName ==  'DecisionMaker') {
                    serverRequestsArray.push(DecisionMakerAssocService.findByDecisionMakerId(vm.parentId));
                    entityName = 'decisionMaker';
                } else if (vm.parentName ==  'EvalCmteMember') {
                    serverRequestsArray.push(EvalCmteMemberAssocService.findByEvalCmteMemberId(vm.parentId));
                    entityName = 'evalCmteMember';
                } else {
                    SeapAlertService.toastError("Nu pot fi aduse date depre asociatii!");
                    return;
                }
            }

            // Aducere date Server
            vm.isLoadingData = true;
            $q.all(serverRequestsArray).then(
                function success(responseArray) {

                    if (vm.parentId) {
                        vm.assocDTOList = responseArray[0];
                    } else {
                        vm.assocDTOList = [];
                    }

                    vm.isLoadingData = false;
                },
                function error(error) {
                    SeapAlertService.toastError('Eroare la aducere date despre Asociatii!');
                    vm.isLoadingData = false;
                }
            );
        };

        vm.initData();

        vm.selectedAsociatie = null;

        // Adauga o noua asociatie
        vm.addAsociatie = function(selectedAsociatie) {
            if (!selectedAsociatie) {
                SeapAlertService.toastError('Nume asociatie invalid!');
                return;
            }

            var existent = false;
            angular.forEach(vm.assocDTOList, function(asociatieItem, index) {
                if (!asociatieItem.isDeleted && asociatieItem.associationName === selectedAsociatie) {
                    existent = true;
                    return;
                }
            });

            if (existent) {
                SeapAlertService.toastError('Asociatie deja existenta!');
            } else {
                vm.assocDTOList.push({
                    associationName: selectedAsociatie
                });
                $scope.formAlegeAsociatii.$pristine = false;
            }

        };

        // Sterge o Asociatie
        vm.deleteAsociatie = function(asociatie) {
            $scope.formAlegeAsociatii.$pristine = false;
            angular.forEach(vm.assocDTOList, function(asociatieItem, index) {
                if (asociatie[entityName + "AssocId"]) {
                    if (asociatieItem[entityName + "AssocId"] === asociatie[entityName + "AssocId"]) {
                        vm.assocDTOList[index].isDeleted = true;
                        return;
                    }
                } else {
                    if (asociatieItem.associationName === asociatie.associationName) {
                        vm.assocDTOList.splice(index, 1);
                        return;
                    }
                }
            });
        };

        // Editable
        vm.isAlegeAsociatiiFormEditable = false;
        vm.toggleAlegeAsociatiiFormEdit = function() {
            vm.isAlegeAsociatiiFormEditable = !vm.isAlegeAsociatiiFormEditable;
        };

        /**
         * Salveaza informatiile depre formular Asociatii
         */
        vm.saveAlegeAsociatiiForm = function() {
            var serverRequestArray = [];
            if (vm.parentName ==  'DecisionMaker') {
                serverRequestArray.push(DecisionMakerAssocService.save(vm.parentId, vm.assocDTOList));
            } else if (vm.parentName ==  'EvalCmteMember') {
                serverRequestArray.push(EvalCmteMemberAssocService.save(vm.parentId, vm.assocDTOList));
            }

            vm.isLoadingData = true;
            $q.all(serverRequestArray).then(
                function success(responseArray) {
                    vm.assocDTOList = responseArray[0];
                    vm.isAlegeAsociatiiFormEditable = false;
                    vm.isLoadingData = false;
                    $scope.formAlegeAsociatii.$pristine = true;
                    SeapAlertService.toastSuccess("Lista de asociatii salvata cu succes!", "Succes");

                }, function error(errMsg) {
                    vm.isLoadingData = false;
                    SeapAlertService.toastError("Eroare la salvare lista de asociatii! Mesaj: " + errMsg, "Eroare");
                }
            );
        };
    }
})();
