(function() {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .controller('ModalAlertTemplateCtrl', ModalAlertTemplateCtrl);

    ModalAlertTemplateCtrl.$inject = ['$uibModalInstance', 'modalOptions'];

    function ModalAlertTemplateCtrl($uibModalInstance, modalOptions) {
        var vm = this;
        vm.modalOptions = modalOptions;

        vm.ok = function () {
            if (vm.modalOptions.okCallback) {
                vm.modalOptions.okCallback();
            }
            $uibModalInstance.close();
        };

        vm.cancel = function () {
            $uibModalInstance.dismiss('cancel');
        };
    }
})();
