/**
 * Created by florin.iacob on 5/16/2017.
 */
(function() {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('form-consultanti-documentatie-lista', {
                parent: 'seap',
                url: '/form-consultanti-documentatie-lista?proc&validationMessage',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'Consultanti elaborare documentatie'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/_seap/entities/form-consultanti-documentatie/lista/form-consultanti-documentatie-lista.html',
                        controller: 'FormConsultantiDocumentatieListaCtrl',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    proc: {
                        value: '',
                        squash: true
                    },
                    validationMessage: {
                        value: '',
                        squash: true
                    }
                },
                resolve: {
                    urlParams: ['$stateParams', function ($stateParams) {
                        return {
                            idProcedura: $stateParams.proc,
                            validationMessage: $stateParams.validationMessage
                        };
                    }]
                }
            })
            .state('form-consultanti-documentatie-save-pf', {
                parent: 'seap',
                url: '/form-consultanti-documentatie-save-pf?proc&cons&validationMessage',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'Salveaza consultant'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/_seap/entities/form-consultanti-documentatie/save-pf/form-consultanti-documentatie-save-pf.html',
                        controller: 'FormConsultantiDocumentatieSavePfCtrl',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    proc: {
                        value: '',
                        squash: true
                    },
                    cons: {
                        value: '',
                        squash: true
                    },
                    validationMessage: {
                        value: '',
                        squash: true
                    }
                },
                resolve: {
                    urlParams: ['$stateParams', function ($stateParams) {
                        return {
                            idProcedura: $stateParams.proc,
                            idConsultant: $stateParams.cons,
                            validationMessage: $stateParams.validationMessage
                        };
                    }]
                }
            })
            .state('form-consultanti-documentatie-save-pj', {
                parent: 'seap',
                url: '/form-consultanti-documentatie-save-pj?proc&cons&validationMessage',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'Salveaza consultant'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/_seap/entities/form-consultanti-documentatie/save-pj/form-consultanti-documentatie-save-pj.html',
                        controller: 'FormConsultantiDocumentatieSavePjCtrl',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    proc: {
                        value: '',
                        squash: true
                    },
                    cons: {
                        value: '',
                        squash: true
                    },
                    validationMessage: {
                        value: '',
                        squash: true
                    }
                },
                resolve: {
                    urlParams: ['$stateParams', function ($stateParams) {
                        return {
                            idProcedura: $stateParams.proc,
                            idConsultant: $stateParams.cons,
                            validationMessage: $stateParams.validationMessage
                        };
                    }]
                }
            })
            .state('form-consultanti-documentatie-save-angajator', {
                parent: 'seap',
                url: '/form-consultanti-documentatie-save-angajator?proc&cons&employer&validationMessage',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'Salveaza angajator'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/_seap/entities/form-consultanti-documentatie/save-angajator/form-consultanti-documentatie-save-angajator.html',
                        controller: 'FormConsultantiDocumentatieSaveAngajatorCtrl',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    proc: {
                        value: '',
                        squash: true
                    },
                    cons: {
                        value: '',
                        squash: true
                    },
                    employer: {
                        value: '',
                        squash: true
                    },
                    validationMessage: {
                        value: '',
                        squash: true
                    }
                },
                resolve: {
                    urlParams: ['$stateParams', function ($stateParams) {
                        return {
                            idProcedura: $stateParams.proc,
                            idConsultant: $stateParams.cons,
                            idEmployer: $stateParams.employer,
                            validationMessage: $stateParams.validationMessage
                        };
                    }]
                }
            });
    }

})();
