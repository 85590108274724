(function() {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .config(stateConfig)
        .config(datePickerConfig)
        .config(toastrSetupConfig);

    stateConfig.$inject = ['$stateProvider'];
    function stateConfig($stateProvider) {
        $stateProvider.state('app', {
            abstract: true,
            views: {
                'navbar@': {
                    templateUrl: 'app/_seap/layouts/navbar/navbar.html',
                    controller: 'FmNavbarController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                authorize: ['Auth',
                    function (Auth) {
                        return Auth.authorize();
                    }
                ]
            }
        });
    }

    datePickerConfig.$inject = ['uiDatetimePickerConfig'];
    function datePickerConfig(uiDatetimePickerConfig) {
        uiDatetimePickerConfig.buttonBar.now.text = "Acum";
        uiDatetimePickerConfig.buttonBar.today.text = "Astazi";
        uiDatetimePickerConfig.buttonBar.clear.text = "Sterge";
        uiDatetimePickerConfig.buttonBar.date.text = "Data";
        uiDatetimePickerConfig.buttonBar.time.text = "Ora";
        uiDatetimePickerConfig.buttonBar.close.text = "Inchide";
    }

    toastrSetupConfig.$inject = ['toastrConfig'];
    function toastrSetupConfig(toastrConfig) {
        angular.extend(toastrConfig, {
            timeOut: 10000,
            extendedTimeOut: 7000,
            closeButton: true
        });
    }
})();
