/**
 * Created by florin.iacob on 5/16/2017.
 */
(function() {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .controller('FormFactoriDecizionaliListaCtrl', FormFactoriDecizionaliListaCtrl);

    FormFactoriDecizionaliListaCtrl.$inject = ['$q', '$state', 'SeapAlertService', 'urlParams', 'FormFactoriDecizionaliService', 'FormProceduraAchizitieService', 'Constants', '$uibModal', 'IntegrityWarningService', 'Principal', 'SicapSyncService'];

    function FormFactoriDecizionaliListaCtrl($q, $state, SeapAlertService, urlParams, FormFactoriDecizionaliService, FormProceduraAchizitieService, Constants, $uibModal, IntegrityWarningService, Principal, SicapSyncService) {

        var vm = this;
        if (urlParams.acqProcedureId) {
            vm.acqProcedureId = parseInt(urlParams.acqProcedureId);
        }
        vm.validationMessage = urlParams.validationMessage;
        vm.isLoadingData = false;

        vm.INTEGRITY_FORM_SECTION = Constants.INTEGRITY_FORM_SECTION;
        vm.INTEGRITY_FORM_SUBSECTION = Constants.INTEGRITY_FORM_SUBSECTION;

        vm.isAC = Principal.hasAnyAuthority(['ROLE_AC']);
        vm.isOpSeap = Principal.hasAnyAuthority(['ROLE_OP_SEAP']);

        vm.hasRoleManualAddDecisionMaker = Principal.hasAnyAuthority(['ROLE_MANUAL_ADD_ALL', 'ROLE_MANUAL_ADD_DECISION_MAKER']);
        vm.hasRoleExclusiveSyncAllSections = Principal.hasAnyAuthority(['ROLE_EXCLUSIVE_SYNC_ALL_SECTIONS']);

        /**
         * Verificare daca formularul este doar in modul de citire date, dara editare
         */
        vm.checkIfReadonly = function() {
            vm.isReadOnlyMode = false;

            if (vm.acqProcedure.isReadOnly
                || !vm.acqProcedure.editableSectionScheme[vm.INTEGRITY_FORM_SECTION.I_2]) {

                vm.isReadOnlyMode = true;
            }
        };

        /**
         * Se va face asocierea avertismentelor pentru ultima versiune cu persoanele nu lista
         */
        vm.asociereAvertismenteCuPersoane = function() {
            if (vm.warningList && vm.warningList.length) {
                angular.forEach(vm.warningList, function(warningItem, warningIndex) {
                    if (warningItem.identificatorDtoList && warningItem.identificatorDtoList.length) {
                        angular.forEach(warningItem.identificatorDtoList, function(identificatorDtoItem, identificatorDtoIndex) {
                            angular.forEach(vm.decisionMakerList, function(decisionMakerItem, decisionMakerIndex) {
                                var hasWarning = false;
                                if (identificatorDtoItem.cnp) {
                                    if (identificatorDtoItem.cnp === decisionMakerItem.naturalPersonDTO.cnp) {
                                        hasWarning = true;
                                    }
                                } else {
                                    if (identificatorDtoItem.pin === decisionMakerItem.naturalPersonDTO.pin) {
                                        hasWarning = true;
                                    }
                                }
                                if (hasWarning) {
                                    vm.decisionMakerList[decisionMakerIndex].warningDesc = vm.warningList[warningIndex].warningDesc;
                                    vm.decisionMakerList[decisionMakerIndex].warningDate = vm.warningList[warningIndex].warningDate;
                                }
                            });
                        });
                    }
                });
            }
        };

        /**
         * Intializeaza date Controller
         */
        vm.initData = function() {
            var serverRequestArray = [];
            serverRequestArray.push(FormProceduraAchizitieService.findById(vm.acqProcedureId));
            serverRequestArray.push(FormProceduraAchizitieService.getEditableSubsectionTypeList(vm.acqProcedureId, vm.INTEGRITY_FORM_SECTION.I_2));
            serverRequestArray.push(FormFactoriDecizionaliService.findByAcqProcedureId(vm.acqProcedureId));
            if (vm.isAC || vm.isOpSeap) {
                serverRequestArray.push(IntegrityWarningService.getWarningListForLastVersion(null, vm.acqProcedureId));
            }

            vm.isLoadingData = true;
            $q.all(serverRequestArray).then(
                function success(responseArray) {
                    vm.acqProcedure = responseArray[0];
                    vm.editableSubsectionTypeList = responseArray[1];
                    vm.decisionMakerList = responseArray[2];
                    if (vm.isAC || vm.isOpSeap) {
                        vm.warningList = responseArray[3];
                        vm.asociereAvertismenteCuPersoane();
                    }
                    vm.hasRoleManualAddDecisionMaker = vm.hasRoleManualAddDecisionMaker && vm.acqProcedure.isLocal;

                    vm.checkIfReadonly();
                    vm.isLoadingData = false;
                }, function(errMsg) {
                    vm.isLoadingData = false;
                    SeapAlertService.toastError("Eroare la aducere date!", "Eroare");
                }
            );
        };

        vm.initData();

        // Adaugare Factor
        vm.openSaveFactorModal = function(decisionMaker) {
            if (decisionMaker) {
                $state.go('form-factori-decizionali-save', {proc: vm.acqProcedureId, factor: decisionMaker.decisionMakerId, validationMessage: vm.validationMessage});
            } else {
                $state.go('form-factori-decizionali-save', {proc: vm.acqProcedureId});
            }
        };

        // Sterge Factor decizional
        vm.deleteFactor = function(decisionMaker) {
            FormFactoriDecizionaliService.delete(vm.acqProcedureId, decisionMaker.decisionMakerId).then(
                function success(response) {
                    SeapAlertService.toastSuccess('Factor decizional sters cu succes!', 'Succes');
                    angular.forEach(vm.decisionMakerList, function(decisionMakerItem, index) {
                        if (decisionMakerItem.decisionMakerId === decisionMaker.decisionMakerId) {
                            vm.decisionMakerList.splice(index, 1);
                            return;
                        }
                    });
                }, function error(errorMsg) {
                    SeapAlertService.toastError('Eroare la stergerea Factorului decizional. Mesaj: ' + errorMsg, 'Eroare');
                }
            );
        };

        /**
         * Preluare Factori Decizionali din alta Procedura
         */
        vm.openPreluareFactorModal = function() {
            $uibModal.open({
                templateUrl: 'app/_seap/modals/preluare-factori/preluare-factori.html',
                controller: 'PreluareFactoriCtrl',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    acqProcedureId: function() {
                        return vm.acqProcedureId;
                    }
                }
            }).result.then(
                function success(response) {
                    vm.initData();
                }, function error(response) {
                    console.error(' -- Eroare la preluare Listei de Factori decizionali!');
                }
            );
        };

        // Sincronizare date lista factori decizionali din SICAP
        vm.syncDecisionMakersFromSicap = function() {
            vm.isLoadingData = true;
            SicapSyncService.syncDecisionMakerList(vm.acqProcedureId).then(
                function success(response) {
                    vm.initData();
                    SeapAlertService.toastSuccess("Preluarea listei de contracte din Noul SEAP s-a efectuat cu succes!");
                    vm.isLoadingData = false;
                },
                function error(errorMsg) {
                    SeapAlertService.toastError(errorMsg);
                    vm.isLoadingData = false;
                }
            );
        };
    }
})();
