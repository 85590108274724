(function() {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .controller('PublicationProgressCtrl', PublicationProgressCtrl);

    PublicationProgressCtrl.$inject = ['$uibModalInstance', 'title', 'message'];

    function PublicationProgressCtrl($uibModalInstance, title, message) {
        var vm = this;
        vm.title = title;
        vm.message = message;


        vm.close = function () {
            $uibModalInstance.close();
        }
    }
})();
