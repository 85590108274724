(function() {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('statistics-integrity-forms', {
            parent: 'statistics',
            url: '/statistics-integrity-forms',
            data: {
                authorities: ['ROLE_OP_SEAP'],
                pageTitle: 'Statistici formulare integritate'
            },
            views: {
                'content@': {
                    templateUrl: 'app/_seap/layouts/statistics/integrity-forms/statistics-integrity-forms.html',
                    controller: 'StatisticsIntegrityFormsController',
                    controllerAs: 'vm'
                }
            }
        });
    }
})();
