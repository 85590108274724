/**
 * Created by florin.iacob on 5/22/2017.
 */
(function() {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .factory('ProcConsultantEmployerService', ProcConsultantEmployerService);

    ProcConsultantEmployerService.$inject = ['$q', '$http', 'GenericService', 'API_BASE_URL'];

    function ProcConsultantEmployerService($q, $http, GenericService, API_BASE_URL) {

        var Service = {};

        /**
         * Aducerea listei de Angajatori pentru Consultantul specificat
         */
        Service.findByProcurementConsultantId = function(procurementConsultantID) {
            var deferred = $q.defer();
            // make server request
            $http({
                method: 'GET',
                url: API_BASE_URL + '/procConsultantEmployer/findByProcurementConsultantId/' + procurementConsultantID,
                headers: {
                    'Content-Type': "application/json"
                }
            }).then(
                function success(response) {
                    deferred.resolve(response.data);

                }, function error(response) {
                    deferred.reject(response.data.message);
                    console.error(' -- REST call Error: ' + API_BASE_URL + '/procConsultantEmployer/findByProcurementConsultantId/' + procurementConsultantID);
                }
            );
            return deferred.promise;
        };

        /**
         * Actualizeaza lista de Angajatori pentru Consultantul specificat
         */
        Service.save = function(procurementConsultantID, procConsultantEmployerDTOList) {
            var deferred = $q.defer();
            // make server request
            $http({
                method: 'PUT',
                url: API_BASE_URL + '/procConsultantEmployer/save/' + procurementConsultantID,
                headers: {
                    'Content-Type': "application/json"
                },
                data: JSON.stringify(procConsultantEmployerDTOList)
            }).then(
                function success(response) {
                    deferred.resolve(response.data);

                }, function error(response) {
                    var errMsg = 'Eroare la salvarea Angajatorului!';
                    if (response.data && response.data.message) {
                        errMsg = response.data.message;
                    }
                    deferred.reject(errMsg);
                    console.error(' -- REST PUT call Error: /procConsultantEmployer/save/' + procurementConsultantID);
                }
            );
            return deferred.promise;
        };

        /**
        * Aducerea unui Angajator pe baza ID-ului
        */
        Service.findById = function (procConsultantEmployerId) {
            return GenericService.GetFn('/procConsultantEmployer/findById/' + procConsultantEmployerId)
        };

        /**
         * Creaza un Angajator pentru cosnultantul cu ID-ul specficat
         */
        Service.create = function (acqProcedureId, procurementConsultantId, procConsultantEmployerDTO) {
            return GenericService.PostFn('/procConsultantEmployer/create/' + acqProcedureId + '/' + procurementConsultantId, procConsultantEmployerDTO);
        };

        /**
         * Actualizeaza un angajator pe baza DTO-ului
         */
        Service.update = function (acqProcedureId, procConsultantEmployerDTO) {
            return GenericService.PutFn('/procConsultantEmployer/update/' + acqProcedureId, procConsultantEmployerDTO);
        };

        /**
         * Sterge un Angajator avand ID-ul specificat
         */
        Service.delete = function (acqProcedureId, procConsultantEmployerId) {
            return GenericService.DeleteFn('/procConsultantEmployer/delete/' + acqProcedureId + '/' + procConsultantEmployerId);
        };

        return Service
    }
})();

