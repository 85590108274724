/**
 * Created by florin.iacob on 5/16/2017.
 */
(function() {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .factory('MeasureTakenService', MeasureTakenService);

    MeasureTakenService.$inject = ['$http','$q','API_BASE_URL'];

    function MeasureTakenService ($http, $q, API_BASE_URL) {

        var service = {};

        /**
         * Load masura dispusa
         */
        service.findById = function(measureTakenId) {
            var deferred = $q.defer();
            // make server request
            $http({
                method: 'GET',
                url: API_BASE_URL + '/measuretaken/findById/' + measureTakenId,
                headers: {
                    'Content-Type': "application/json"
                }
            }).then(
                function success(response) {
                    if (!response.data) {
                        deferred.reject('No data returned!');
                    }
                    deferred.resolve(response.data);

                }, function error(response) {
                    deferred.reject(response.data.message);
                    console.error(' -- REST call Error: ' + API_BASE_URL + '/measuretaken/findById/' + measureTakenId);
                }
            );
            return deferred.promise;
        };

        /**
         * Creaza o masura dispusa
         */
        service.create = function(integrityWarningId, measureTakenDTO) {
            var deferred = $q.defer();
            // make server request
            $http({
                method: 'POST',
                url: API_BASE_URL + '/measuretaken/create/' + integrityWarningId,
                headers: {
                    'Content-Type': "application/json"
                },
                data: JSON.stringify(measureTakenDTO)
            }).then(
                function success(response) {
                    deferred.resolve(response.data);

                }, function error(response) {
                    var errMsg = 'Eroare la salvarea masurii!';
                    if (response.data && response.data.message) {
                        errMsg = response.data.message;
                    }
                    deferred.reject(errMsg);
                    console.error(' -- REST POST call Error: /measuretaken/create/' + integrityWarningId);
                }
            );
            return deferred.promise;
        };

        /**
         * Actualizeaza un Contract pentru o Procedura de Achizitie
         */
        service.update = function(measureTakenDTO) {
            var deferred = $q.defer();
            // make server request
            $http({
                method: 'PUT',
                url: API_BASE_URL + '/measuretaken/update',
                headers: {
                    'Content-Type': "application/json"
                },
                data: JSON.stringify(measureTakenDTO)
            }).then(
                function success(response) {
                    deferred.resolve(response.data);

                }, function error(response) {
                    var errMsg = 'Eroare la salvarea masurii!';
                    if (response.data && response.data.message) {
                        errMsg = response.data.message;
                    }
                    deferred.reject(errMsg);
                    console.error(' -- REST POST call Error: /measuretaken/update');
                }
            );
            return deferred.promise;
        };

        /**
         * Sterge un Contract pentru o Procedura de Achizitie
         */
        service.delete = function(measureTakenId) {
            var deferred = $q.defer();
            // make server request
            $http({
                method: 'DELETE',
                url: API_BASE_URL + '/measuretaken/delete/' + measureTakenId,
                headers: {
                    'Content-Type': "application/json"
                }
            }).then(
                function success(response) {
                    deferred.resolve(response.data);

                }, function error(response) {
                    var errMsg = 'Eroare la stergerea Contractului!';
                    if (response.data && response.data.message) {
                        errMsg = response.data.message;
                    }
                    deferred.reject(errMsg);
                    console.error(' -- REST POST call Error: /measuretaken/delete/' + measureTakenId);
                }
            );
            return deferred.promise;
        };

        return service;
    }
})();
