/**
 * Created by florin.iacob on 5/16/2017.
 */
(function() {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('form-factori-decizionali-lista', {
                parent: 'seap',
                url: '/form-factori-decizionali-lista?proc&validationMessage',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'Factori decizionali'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/_seap/entities/form-factori-decizionali/lista/form-factori-decizionali-lista.html',
                        controller: 'FormFactoriDecizionaliListaCtrl',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    proc: {
                        value: '',
                        squash: true
                    },
                    validationMessage: {
                        value: '',
                        squash: true
                    }
                },
                resolve: {
                    urlParams: ['$stateParams', function ($stateParams) {
                        return {
                            acqProcedureId: $stateParams.proc,
                            validationMessage: $stateParams.validationMessage
                        };
                    }]
                }
            })
            .state('form-factori-decizionali-save', {
                parent: 'seap',
                url: '/form-factori-decizionali-save?proc&factor&validationMessage',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'Salveaza factor'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/_seap/entities/form-factori-decizionali/save/form-factori-decizionali-save.html',
                        controller: 'FormFactoriDecizionaliSaveCtrl',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    proc: {
                        value: '',
                        squash: true
                    },
                    factor: {
                        value: '',
                        squash: true
                    },
                    validationMessage: {
                        value: '',
                        squash: true
                    }
                },
                resolve: {
                    urlParams: ['$stateParams', function ($stateParams) {
                        return {
                            acqProcedureId: $stateParams.proc,
                            decisionMakerId: $stateParams.factor,
                            validationMessage: $stateParams.validationMessage
                        };
                    }]
                }
            });
    }

})();
