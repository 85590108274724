/**
 * Created by florin.iacob on 5/16/2017.
 */
(function() {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .controller('FiltreCautareProceduriCtrl', FiltreCautareProceduriCtrl);

    FiltreCautareProceduriCtrl.$inject = ['Principal', '$q', 'SeapAlertService', 'NomenclatorService', 'FormProceduraAchizitieService', 'FiltreCautareProceduriService'];

    function FiltreCautareProceduriCtrl(Principal, $q, SeapAlertService, NomenclatorService, FormProceduraAchizitieService, FiltreCautareProceduriService) {

        var vm = this;
        /*
            isFilterOpen: '=',
            onApplyFilter: '&'
        */

        vm.initFilterDTO = function() {

            if (FiltreCautareProceduriService.getFilterDTO() != null) {
                vm.filterDTO = FiltreCautareProceduriService.getFilterDTO();
            } else {
                vm.filterDTO = FiltreCautareProceduriService.initFilterDTO();
            }
        };

        /**
         * Initializare date
         */
        vm.initData = function() {
            var serverRequests = [
                NomenclatorService.findByKey('NomFormState'),
                NomenclatorService.findByKey('NomPublicationType'),
                NomenclatorService.findByKey('NomAcqProcedureState')
            ];

            vm.data = {};

            vm.initFilterDTO();

            // Aducere date Server
            vm.isLoadingData = true;
            $q.all(serverRequests).then(
                function success(responseArray) {

                    vm.data.nomFormStateList = responseArray[0];
                    vm.data.nomFormStateList.splice(0, 0, {id: null, name: ""});
                    vm.data.nomFormStateList.splice(1, 0, {id: 0, name: "Nepublicat"});
                    vm.data.nomPublicationTypeList = responseArray[1];
                    vm.data.nomPublicationTypeList.splice(0, 0, {id: null, name: ""});
                    vm.data.nomAcqProcedureStateList = responseArray[2];
                    vm.data.nomAcqProcedureStateList.splice(0, 0, {id: null, name: ""});

                    vm.isLoadingData = false;
                },
                function error(errorArray) {
                    SeapAlertService.toastError('Eroare la aducere date pentru Filtre!');
                    vm.isLoadingData = false;
                }
            );
        };

        vm.initData();

        /**
         * Reseteaza Filtre
         */
        vm.resetFilter = function() {
            FiltreCautareProceduriService.setFilterByIntegrityWarningsReceivedActivated(false);
            FiltreCautareProceduriService.setFilterDTO(null);
            vm.initFilterDTO();
            vm.applyFilter();
        };

        /**
         * Aplica filterle completate
         */
        vm.applyFilter = function() {
            vm.isLoadingData = true;
            vm.onApplyFilter({filterDTO: vm.filterDTO}).then(
                function success(response) {
                    FiltreCautareProceduriService.setFilterDTO(vm.filterDTO);
                    vm.isLoadingData = false;
                }, function error(errMsg) {
                    SeapAlertService.toastError("Eroare la aplicare filtre! Mesaj: " + errMsg);
                    vm.isLoadingData = false;
                }
            );
        };

        /**
         * Deschidere/inchidere filtre
         *
         * @param isFilterOpen
         */
        vm.toggleFilterOpen = function(isFilterOpen) {
            vm.isFilterOpen = isFilterOpen;
            FiltreCautareProceduriService.setIsFilterApplicable(vm.isFilterOpen);
        };

        /**
         * Schimbare Filtru Stare Procedura
         */
        vm.onNomAcqProcedureStateChange = function() {
            angular.forEach(vm.data.nomAcqProcedureStateList, function(nomAcqProcedureState, index) {
                if (nomAcqProcedureState.id === vm.filterDTO.nomAcqProcedureStateDTO.id) {
                    vm.filterDTO.nomAcqProcedureStateDTO.name = nomAcqProcedureState.name;
                }
            });
        };

        // DATE PICKER
        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.publicationDateStart = false;
        vm.datePickerOpenStatus.publicationDateEnd = false;
        vm.openCalendar = function(date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
