/**
 * Created by florin.iacob on 6/8/2017.
 */
(function() {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .factory('FormOfertantiService', FormOfertantiService);

    FormOfertantiService.$inject = ['$http','$q','API_BASE_URL'];

    function FormOfertantiService ($http, $q, API_BASE_URL) {

        var service = {};

        /**
         * Aducerea listei de Factori Decizionali pentru Procedura de Achzitie specificata
         */
        service.findByAcqProcedureId = function(acqProcedureId) {
            var deferred = $q.defer();
            // make server request
            $http({
                method: 'GET',
                url: API_BASE_URL + '/tenderer/findByAcqProcedureId/' + acqProcedureId,
                headers: {
                    'Content-Type': "application/json"
                }
            }).then(
                function success(response) {
                    if (!response.data) {
                        deferred.reject('No data returned!');
                    }
                    deferred.resolve(response.data);

                }, function error(response) {
                    deferred.reject(response.data.message);
                    console.error(' -- REST call Error: ' + API_BASE_URL + '/tenderer/findByAcqProcedureId/' + acqProcedureId);
                }
            );
            return deferred.promise;
        };

        /**
         * Aduce un Ofertant pe baza ID-ului specificat
         */
        service.findById = function(tendererId) {
            var deferred = $q.defer();
            // make server request
            $http({
                method: 'GET',
                url: API_BASE_URL + '/tenderer/findById/' + tendererId,
                headers: {
                    'Content-Type': "application/json"
                }
            }).then(
                function success(response) {
                    if (!response.data) {
                        deferred.reject('No data returned!');
                    }
                    deferred.resolve(response.data);

                }, function error(response) {
                    deferred.reject(response.data.message);
                    console.error(' -- REST call Error: ' + API_BASE_URL + '/tenderer/findById/' + tendererId);
                }
            );
            return deferred.promise;
        };

        /**
         * Creaza un Ofertant pentru o Procedura de Achizitie
         */
        service.create = function(acqProcedureId, tendererDTO) {
            var deferred = $q.defer();
            // make server request
            $http({
                method: 'POST',
                url: API_BASE_URL + '/tenderer/create/' + acqProcedureId,
                headers: {
                    'Content-Type': "application/json"
                },
                data: JSON.stringify(tendererDTO)
            }).then(
                function success(response) {
                    deferred.resolve(response.data);

                }, function error(response) {
                    var errMsg = 'Eroare la salvarea Ofertantului!';
                    if (response.data && response.data.message) {
                        errMsg = response.data.message;
                    }
                    deferred.reject(errMsg);
                    console.error(' -- REST POST call Error: /tenderer/create/' + acqProcedureId);
                }
            );
            return deferred.promise;
        };

        /**
         * Actualizeaza un Ofertant pentru o Procedura de Achizitie
         */
        service.update = function(acqProcedureId, tendererDTO) {
            var deferred = $q.defer();
            // make server request
            $http({
                method: 'PUT',
                url: API_BASE_URL + '/tenderer/update/' + acqProcedureId,
                headers: {
                    'Content-Type': "application/json"
                },
                data: JSON.stringify(tendererDTO)
            }).then(
                function success(response) {
                    deferred.resolve(response.data);

                }, function error(response) {
                    var errMsg = 'Eroare la salvarea Ofertantului!';
                    if (response.data && response.data.message) {
                        errMsg = response.data.message;
                    }
                    deferred.reject(errMsg);
                    console.error(' -- REST POST call Error: /tenderer/update/' + acqProcedureId);
                }
            );
            return deferred.promise;
        };

        /**
         * Sterge un Factor decizional pentru o Procedura de Achizitie
         */
        service.delete = function(acqProcedureId, tendererId) {
            var deferred = $q.defer();
            // make server request
            $http({
                method: 'DELETE',
                url: API_BASE_URL + '/tenderer/delete/' + acqProcedureId + "/" + tendererId,
                headers: {
                    'Content-Type': "application/json"
                }
            }).then(
                function success(response) {
                    deferred.resolve(response.data);

                }, function error(response) {
                    var errMsg = 'Eroare la stergerea Ofertantului!';
                    if (response.data && response.data.message) {
                        errMsg = response.data.message;
                    }
                    deferred.reject(errMsg);
                    console.error(' -- REST POST call Error: /tenderer/delete/' + acqProcedureId + "/" + tendererId);
                }
            );
            return deferred.promise;
        };

        return service;
    }
})();
