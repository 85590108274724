/**
 * Created by florin.iacob on 5/16/2017.
 */
(function () {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .controller('IntegrityWarningListareCtrl', IntegrityWarningListareCtrl);

    IntegrityWarningListareCtrl.$inject = ['$state', '$q', '$uibModal', 'SeapAlertService', 'urlParams', 'IntegrityFormService', 'IntegrityWarningService', 'MeasureTakenService', 'GenericService'];

    function IntegrityWarningListareCtrl($state, $q, $uibModal, SeapAlertService, urlParams, IntegrityFormService, IntegrityWarningService, MeasureTakenService, GenericService) {

        var vm = this;
        if (urlParams.integrityFormId) {
            vm.integrityFormId = parseInt(urlParams.integrityFormId);
        }
        if(urlParams.fromList) {
            vm.fromList = "true" === urlParams.fromList;
        }

        vm.isLoadingData = false;

        /**
         * Intializeaza date Controller
         */
        vm.initData = function () {
            var serverRequestArray = [];
            serverRequestArray.push(GenericService.GetFn('/integrityWarning/findIntegrityFormById/'+ vm.integrityFormId));
            serverRequestArray.push(IntegrityWarningService.findByIntegrityFormId(vm.integrityFormId));

            vm.isLoadingData = true;
            $q.all(serverRequestArray).then(
                function success(responseArray) {
                    vm.integrityForm = responseArray[0];
                    vm.acqProcedure = vm.integrityForm.acqProcedureDTO;
                    vm.acqProcedureId = vm.acqProcedure.acqProcedureId;
                    vm.integrityWarningList = responseArray[1];
                    vm.isLoadingData = false;
                }, function (errMsg) {
                    vm.isLoadingData = false;
                    SeapAlertService.toastError("Eroare la aducere date!", "Eroare");
                }
            );
        };

        vm.initData();


        vm.deleteMeasureTaken = function (measureTakenId) {
            MeasureTakenService.delete(measureTakenId).then(
                function success(response) {
                    SeapAlertService.toastSuccess('Masura dispusa a fost stearsa cu succes!', 'Succes');
                    angular.forEach(vm.integrityWarningList, function (integrityWarningItem, index) {
                        if (integrityWarningItem.measureTakenDTOs[0].measureTakenId === measureTakenId) {
                            integrityWarningItem.measureTakenDTOs = null;
                        }
                    });
                }, function error(errorMsg) {
                    SeapAlertService.toastError('Eroare la stergerea Contractului. Mesaj: ' + errorMsg, 'Eroare');
                }
            );
        };

        vm.openMeasureTakenModal = function (integrityWarningDTO){
            $uibModal.open({
                templateUrl: 'app/_seap/entities/integrity-warning/measure-taken/measure-taken.html',
                controller: 'MeasureTakenCtrl',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    // resolve variables
                    integrityWarning: integrityWarningDTO
                }
            }).result.then(
                function success(measureTaken) {
                    console.info(' -- ok createMeasureTaken');

                    integrityWarningDTO.measureTakenDTOs = [measureTaken]
                }, function error(response) {
                    console.error(' -- err createMeasureTaken');
                }
            );
        };

        vm.backState = function(){
            if(vm.fromList) {
                $state.go('integrity-form-listare', {form: vm.integrityFormId});
            } else {
                $state.go('integrity-form-save', {proc: vm.acqProcedureId});
            }
        };
    }
})();
