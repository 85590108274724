/**
 * Created by florin.iacob on 5/22/2017.
 */
(function() {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .factory('DecisionMakerAssocService', DecisionMakerAssocService);

    DecisionMakerAssocService.$inject = ['$q', '$http', 'API_BASE_URL'];

    function DecisionMakerAssocService($q, $http, API_BASE_URL) {

        var Service = {};

        /**
         * Aducerea listei de Asociatii pentru Factorul decizional specificat
         */
        Service.findByDecisionMakerId = function(decisionMakerID) {
            var deferred = $q.defer();
            // make server request
            $http({
                method: 'GET',
                url: API_BASE_URL + '/decisionMakerAssoc/findByDecisionMakerId/' + decisionMakerID,
                headers: {
                    'Content-Type': "application/json"
                }
            }).then(
                function success(response) {
                    deferred.resolve(response.data);

                }, function error(response) {
                    deferred.reject(response.data.message);
                    console.error(' -- REST call Error: ' + API_BASE_URL + '/decisionMakerAssoc/findByDecisionMakerId/' + decisionMakerID);
                }
            );
            return deferred.promise;
        };

        /**
         * Actualizeaza lista de Asociatii pentru Factorul deciszional specificat
         */
        Service.save = function(decisionMakerID, decisionMakerAssocDTOList) {
            var deferred = $q.defer();
            // make server request
            $http({
                method: 'PUT',
                url: API_BASE_URL + '/decisionMakerAssoc/save/' + decisionMakerID,
                headers: {
                    'Content-Type': "application/json"
                },
                data: JSON.stringify(decisionMakerAssocDTOList)
            }).then(
                function success(response) {
                    deferred.resolve(response.data);

                }, function error(response) {
                    var errMsg = 'Eroare la salvarea Asociatiei!';
                    if (response.data && response.data.message) {
                        errMsg = response.data.message;
                    }
                    deferred.reject(errMsg);
                    console.error(' -- REST PUT call Error: /decisionMakerAssoc/save/' + decisionMakerID);
                }
            );
            return deferred.promise;
        };

        return Service
    }
})();

