/**
 * Created by florin.iacob on 5/16/2017.
 */
(function() {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .controller('FormOfertantiListareCtrl', FormOfertantiListareCtrl);

    FormOfertantiListareCtrl.$inject = ['$q', '$state', 'SeapAlertService', 'urlParams', 'FormProceduraAchizitieService', 'FormOfertantiService',
        'TendererAssocMemberService', 'TendererParticipantService', 'Constants', 'Principal', 'SicapSyncService'];

    function FormOfertantiListareCtrl($q, $state, SeapAlertService, urlParams, FormProceduraAchizitieService, FormOfertantiService,
                                      TendererAssocMemberService, TendererParticipantService, Constants, Principal, SicapSyncService) {

        var vm = this;
        if (urlParams.idProcedura) {
            vm.acqProcedureId = parseInt(urlParams.idProcedura);
        }
        vm.validationMessage = urlParams.validationMessage;
        vm.isLoadingData = false;

        vm.INTEGRITY_FORM_SECTION = Constants.INTEGRITY_FORM_SECTION;
        vm.INTEGRITY_FORM_SUBSECTION = Constants.INTEGRITY_FORM_SUBSECTION;

        vm.hasRoleManualAddTenderer = Principal.hasAnyAuthority(['ROLE_MANUAL_ADD_ALL', 'ROLE_MANUAL_ADD_TENDERER']);
        vm.hasRoleManualAddTendererMember = Principal.hasAnyAuthority(['ROLE_MANUAL_ADD_ALL', 'ROLE_MANUAL_ADD_TENDERER_MEMBER']);
        vm.hasRoleManualAddTendererParticipant = Principal.hasAnyAuthority(['ROLE_MANUAL_ADD_ALL', 'ROLE_MANUAL_ADD_TENDERER_PARTICIPANT']);
        vm.hasRoleExclusiveSyncAllSections = Principal.hasAnyAuthority(['ROLE_EXCLUSIVE_SYNC_ALL_SECTIONS']);

        /**
         * Verificare daca formularul este doar in modul de citire date, dara editare
         */
        vm.checkIfReadonly = function() {
            vm.isReadOnlyMode = false;

            if (vm.acqProcedure.isReadOnly
                    || !vm.acqProcedure.editableSectionScheme[vm.INTEGRITY_FORM_SECTION.II]) {

                vm.isReadOnlyMode = true;
            }
        };

        /**
         * Intializeaza date Controller
         */
        vm.initData = function() {
            var serverRequestArray = [];
            serverRequestArray.push(FormProceduraAchizitieService.findById(vm.acqProcedureId));
            serverRequestArray.push(FormOfertantiService.findByAcqProcedureId(vm.acqProcedureId));
            serverRequestArray.push(FormProceduraAchizitieService.getEditableSubsectionTypeList(vm.acqProcedureId, vm.INTEGRITY_FORM_SECTION.II));

            vm.isLoadingData = true;
            $q.all(serverRequestArray).then(
                function success(responseArray) {
                    vm.acqProcedure = responseArray[0];
                    vm.checkIfReadonly();

                    vm.hasRoleManualAddTenderer = vm.hasRoleManualAddTenderer && vm.acqProcedure.isLocal;
                    vm.hasRoleManualAddTendererMember = vm.hasRoleManualAddTendererMember && vm.acqProcedure.isLocal;
                    vm.hasRoleManualAddTendererParticipant = vm.hasRoleManualAddTendererParticipant && vm.acqProcedure.isLocal;

                    vm.tendererList = responseArray[1];
                    vm.editableSubsectionTypeList = responseArray[2];
                    vm.isLoadingData = false;
                }, function(errMsg) {
                    vm.isLoadingData = false;
                    SeapAlertService.toastError("Eroare la aducere date!", "Eroare");
                }
            );
        };

        vm.initData();

        /**
         * Adauga/modifica un ofertant
         *
         * @param ofertant
         */
        vm.saveOfertant = function(ofertant) {
            if (ofertant) {
                $state.go('form-ofertanti-save', {proc: vm.acqProcedureId, ofertant: ofertant.tendererId, validationMessage: vm.validationMessage});
            } else {
                $state.go('form-ofertanti-save', {proc: vm.acqProcedureId});
            }
        };

        /**
         * Sterge un ofertant
         *
         * @param ofertant
         */
        vm.stergeOfertant = function(tenderer) {
            FormOfertantiService.delete(vm.acqProcedureId, tenderer.tendererId).then(
                function success(evalCommitteeResponse) {
                    vm.isLoadingData = false;
                    SeapAlertService.toastSuccess("Ofertantul a fost sters cu succes!", "Succes");
                    angular.forEach(vm.tendererList, function(tendererItem, index) {
                        if (tendererItem.tendererId === tenderer.tendererId) {
                            vm.tendererList.splice(index, 1);
                        }
                    });
                }, function error(errMsg) {
                    vm.isLoadingData = false;
                    SeapAlertService.toastError("Eroare la stergere date despre ofertant! Mesaj: " + errMsg, "Eroare");
                }
            );
        };

        // Adaugare Membru
        vm.saveMembru = function(tenderer, tendererAssocMember) {
            if (tendererAssocMember) {
                $state.go('form-ofertanti-save-membru', {
                    proc: vm.acqProcedureId,
                    ofertant: tenderer.tendererId,
                    membru: tendererAssocMember.tendererAssocMemberId,
                    validationMessage: vm.validationMessage});
            } else {
                $state.go('form-ofertanti-save-membru', {
                    proc: vm.acqProcedureId,
                    ofertant: tenderer.tendererId});
            }
        };

        // Sterge Membru Ofertant
        vm.deleteMembru = function(tenderer, tendererAssocMember) {
            TendererAssocMemberService.delete(vm.acqProcedureId, tendererAssocMember.tendererAssocMemberId).then(
                function success(tendererAssocMemberResponse) {
                    vm.isLoadingData = false;
                    SeapAlertService.toastSuccess("Membrul asociat ofertantului a fost sters cu succes!", "Succes");
                    angular.forEach(tenderer.tendererAssocMemberDTOs, function(tendererAssocMemberDTO, index) {
                        if (tendererAssocMemberDTO.tendererAssocMemberId === tendererAssocMember.tendererAssocMemberId) {
                            tenderer.tendererAssocMemberDTOs.splice(index, 1);
                        }
                    });
                }, function error(errMsg) {
                    vm.isLoadingData = false;
                    SeapAlertService.toastError("Eroare la stergere date despre pentru membrul selectat! Mesaj: " + errMsg, "Eroare");
                }
            );
        };

        // Adaugare Participant
        vm.saveParticipant = function(tenderer, tendererParticipant) {
            if (tendererParticipant) {
                $state.go('form-ofertanti-save-participant', {
                    proc: vm.acqProcedureId,
                    ofertant: tenderer.tendererId,
                    participant: tendererParticipant.tendererParticipantId,
                    validationMessage: vm.validationMessage});
            } else {
                $state.go('form-ofertanti-save-participant', {
                    proc: vm.acqProcedureId,
                    ofertant: tenderer.tendererId});
            }
        };

        // Sterge Participant
        vm.deleteParticipant = function(tenderer, tendererParticipant) {
            TendererParticipantService.delete(vm.acqProcedureId, tendererParticipant.tendererParticipantId).then(
                function success(tendererParticipantResponse) {
                    vm.isLoadingData = false;
                    SeapAlertService.toastSuccess("Participantul a fost sters cu succes!", "Succes");
                    angular.forEach(tenderer.tendererParticipantDTOs, function(tendererParticipantDTO, index) {
                        if (tendererParticipantDTO.tendererParticipantId === tendererParticipant.tendererParticipantId) {
                            tenderer.tendererParticipantDTOs.splice(index, 1);
                        }
                    });
                }, function error(errMsg) {
                    vm.isLoadingData = false;
                    SeapAlertService.toastError("Eroare la stergere date despre pentru participantul selectat! Mesaj: " + errMsg, "Eroare");
                }
            );
        };

        // Sincronizare date lista ofertanti din SICAP
        vm.syncTenderersFromSicap = function() {
            vm.isLoadingData = true;
            SicapSyncService.syncTendererList(vm.acqProcedureId).then(
                function success(response) {
                    vm.initData();
                    SeapAlertService.toastSuccess("Preluarea listei de ofertanti din aplicatia Noul SEAP s-a efectuat cu succes!");
                    vm.isLoadingData = false;
                },
                function error(errorMsg) {
                    SeapAlertService.toastError(errorMsg);
                    vm.isLoadingData = false;
                }
            );
        };

        // Sincronizare date lista membri pentru ofertant din SICAP
        vm.syncMemberListFromSicap = function(tenderer) {
            vm.isLoadingData = true;
            SicapSyncService.syncTendererAssocMemberList(vm.acqProcedureId, tenderer.tendererId).then(
                function success(response) {
                    vm.initData();
                    SeapAlertService.toastSuccess("Preluarea listei de membri pentru ofertant din aplicatia Noul SEAP s-a efectuat cu succes!");
                    vm.isLoadingData = false;
                },
                function error(errorMsg) {
                    SeapAlertService.toastError(errorMsg);
                    vm.isLoadingData = false;
                }
            );
        };

        // Sincronizare date lista participanti pentru ofertant din SICAP
        vm.syncParticipantListFromSicap = function(tenderer) {
            vm.isLoadingData = true;
            SicapSyncService.syncTendererParticipantList(vm.acqProcedureId, tenderer.tendererId).then(
                function success(response) {
                    vm.initData();
                    SeapAlertService.toastSuccess("Preluarea listei de participanti pentru ofertant din aplicatia Noul SEAP s-a efectuat cu succes!");
                    vm.isLoadingData = false;
                },
                function error(errorMsg) {
                    SeapAlertService.toastError(errorMsg);
                    vm.isLoadingData = false;
                }
            );
        };

    }
})();
