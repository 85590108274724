/**
 * Created by florin.iacob on 5/16/2017.
 */
(function() {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .factory('NaturalPersonService', NaturalPersonService);

    NaturalPersonService.$inject = ['$http','$q','API_BASE_URL', 'SeapAlertService', 'GenericService'];

    function NaturalPersonService ($http, $q, API_BASE_URL, SeapAlertService, GenericService) {

        var service = {};

        /**
         * Aducerea unei persoane fizice pe baza identificatorului specificat
         */
        service.findByIdentificator = function(cnp, pin) {
            var requestUrl = '/naturalPerson/findByIdentificator?';
            if (cnp) {
                requestUrl += 'cnp=' + cnp
            } else {
                requestUrl += 'pin=' + pin
            }
            return GenericService.GetFn(requestUrl);
        };

        return service;
    }
})();
