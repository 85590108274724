/**
 * Created by florin.iacob on 5/16/2017.
 */
(function() {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .controller('RaportFinalProceduraFormCtrl', RaportFinalProceduraFormCtrl);

    RaportFinalProceduraFormCtrl.$inject = ['$q', 'SeapAlertService', 'FormProceduraAchizitieService', '$scope', 'Principal', 'SicapSyncService'];

    function RaportFinalProceduraFormCtrl($q, SeapAlertService, FormProceduraAchizitieService, $scope, Principal, SicapSyncService) {

        var vm = this;

        /*
            acqProcedure: '=',
            isReadOnly: '='
        * */

        vm.acqProcedureId = vm.acqProcedure.acqProcedureId;

        vm.dataInitialized = false;
        vm.reportDate = new Date();
        vm.acqProcedureReportList = [];

        vm.hasRoleManualAddAcqProcedureReport = vm.acqProcedure.isLocal && Principal.hasAnyAuthority(['ROLE_MANUAL_ADD_ALL', 'ROLE_MANUAL_ADD_ACQ_PROCEDURE_REPORT']);
        vm.hasRoleExclusiveSyncAllSections = Principal.hasAnyAuthority(['ROLE_EXCLUSIVE_SYNC_ALL_SECTIONS']);

        vm.initData = function() {
            var serverRequestArray = [];
            serverRequestArray.push(FormProceduraAchizitieService.getAcqProcedureReportList(vm.acqProcedureId));

            $q.all(serverRequestArray).then(
                function success(responseArray) {
                    vm.acqProcedureReportList = responseArray[0];
                    if (!vm.acqProcedureReportList || !vm.acqProcedureReportList.length) {
                        vm.acqProcedureReportList = [];
                    }
                    vm.dataInitialized = true;
                }, function error(errMsg) {
                    SeapAlertService.toastError('Eroare la aducerea datelor despre Raport Final Procedura. Mesaj: ' + errMsg, 'Eroare');
                }
            );
        };

        if (vm.acqProcedureId) {
            vm.initData();
        }

        vm.check = function (data) {
            console.log(data);
        };

        // Adauga o noua data
        vm.addReportDate = function(reportDate) {
            var dataDeAdaugat = new Date(reportDate.getTime());
            vm.acqProcedureReportList.push({reportDate: dataDeAdaugat});
            $scope.formRaportFinalProcedura.$pristine = false;
        };

        // Sterge o data
        vm.deleteReportDate = function(index) {
            vm.acqProcedureReportList.splice(index, 1);
            $scope.formRaportFinalProcedura.$pristine = false;
        };

        // Editable
        vm.isRaportFinalFormEditable = false;
        vm.toggleRaportFinalFormEdit = function() {
            vm.isRaportFinalFormEditable = !vm.isRaportFinalFormEditable;
            if (vm.isRaportFinalFormEditable && !vm.dataInitialized) {
                vm.initData();
            }
        };

        /**
         * Salveaza informatiile depre fond UE
         */
        vm.saveRaportFinalForm = function() {
            FormProceduraAchizitieService.saveAcqProcedureReportList(vm.acqProcedureId, vm.acqProcedureReportList).then(
                function success() {
                    vm.isRaportFinalFormEditable = false;
                    SeapAlertService.toastSuccess('Datele despre Raport Final Procedura salvate cu succes!', 'Succes');
                    $scope.formRaportFinalProcedura.$pristine = true;
                }, function error(errorMsg) {
                    SeapAlertService.toastError('Eroare la salvarea datelor despre Raport Final Procedura. Mesaj: ' + errorMsg, 'Eroare');
                }
            );
        };

        // Sincronizare date raport final din SICAP
        vm.syncAcqProcedureReportFromSicap = function() {
            vm.isLoadingData = true;
            SicapSyncService.syncAcqProcedureReport(vm.acqProcedureId).then(
                function success(response) {
                    vm.initData();
                    SeapAlertService.toastSuccess("Preluarea datelor pentru raport final din Noul Seap s-a efectuat cu succes!");
                    vm.isLoadingData = false;
                },
                function error(errorMsg) {
                    SeapAlertService.toastError(errorMsg);
                    vm.isLoadingData = false;
                }
            );
        };

        // DATE PICKER
        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.dataRaportFinal = false;
        vm.openCalendar = function(date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
