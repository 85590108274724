/**
 * Created by florin.iacob on 5/16/2017.
 */
(function() {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .controller('FormAutoritateContractantaCtrl', FormAutoritateContractantaCtrl);

    FormAutoritateContractantaCtrl.$inject = ['$q', 'SeapAlertService', 'urlParams', '$location', 'AutoritateContractantaViewService'];

    function FormAutoritateContractantaCtrl($q, SeapAlertService, urlParams, $location, AutoritateContractantaViewService) {

        var vm = this;
        if (urlParams.prevState) {
            vm.prevState = urlParams.prevState;
        }
        vm.isEditMode = true;
        vm.isLoadingData = false;
        vm.isContractAuth = true;

        /**
         * Intializeaza date Controller
         */
        vm.initData = function() {
            vm.isLoadingData = true;
            var serverRequest = [];
            serverRequest.push(AutoritateContractantaViewService.findByCurrentUser());

            $q.all(serverRequest).then(
                function success(contractAuthRespArray) {
                    vm.contractAuth = contractAuthRespArray[0];
                    vm.isLoadingData = false;
                }, function error(errMsg) {
                    vm.isLoadingData = false;
                    SeapAlertService.toastError('Eroare la preluarea Autoritatii Contractante. Mesaj: ' + errMsg, 'Eroare');
                }
            );
        };

        vm.initData();

        /**
         * Function used to create or update Procurement Consultant
         */
        vm.saveContractAuthPF = function() {
            var deferred = $q.defer();

            var serverRequestArray = [];
            serverRequestArray.push(AutoritateContractantaViewService.updatePF(vm.contractAuth.naturalPersonDTO));

            $q.all(serverRequestArray).then(
                function success(responseArray) {
                    vm.contractAuth.naturalPersonDTO = responseArray[0];
                    vm.isEditMode = true;
                    deferred.resolve(responseArray);
                }, function error(errMsg) {
                    deferred.reject(errMsg);
                }
            );
            return deferred.promise;
        };

        vm.goBack = function() {
            $location.url(vm.prevState);
        }
    }
})();
