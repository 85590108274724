(function() {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .factory('SeapAuditsService', SeapAuditsService);

    SeapAuditsService.$inject = ['$http', '$q', 'API_BASE_URL', 'GenericService'];

    function SeapAuditsService ($http, $q, API_BASE_URL, GenericService) {
        var service = {};

        service.findByFilters = function(params, auditFilterDTO) {
            var deferred = $q.defer();
            // make server request
            $http({
                method: 'POST',
                url: 'management/audits/findByFilters',
                headers: {
                    'Content-Type': "application/json"
                },
                isArray: true,
                params: params,
                data: JSON.stringify(auditFilterDTO)
            }).then(
                function success(response) {
                    if (!response.data) {
                        deferred.reject('No data returned!');
                    }
                    deferred.resolve(response);

                }, function error(response) {
                    deferred.reject(response.data.message);
                    console.error(' -- REST POST Error: ' + '/management/audits/findByFilters');
                }
            );
            return deferred.promise;
        };

        service.getNomEventTypeList = function() {
            var deferred = $q.defer();
            // make server request
            $http({
                method: 'GET',
                url: 'management/audits/getNomEventTypeList',
                headers: {
                    'Content-Type': "application/json"
                }
            }).then(
                function success(response) {
                    if (!response.data) {
                        deferred.reject('No data returned!');
                    }
                    deferred.resolve(response.data);

                }, function error(response) {
                    deferred.reject(response.data.message);
                    console.error(' -- REST POST Error: ' + '/management/audits/getNomEventTypeList');
                }
            );
            return deferred.promise;
        };

        return service;
    }
})();
