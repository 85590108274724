(function() {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .controller('CreateContractCtrl', CreateContractCtrl);

    CreateContractCtrl.$inject = ['SeapAlertService', '$q', 'NomenclatorService', '$uibModalInstance', 'ContracteFormService',
        'AcqProcedureLotService', 'contractEntity', 'acqProcedureId', 'acqProcedure', 'Principal'];

    function CreateContractCtrl(SeapAlertService, $q, NomenclatorService, $uibModalInstance, ContracteFormService,
                                AcqProcedureLotService, contractEntity, acqProcedureId, acqProcedure, Principal) {
        var vm = this;

        vm.contractEntity = contractEntity;
        vm.clear = clear;
        vm.save = save;

        vm.selectedAcqProcedureLot = {id: null};

        vm.isEditMode = contractEntity ? true : false;

        if (vm.isEditMode) {
            vm.contract = contractEntity;
            vm.contract.contractDate = new Date(vm.contract.contractDate);
            vm.contract.publicationDate = new Date(vm.contract.publicationDate);
        } else {
            vm.contract = {
                contractId: null,
                contractUid: null,
                contractNumber: null,
                contractDate: null,
                publicationDate: null,
                acqProcLotContractDTOs: []
            };
        }

        vm.hasRoleManualAddContract = acqProcedure.isLocal ?  true : Principal.hasAnyAuthority(['ROLE_MANUAL_ADD_ALL', 'ROLE_MANUAL_ADD_CONTRACT']);

        /**
         * Initializeaza datele controller-ului
         */
        vm.initData = function() {
            vm.isLoadingData = false;
            vm.data = {};
            var serverRequestArray = [
                AcqProcedureLotService.findByAcqProcedureId(acqProcedureId)
            ];

            $q.all(serverRequestArray).then(
                function success(responseArray) {
                    vm.data.acqProcedureLotList = responseArray[0];
                    vm.isLoadingData = false;
                }, function error(errorMsg) {
                    SeapAlertService.toastError('Eroare la aducere date pentru Contract!');
                    vm.isLoadingData = false;
                }
            );
        };

        vm.initData();

        /**
         * Salveaza Contract
         */
        function save () {
            vm.isLoadingData = true;
            var serverRequestArray = [];
            if (vm.isEditMode) {
                serverRequestArray.push(ContracteFormService.update(acqProcedureId, vm.contract));
            } else {
                serverRequestArray.push(ContracteFormService.create(acqProcedureId, vm.contract));
            }

            $q.all(serverRequestArray).then(
                function success(responseArray) {
                    vm.contract.contractId = responseArray[0].contractId;
                    vm.contract.contractUid = responseArray[0].contractUid;
                    var finalAcqProcLotContractDTO = [];
                    angular.forEach(vm.contract.acqProcLotContractDTOs, function(acqProcLotContractDTO, index) {
                        if (!acqProcLotContractDTO.isDeleted) {
                            finalAcqProcLotContractDTO.push(acqProcLotContractDTO);
                        }
                    });
                    vm.contract.acqProcLotContractDTOs = finalAcqProcLotContractDTO;
                    $uibModalInstance.close(vm.contract);
                    SeapAlertService.toastSuccess('Contract salvat cu succes!', 'Succes');
                    vm.isLoadingData = false;
                }, function error(errorMsg) {
                    vm.isLoadingData = false;
                    SeapAlertService.toastError('Eroare la salvarea Contractului. Mesaj: ' + errorMsg, 'Eroare');
                }
            );
        }

        /**
         * Adauga un lot atasat Contractului
         * @param selectedAcqProcedureLotId
         */
        vm.addAcqProcedureLot = function(selectedAcqProcedureLotId) {

            if (!selectedAcqProcedureLotId) {
                return;
            }
            selectedAcqProcedureLotId = parseInt(selectedAcqProcedureLotId);
            // verificare daca Lotul este deja selectat
            var lotExistent = false;
            angular.forEach(vm.contract.acqProcLotContractDTOs, function(acqProcLotContractDTO, index) {
               if (acqProcLotContractDTO.acqProcedureLotDTO.acqProcedureLotId === selectedAcqProcedureLotId
                        && !acqProcLotContractDTO.isDeleted) {
                   lotExistent = true;
                   return;
               }
            });

            if (lotExistent) {
                SeapAlertService.toastError('Lotul selectat este deja prezent pentru acest contract!', 'Atentie!');
                return;
            }
            angular.forEach(vm.data.acqProcedureLotList, function(acqProcedureLot, index) {
                if (acqProcedureLot.acqProcedureLotId === selectedAcqProcedureLotId) {
                    vm.contract.acqProcLotContractDTOs.push(
                        {
                            acqProcLotContractId: null,
                            acqProcedureLotDTO: acqProcedureLot
                        }
                    );
                    return;
                }
            });
        };

        /**
         * Sterge un lot atasat Contractului
         * @param index
         */
        vm.deleteAcqProcedureLot = function(index) {
            if (vm.contract.acqProcLotContractDTOs[index].acqProcLotContractId) {
                vm.contract.acqProcLotContractDTOs[index].isDeleted = true;
            } else {
                vm.contract.acqProcLotContractDTOs.splice(index, 1);
            }
        };

        /**
         * Inchide fereastra
         */
        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        // DATE PICKER
        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.dataSemnareContract = false;
        vm.datePickerOpenStatus.dataPublicareContract = false;
        vm.openCalendar = function(date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
