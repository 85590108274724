/**
 * Created by florin.iacob on 5/16/2017.
 */
(function () {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .controller('IntegrityFormPrintCtrl', IntegrityFormPrintCtrl);

    IntegrityFormPrintCtrl.$inject = ['GenericService', '$q', 'SeapAlertService', 'urlParams', '$state', 'PublicationValidationService'];

    function IntegrityFormPrintCtrl(GenericService, $q, SeapAlertService, urlParams, $state, PublicationValidationService) {

        var vm = this;

        vm.isLoadingData = false;

        vm.idIntegrityForm = urlParams.idIntegrityForm;
        if (vm.idIntegrityForm) {
            vm.idIntegrityForm = parseInt(vm.idIntegrityForm);
        }
        vm.idAcqProcedure = urlParams.idAcqProcedure;
        if (vm.idAcqProcedure) {
            vm.idAcqProcedure = parseInt(vm.idAcqProcedure);
        }

        /**
         * Functie folosita pentru transformarea si afisarea documentului XML
         *
         * @param xml
         * @param xsl
         */
        function transformXMLAsHTML(xml, xsl) {
            // code for IE

            var isIE = (navigator.appName == 'Microsoft Internet Explorer' ||  !!(navigator.userAgent.match(/Trident/)
            || navigator.userAgent.match(/rv:11/)) || (typeof $.browser !== "undefined" && $.browser.msie == 1));

            if (window.ActiveXObject || isIE)
            {
                if (typeof (xml.transformNode) != "undefined") {
                    // IE6, IE7, IE8
                    var ex = xml.transformNode(xsl);
                    document.getElementById("transformResult").innerHTML = ex;
                } else {
                    // IE9 and grater
                    var xmlDoc = new ActiveXObject("Microsoft.XMLDOM");
                    xmlDoc.async = false;
                    var xmlAsString = new XMLSerializer().serializeToString(xml.documentElement);
                    xmlDoc.loadXML(xmlAsString);
                    var xslDoc = new ActiveXObject("MSXML2.FreeThreadedDOMDocument");
                    xslDoc.async = false;
                    xslDoc.load('app/_seap/entities/integrity-form-version/view/view-integrity-form-version.xsl');

                    var processor = new ActiveXObject("Msxml2.XSLTemplate");
                    processor.stylesheet = xslDoc;

                    var objXSLTProc = processor.createProcessor();
                    objXSLTProc.input = xmlDoc;
                    objXSLTProc.transform();

                    document.getElementById("transformResult").innerHTML = objXSLTProc.output;
                }
            }
            // code for Chrome, Firefox, Opera, etc.
            else if (document.implementation && document.implementation.createDocument)
            {
                var xsltProcessor = new XSLTProcessor();
                xsltProcessor.importStylesheet(xsl);
                var resultDocument = xsltProcessor.transformToFragment(xml, document);
                document.getElementById("transformResult").appendChild(resultDocument);
            }
        };

        /**
         * Functie aplicata pentru a transforma continutul XML in HTML
         */
        vm.applyXMLTransform = function() {
            vm.isLoadingData = true;

            var requestArray = [];
            requestArray.push(GenericService.GetFn('/publication/getXmlSnapshot/'+ vm.idIntegrityForm));
            requestArray.push(GenericService.GetAsXMLFn('app/_seap/entities/integrity-form-version/view/view-integrity-form-version.xsl'));

            $q.all(requestArray).then(
                function (responseArray) {
                    var xmlDoc = jQuery.parseXML(responseArray[0]);
                    transformXMLAsHTML(xmlDoc, responseArray[1]);
                    vm.isLoadingData = false;
                }, function (errMsg) {
                    if (errMsg) {
                        var errorDisplayed = false;
                        try {
                            var errorJson = JSON.parse(errMsg);
                            if (errorJson.errors && errorJson.errors.constructor === Array) {
                                PublicationValidationService.openPublicationValidationErrorsModal(errorJson.errors, vm.idAcqProcedure);
                                errorDisplayed = true;
                            }
                        } catch (e) {}
                        if (!errorDisplayed) {
                            SeapAlertService.toastError('Eroare la aducere date despre formular! Mesaj: ' + errMsg);
                        }
                    }
                    SeapAlertService.toastError('Doar formularele aflate intr-o stare valida de publicare pot fi IMPRIMATE!', 'Eroare');

                    vm.openIntegrityFormSave();
                    vm.isLoadingData = false;
                    console.error(" ERORI validare formular pentru IMPRIMARE (XML + XSL): " + errMsg);
                }
            );
        };

        vm.applyXMLTransform();

        /**
         * Intoarcere in lista de optiuni
         */
        vm.openIntegrityFormSave = function () {
            $state.go('integrity-form-save', {form: vm.idIntegrityForm});
        };

        /**
         * Functie apelata pentru printarea paginii accesate
         */
        vm.printPage = function() {
            window.print();
        }
    }
})();
