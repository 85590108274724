/**
 * Created by florin.iacob on 5/16/2017.
 */
(function() {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .factory('LegalPersonService', LegalPersonService);

    LegalPersonService.$inject = ['$http','$q','API_BASE_URL', 'SeapAlertService', 'GenericService'];

    function LegalPersonService ($http, $q, API_BASE_URL, SeapAlertService, GenericService) {

        var service = {};

        /**
         * Aducerea unei persoane juridice pe baza identificatorului specificat
         */
        service.findByIdentificator = function(cif, tin) {
            var requestUrl = '/legalPerson/findByIdentificator?';
            if (cif) {
                requestUrl += 'cif=' + cif
            } else {
                requestUrl += 'tin=' + tin
            }
            return GenericService.GetFn(requestUrl);
        };

        return service;
    }
})();
