/**
 * Created by florin.iacob on 5/16/2017.
 */
(function() {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('form-comisie-evaluare-listare', {
                parent: 'seap',
                url: '/form-comisie-evaluare-listare?proc&validationMessage',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'Comisie evaluare'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/_seap/entities/form-comisie-evaluare/listare/form-comisie-evaluare-listare.html',
                        controller: 'FormComisieEvaluareListareCtrl',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    proc: {
                        value: '',
                        squash: true
                    },
                    validationMessage: {
                        value: '',
                        squash: true
                    }
                },
                resolve: {
                    urlParams: ['$stateParams', function ($stateParams) {
                        return {
                            acqProcedureId: $stateParams.proc,
                            validationMessage: $stateParams.validationMessage
                        };
                    }]
                }
            })
            .state('form-comisie-evaluare-save-membru', {
                parent: 'seap',
                url: '/form-comisie-evaluare-save-membru?proc&comisie&membru&validationMessage',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'Salveaza membru comisie'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/_seap/entities/form-comisie-evaluare/save-membru/form-comisie-evaluare-save-membru.html',
                        controller: 'FormComisieEvaluareSaveMembruCtrl',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    proc: {
                        value: '',
                        squash: true
                    },
                    comisie: {
                        value: '',
                        squash: true
                    },
                    membru: {
                        value: '',
                        squash: true
                    },
                    validationMessage: {
                        value: '',
                        squash: true
                    }
                },
                resolve: {
                    urlParams: ['$stateParams', function ($stateParams) {
                        return {
                            acqProcedureId: $stateParams.proc,
                            evalCommitteeId: $stateParams.comisie,
                            evalCommitteeMemberId: $stateParams.membru,
                            validationMessage: $stateParams.validationMessage
                        };
                    }]
                }
            })
            .state('form-comisie-evaluare-save-expert', {
                parent: 'seap',
                url: '/form-comisie-evaluare-save-expert?proc&comisie&expert&validationMessage',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'Salveaza expert comisie'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/_seap/entities/form-comisie-evaluare/save-expert/form-comisie-evaluare-save-expert.html',
                        controller: 'FormComisieEvaluareSaveExpertCtrl',
                        controllerAs: 'vm'
                    }
                },
                params: {
                    proc: {
                        value: '',
                        squash: true
                    },
                    comisie: {
                        value: '',
                        squash: true
                    },
                    expert: {
                        value: '',
                        squash: true
                    },
                    validationMessage: {
                        value: '',
                        squash: true
                    }
                },
                resolve: {
                    urlParams: ['$stateParams', function ($stateParams) {
                        return {
                            acqProcedureId: $stateParams.proc,
                            evalCommitteeId: $stateParams.comisie,
                            evalCommitteeExpertId: $stateParams.expert,
                            validationMessage: $stateParams.validationMessage
                        };
                    }]
                }
            });
    }

})();
