/**
 * Created by florin.iacob on 5/16/2017.
 */
(function() {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .controller('AlegeCategorieAutoritateCtrl', AlegeCategorieAutoritateCtrl);

    AlegeCategorieAutoritateCtrl.$inject = ['$q', 'SeapAlertService', 'NomenclatorService', 'AutoritateContractantaViewService','$scope'];

    function AlegeCategorieAutoritateCtrl($q, SeapAlertService, NomenclatorService, AutoritateContractantaViewService, $scope) {

        var vm = this;
        /*
            formName: '@',
            isReadOnly: '='
        */

        vm.isLoadingData = false;
        vm.selectedNomContractAuthCategId;
        vm.selectedContractAuthCategDtoList = [];

        /**
         * Initializeaza date Controller
         */
        vm.initData = function() {
            var serverRequestArray = [];
            serverRequestArray.push(NomenclatorService.findByKey('NomContractAuthCateg'));
            serverRequestArray.push(AutoritateContractantaViewService.findContractAuthCategDtoList());

            vm.data = {nomenclatorCategorieAutoritateList: []};
            vm.isLoadingData = true;
            $q.all(serverRequestArray).then(
                function success(responseArray) {
                    vm.data.nomenclatorCategorieAutoritateList = responseArray[0];
                    vm.selectedContractAuthCategDtoList = responseArray[1];
                    vm.isLoadingData = false;
                }, function error(errMsg) {
                    vm.isLoadingData = false;
                    SeapAlertService.toastError("Eroare aducere date pentru alegere categorie autoritate!");
                }
            );
        };

        vm.initData();


        // Editable
        vm.isAlegeCategorieAutoritateFormEditable = false;
        vm.toggleCategorieAutoritateFormEdit = function() {
            vm.isAlegeCategorieAutoritateFormEditable = !vm.isAlegeCategorieAutoritateFormEditable;
        };

        /**
         * Salveaza informatiile depre formular Lista categorii autoritate contractanta
         */
        vm.saveContractAuthCategDtoList = function() {
            var serverRequestArray = [];
            serverRequestArray.push(AutoritateContractantaViewService.updateContractAuthCategDtoList(vm.selectedContractAuthCategDtoList));

            vm.isLoadingData = true;
            $q.all(serverRequestArray).then(
                function success(responseArray) {
                    vm.selectedContractAuthCategDtoList = responseArray[0];
                    vm.isAlegeCategorieAutoritateFormEditable = false;
                    vm.isLoadingData = false;
                    $scope.formAlegeCategorieAutoritate.$setPristine();
                    SeapAlertService.toastSuccess("Salvare cu succes!");
                }, function error(errMsg) {
                    vm.isLoadingData = false;
                    SeapAlertService.toastError("Eroare la salvare date despre categorie autoritate! Mesaj eroare: " + errMsg);
                }
            );
        };

        // Adauga un nou NomContractAuthCateg
        vm.addNomContractAuthCateg = function(selectedNomContractAuthCategId) {
            var existent = false;
            angular.forEach(vm.selectedContractAuthCategDtoList, function(entityDTO, index) {
                if (entityDTO.nomContractAuthCategDTO.id === parseInt(selectedNomContractAuthCategId)) {
                    existent = true;
                    return;
                }
            });

            if (existent) {
                SeapAlertService.toastError('Categorie deja adaugata!');
            } else {
                angular.forEach(vm.data.nomenclatorCategorieAutoritateList, function (nomEntityDTO, index) {
                    if (nomEntityDTO.id === parseInt(selectedNomContractAuthCategId)) {
                        vm.selectedContractAuthCategDtoList.push({nomContractAuthCategDTO: nomEntityDTO});
                        $scope.formAlegeCategorieAutoritate.$pristine = false;
                        return;
                    }
                });
            }

        };

        // Sterge o inregistrare NomContractAuthCateg
        vm.deleteNomContractAuthCateg = function(selectedEntityDTO) {
            $scope.formAlegeCategorieAutoritate.$pristine = false;
            angular.forEach(vm.selectedContractAuthCategDtoList, function(entityDTO, index) {
                if (entityDTO.nomContractAuthCategDTO.id === selectedEntityDTO.nomContractAuthCategDTO.id) {
                    vm.selectedContractAuthCategDtoList.splice(index, 1);
                    return;
                }
            });
        };
    }
})();
