/**
 * Created by florin.iacob on 5/16/2017.
 */
(function() {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .controller('FormConsultantiDocumentatieSaveAngajatorCtrl', FormConsultantiDocumentatieSaveAngajatorCtrl);

    FormConsultantiDocumentatieSaveAngajatorCtrl.$inject = ['$q', 'SeapAlertService', 'urlParams', 'FormProceduraAchizitieService', 'ProcConsultantEmployerService', 'Constants', 'FormConsultantiDocumentatieService'];

    function FormConsultantiDocumentatieSaveAngajatorCtrl($q, SeapAlertService, urlParams, FormProceduraAchizitieService, ProcConsultantEmployerService, Constants, FormConsultantiDocumentatieService) {

        var vm = this;
        if (urlParams.idProcedura) {
            vm.acqProcedureId = parseInt(urlParams.idProcedura);
        }
        if (urlParams.idConsultant) {
            vm.procurementConsultantId = parseInt(urlParams.idConsultant);
        }
        vm.isEditMode = false;
        if (urlParams.idEmployer) {
            vm.isEditMode = true;
            vm.employerId = parseInt(urlParams.idEmployer);
        }
        vm.validationMessage = urlParams.validationMessage;

        vm.isLoadingData = false;

        vm.INTEGRITY_FORM_SECTION = Constants.INTEGRITY_FORM_SECTION;

        /**
         * Verificare daca formularul este doar in modul de citire date, dara editare
         */
        vm.checkIfReadonly = function() {
            vm.isReadOnlyMode = false;

            if (vm.acqProcedure.isReadOnly
                || !vm.acqProcedure.editableSectionScheme[vm.INTEGRITY_FORM_SECTION.I_3]) {

                vm.isReadOnlyMode = true;
            }
        };

        /**
         * Intializeaza date Controller
         */
        vm.initData = function() {
            var serverRequestArray = [];
            serverRequestArray.push(FormProceduraAchizitieService.findById(vm.acqProcedureId));
            serverRequestArray.push(FormConsultantiDocumentatieService.findById(vm.procurementConsultantId));
            if (vm.isEditMode) {
                serverRequestArray.push(ProcConsultantEmployerService.findById(vm.employerId));
            }

            vm.isLoadingData = true;
            $q.all(serverRequestArray).then(
                function success(responseArray) {
                    vm.acqProcedure = responseArray[0];
                    vm.procurementConsultant = responseArray[1];

                    if (vm.isEditMode) {
                        vm.employer = responseArray[2];
                    } else {
                        vm.employer = {
                        };
                    }
                    vm.checkIfReadonly();
                    vm.isLoadingData = false;
                }, function(errMsg) {
                    vm.isLoadingData = false;
                    SeapAlertService.toastError("Eroare la aducere date!", "Eroare");
                }
            );
        };

        vm.initData();

        /**
         * Function used to create or update Procurement Consultant
         */
        vm.saveEmployer = function() {
            var deferred = $q.defer();

            var serverRequestArray = [];
            if (vm.isEditMode) {
                serverRequestArray.push(ProcConsultantEmployerService.update(vm.acqProcedureId, vm.employer));
            } else {
                serverRequestArray.push(ProcConsultantEmployerService.create(vm.acqProcedureId, vm.procurementConsultantId, vm.employer));
            }

            $q.all(serverRequestArray).then(
                function success(responseArray) {
                    vm.employer = responseArray[0];
                    vm.isEditMode = true;
                    deferred.resolve(responseArray);
                }, function error(errMsg) {
                    deferred.reject(errMsg);
                }
            );
            return deferred.promise;
        };
    }
})();
