/**
 * Created by florin.iacob on 5/16/2017.
 */
(function() {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .component('filtreCautareProceduri', {
            templateUrl: 'app/_seap/components/filtre-cautare-proceduri/filtre-cautare-proceduri.html',
            controller: 'FiltreCautareProceduriCtrl',
            controllerAs: 'vm',
            bindings: {
                isFilterOpen: '=',
                onApplyFilter: '&'
            }
        });
})();
