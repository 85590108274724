/**
 * Created by florin.iacob on 6/8/2017.
 */
(function() {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .factory('FormFactoriDecizionaliService', FormFactoriDecizionaliService);

    FormFactoriDecizionaliService.$inject = ['$http','$q','API_BASE_URL','GenericService'];

    function FormFactoriDecizionaliService ($http, $q, API_BASE_URL, GenericService) {

        var service = {};

        /**
         * Aducerea listei de Factori Decizionali pentru Procedura de Achzitie specificata
         */
        service.findByAcqProcedureId = function(acqProcedureId) {
            var deferred = $q.defer();
            // make server request
            $http({
                method: 'GET',
                url: API_BASE_URL + '/decisionMaker/findByAcqProcedureId/' + acqProcedureId,
                headers: {
                    'Content-Type': "application/json"
                }
            }).then(
                function success(response) {
                    if (!response.data) {
                        deferred.reject('No data returned!');
                    }
                    deferred.resolve(response.data);

                }, function error(response) {
                    deferred.reject(response.data.message);
                    console.error(' -- REST call Error: ' + API_BASE_URL + '/decisionMaker/findByAcqProcedureId/' + acqProcedureId);
                }
            );
            return deferred.promise;
        };

        /**
         * Aduce un Factor Decizional pe baza ID-ului specificat
         */
        service.findById = function(decisionMakerId) {
            var deferred = $q.defer();
            // make server request
            $http({
                method: 'GET',
                url: API_BASE_URL + '/decisionMaker/findById/' + decisionMakerId,
                headers: {
                    'Content-Type': "application/json"
                }
            }).then(
                function success(response) {
                    if (!response.data) {
                        deferred.reject('No data returned!');
                    }
                    deferred.resolve(response.data);

                }, function error(response) {
                    deferred.reject(response.data.message);
                    console.error(' -- REST call Error: ' + API_BASE_URL + '/decisionMaker/findById/' + decisionMakerId);
                }
            );
            return deferred.promise;
        };

        /**
         * Creaza un Factor Decizional pentru o Procedura de Achizitie
         */
        service.create = function(acqProcedureId, decisionMakerDTO) {
            var deferred = $q.defer();
            // make server request
            $http({
                method: 'POST',
                url: API_BASE_URL + '/decisionMaker/create/' + acqProcedureId,
                headers: {
                    'Content-Type': "application/json"
                },
                data: JSON.stringify(decisionMakerDTO)
            }).then(
                function success(response) {
                    deferred.resolve(response.data);

                }, function error(response) {
                    var errMsg = 'Eroare la salvarea Factorului Decizional!';
                    if (response.data && response.data.message) {
                        errMsg = response.data.message;
                    }
                    deferred.reject(errMsg);
                    console.error(' -- REST POST call Error: /decisionMaker/create/' + acqProcedureId);
                }
            );
            return deferred.promise;
        };

        /**
         * Actualizeaza un Factor Decizional pentru o Procedura de Achizitie
         */
        service.update = function(acqProcedureId, decisionMakerDTO) {
            var deferred = $q.defer();
            // make server request
            $http({
                method: 'PUT',
                url: API_BASE_URL + '/decisionMaker/update/' + acqProcedureId,
                headers: {
                    'Content-Type': "application/json"
                },
                data: JSON.stringify(decisionMakerDTO)
            }).then(
                function success(response) {
                    deferred.resolve(response.data);

                }, function error(response) {
                    var errMsg = 'Eroare la salvarea Factorului Decizional!';
                    if (response.data && response.data.message) {
                        errMsg = response.data.message;
                    }
                    deferred.reject(errMsg);
                    console.error(' -- REST POST call Error: /decisionMaker/update/' + acqProcedureId);
                }
            );
            return deferred.promise;
        };

        /**
         * Sterge un Factor decizional pentru o Procedura de Achizitie
         */
        service.delete = function(acqProcedureId, decisionMakerId) {
            var deferred = $q.defer();
            // make server request
            $http({
                method: 'DELETE',
                url: API_BASE_URL + '/decisionMaker/delete/' + acqProcedureId + "/" + decisionMakerId,
                headers: {
                    'Content-Type': "application/json"
                }
            }).then(
                function success(response) {
                    deferred.resolve(response.data);

                }, function error(response) {
                    var errMsg = 'Eroare la stergerea Factorului decizional!';
                    if (response.data && response.data.message) {
                        errMsg = response.data.message;
                    }
                    deferred.reject(errMsg);
                    console.error(' -- REST POST call Error: /decisionMaker/delete/' + acqProcedureId + "/" + decisionMakerId);
                }
            );
            return deferred.promise;
        };

        /**
         * Preluare lista de factori decizionali de la procedura sursa la procedura destinatie
         */
        service.preluareFactori = function(sourceAcqProcedureId, destinationAcqProcedureId) {
            return GenericService.PostFn('/decisionMaker/duplicateDecisionMakerList/' + sourceAcqProcedureId + '/' + destinationAcqProcedureId);
        };

        return service;
    }
})();
