/**
 * Created by florin.iacob on 5/22/2017.
 */
(function() {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .factory('NomenclatorService', NomenclatorService);

    NomenclatorService.$inject = ['$q', '$http', 'API_BASE_URL'];

    function NomenclatorService($q, $http, API_BASE_URL) {

        var Service = {};

        /**
         * Aduce lista de elemente pentru nomenclatorul cu cheia specificata
         *
         * @param nomenclatorKey
         */
        Service.findByKey = function(nomenclatorKey) {
            var deferred = $q.defer();
            // make server request
            $http({
                method: 'GET',
                url: API_BASE_URL + '/nomenclator/findByKey/' + nomenclatorKey,
                headers: {
                    'Content-Type': "application/json"
                }
            }).then(
                function success(response) {
                    if (!response.data) {
                        deferred.reject('No data returned!');
                    }
                    deferred.resolve(response.data);

                }, function error(response) {
                    deferred.reject(response.data.message);
                    console.error(' -- REST call Error: ' + API_BASE_URL + '/nomenclator/findByKey/' + nomenclatorKey);
                }
            );
            return deferred.promise;
        };

        return Service
    }
})();

