/**
 * Created by florin.iacob on 5/16/2017.
 */
(function() {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .controller('AlegeOfertantiFormCtrl', AlegeOfertantiFormCtrl);

    AlegeOfertantiFormCtrl.$inject = ['$q', 'SeapAlertService', 'DecisionMakerWorkRelService', 'EvalCmteMemberWorkRelService', '$scope', 'FormOfertantiService'];

    function AlegeOfertantiFormCtrl($q, SeapAlertService, DecisionMakerWorkRelService, EvalCmteMemberWorkRelService, $scope, FormOfertantiService) {

        var vm = this;
        /*
            acqProcedureId: '=',
            parentId: '=',
            parentName: '@',
            isReadOnly: '='
         */
        var entityName;

        vm.initData = function() {
            var serverRequestsArray = [
                FormOfertantiService.findByAcqProcedureId(vm.acqProcedureId)
            ];
            if (vm.parentId) {
                if (vm.parentName == 'DecisionMaker') {
                    serverRequestsArray.push(DecisionMakerWorkRelService.findByDecisionMakerId(vm.parentId));
                    entityName = 'decisionMaker';
                } else if (vm.parentName ==  'EvalCmteMember') {
                    serverRequestsArray.push(EvalCmteMemberWorkRelService.findByEvalCmteMemberId(vm.parentId));
                    entityName = 'evalCmteMember';
                } else {
                    SeapAlertService.toastError("Nu pot fi aduse date depre ofertanti!");
                    return;
                }
            }

            vm.data = {};

            // Aducere date Server
            vm.isLoadingData = true;
            $q.all(serverRequestsArray).then(
                function success(responseArray) {
                    vm.data.tendererDTOList = responseArray[0];

                    if (vm.parentId) {
                        vm.entityWorkRelDTOList = responseArray[1];
                    } else {
                        vm.entityWorkRelDTOList = [];
                    }

                    vm.isLoadingData = false;
                },
                function error(error) {
                    SeapAlertService.toastError('Eroare la aducere date despre Ofertanti! Mesaj: ' + error);
                    vm.isLoadingData = false;
                }
            );
        };

        vm.initData();

        vm.selectedWorkRelId = null;

        // Adauga un nou WorkRel
        vm.addWorkRel = function(selectedWorkRelId) {
            var existent = false;
            angular.forEach(vm.entityWorkRelDTOList, function(entityWorkRelDTO, index) {
                if (!entityWorkRelDTO.isDeleted && entityWorkRelDTO.legalPersonDTO.legalPersonId === parseInt(selectedWorkRelId)) {
                    existent = true;
                    return;
                }
            });

            if (existent) {
                SeapAlertService.toastError('Ofertant deja existent!');
            } else {
                angular.forEach(vm.data.tendererDTOList, function (tendererDTO, index) {
                    if (tendererDTO.legalPersonDTO.legalPersonId === parseInt(selectedWorkRelId)) {
                        vm.entityWorkRelDTOList.push({
                            legalPersonDTO: tendererDTO.legalPersonDTO
                        });
                        $scope.formAlegeOfertanti.$pristine = false;
                        return;
                    }
                });
            }

        };

        // Sterge o WorkRel
        vm.deleteWorkRel = function(selectedEntityWorkRelDTO) {
            $scope.formAlegeOfertanti.$pristine = false;
            angular.forEach(vm.entityWorkRelDTOList, function(entityWorkRelDTO, index) {
                if (selectedEntityWorkRelDTO[entityName + "WorkRelId"]) {
                    if (entityWorkRelDTO[entityName + "WorkRelId"] === selectedEntityWorkRelDTO[entityName + "WorkRelId"]) {
                        vm.entityWorkRelDTOList[index].isDeleted = true;
                        return;
                    }
                } else {
                    if (entityWorkRelDTO.legalPersonDTO.legalPersonId === selectedEntityWorkRelDTO.legalPersonDTO.legalPersonId) {
                        vm.entityWorkRelDTOList.splice(index, 1);
                        return;
                    }
                }
            });
        };

        // Editable
        vm.isAlegeOfertantiFormEditable = false;
        vm.toggleAlegeOfertantiFormEdit = function() {
            vm.isAlegeOfertantiFormEditable = !vm.isAlegeOfertantiFormEditable;
        };

        /**
         * Salveaza informatiile depre formular Alegere Ofertanti
         */
        vm.saveAlegeOfertantiForm = function() {
            var serverRequestArray = [];
            if (vm.parentName == 'DecisionMaker') {
                serverRequestArray.push(DecisionMakerWorkRelService.save(vm.parentId, vm.entityWorkRelDTOList));
            } else if (vm.parentName ==  'EvalCmteMember') {
                serverRequestArray.push(EvalCmteMemberWorkRelService.save(vm.parentId, vm.entityWorkRelDTOList));
            }

            vm.isLoadingData = true;
            $q.all(serverRequestArray).then(
                function success(responseArray) {
                    vm.entityWorkRelDTOList = responseArray[0];
                    vm.isAlegeOfertantiFormEditable = false;
                    vm.isLoadingData = false;
                    $scope.formAlegeOfertanti.$pristine = true;
                    SeapAlertService.toastSuccess("Lista de ofertanti salvata cu succes!", "Succes");

                }, function error(errMsg) {
                    vm.isLoadingData = false;
                    SeapAlertService.toastError("Eroare la salvare lista de ofertanti! Mesaj: " + errMsg, "Eroare");
                }
            );
        };
    }
})();
