(function() {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .controller('StatisticsIntegrityFormsController', StatisticsIntegrityFormsController);

    StatisticsIntegrityFormsController.$inject = ['$scope', '$q', 'StatisticsIntegrityFormsService', 'SeapAlertService'];

    function StatisticsIntegrityFormsController ($scope, $q, StatisticsIntegrityFormsService, SeapAlertService) {
        var vm = this;

        vm.isLoadingData = false;

        var date = new Date();
        vm.startDate = new Date(date.getFullYear(), date.getMonth(), 1);
        //vm.startDate = new Date(new Date().getTime() - (30 * 24 * 60 * 60 * 1000));
        vm.endDate = new Date();

        /**
         * Intializeaza date Controller
         */
        vm.initData = function() {
            var serverRequestArray = [];
            var startTime = vm.startDate ? vm.startDate.getTime() : null;
            var endTime = vm.endDate ? vm.endDate.getTime() : null;
            serverRequestArray.push(StatisticsIntegrityFormsService.getStatisticsForPeriod(startTime, endTime));

            vm.isLoadingData = true;
            $q.all(serverRequestArray).then(
                function success(responseArray) {
                    vm.statisticsIntegrityFormsDTO = responseArray[0];
                    vm.isLoadingData = false;
                }, function(errMsg) {
                    vm.isLoadingData = false;
                    SeapAlertService.toastError("Eroare la aducere statistici!", "Eroare");
                }
            );
        };

        vm.initData();

        /**
         * Aplica selectia de perioada pentru prezentarea statisticilor
         */
        vm.aplicaSelectie = function() {
            vm.initData();
        };

        // DATE PICKER
        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.startDate = false;
        vm.datePickerOpenStatus.endDate = false;
        vm.openCalendar = function(date) {
            vm.datePickerOpenStatus[date] = true;
        }

    }
})();
