/**
 * Created by florin.iacob on 5/16/2017.
 */
(function() {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .component('alegeLoturiCastigateForm', {
            templateUrl: 'app/_seap/components/alege-loturi-castigate-form/alege-loturi-castigate-form.html',
            controller: 'AlegeLoturiCastigateFormCtrl',
            controllerAs: 'vm',
            bindings: {
                acqProcedureId: '=',
                parentId: '=',
                parentName: '@',
                contractTendererList: '=',
                isWinner: '=',
                isReadOnly: '='
            }
        });
})();
