/**
 * Created by florin.iacob on 5/22/2017.
 */
(function() {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .factory('PublicationValidationService', PublicationValidationService);

    PublicationValidationService.$inject = ['$q', '$http', '$uibModal', '$state', 'API_BASE_URL', 'SeapAlertService'];

    function PublicationValidationService($q, $http, $uibModal, $state, API_BASE_URL, SeapAlertService) {

        var Service = {};

        /**
         * Aduce lista de elemente pentru nomenclatorul cu cheia specificata
         *
         * @param nomenclatorKey
         */
        Service.op = function (integrityFormId, op) {
            var deferred = $q.defer();
            // make server request
            $http({
                method: 'GET',
                url: API_BASE_URL + '/publication/' + op,
                headers: {
                    'Content-Type': "application/json"
                },
                params: {
                    integrityFormId: integrityFormId
                }
            }).then(
                function success(response) {
                    if (!response.data) {
                        deferred.reject('No data returned!');
                    }
                    deferred.resolve(response.data);

                }, function error(response) {
                    deferred.reject(response.data.message);
                }
            );
            return deferred.promise;
        };

        // vizualizare erori de validare la publicare pentru formularul de integritate
        Service.openPublicationValidationErrorsModal = function (errors, acqProcedureId) {
            $uibModal.open({
                templateUrl: 'app/_seap/modals/publication/publication-validation-errors.html',
                controller: 'PublicationValidationErrorsCtrl',
                controllerAs: 'vm',
                backdrop: 'static',
                size: 'lg',
                resolve: {
                    errors: function () {
                        return errors;
                    },
                    acqProcedureId: function() {
                        return acqProcedureId;
                    }
                }
            }).result.then(
                function success(responseLot) {
                    console.info(' -- ok PublicationValidationErrors');
                }, function error(response) {
                    console.error(' -- err PublicationValidationErrors');
                }
            );
        };

        /**
         * Crearea link-ului catre pagina unde trebuie rezolvata eroarea de validare
         *
         * @param errorObject
         * @param acqProcedureId
         */
        Service.goToStateWithValidationError = function(errorObject, acqProcedureId) {
            if (errorObject.completeMessage) {
                if (errorObject.completeMessage.indexOf('I.1') > -1) {
                    $state.go('form-procedura-achizitie', {proc: acqProcedureId, validationMessage: errorObject.completeMessage});
                } else if (errorObject.completeMessage.indexOf('I.2') > -1) {
                    $state.go('form-factori-decizionali-lista', {proc: acqProcedureId, validationMessage: errorObject.completeMessage});
                } else if (errorObject.completeMessage.indexOf('I.3') > -1) {
                    $state.go('form-consultanti-documentatie-lista', {proc: acqProcedureId, validationMessage: errorObject.completeMessage});
                } else if (errorObject.completeMessage.indexOf('I.4') > -1) {
                    $state.go('form-comisie-evaluare-listare', {proc: acqProcedureId, validationMessage: errorObject.completeMessage});
                } else if (errorObject.completeMessage.indexOf('II') > -1) {
                    $state.go('form-ofertanti-listare', {proc: acqProcedureId, validationMessage: errorObject.completeMessage});
                } else {
                    console.error(" -- Sectiune invalida {" + errorObject.completeMessage + "}");
                    SeapAlertService.toastError('Sectiune invalida! (' + errorObject.completeMessage + ')');
                }
            } else {
                console.error(" -- Nu se poate accesa pagina cu eroare de validare! {" + JSON.stringify(errorObject) + "}");
                SeapAlertService.toastError('Nu se poate accesa pagina cu eroare de validare!');
            }

        };

        var validationTrigger = false;
        Service.setValidationTrigger = function() {
            validationTrigger = true;
        };
        Service.unsetValidationTrigger = function() {
            validationTrigger = false;
        };
        Service.isValidationTriggerActive = function() {
            return validationTrigger;
        };

        return Service
    }
})();

