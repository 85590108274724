/**
 * Created by florin.iacob on 5/16/2017.
 */
(function() {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .controller('FormComisieEvaluareListareCtrl', FormComisieEvaluareListareCtrl);

    FormComisieEvaluareListareCtrl.$inject = ['$q', '$state', 'SeapAlertService', 'urlParams', 'FormProceduraAchizitieService', 'FormComisieEvaluareService',
        'AcqProcedureLotService', 'EvalCommitteMemberService', 'EvalCommitteExpertService', 'Constants', 'IntegrityWarningService', 'Principal'];

    function FormComisieEvaluareListareCtrl($q, $state, SeapAlertService, urlParams, FormProceduraAchizitieService, FormComisieEvaluareService,
                                            AcqProcedureLotService, EvalCommitteMemberService, EvalCommitteExpertService, Constants, IntegrityWarningService, Principal) {

        var vm = this;
        if (urlParams.acqProcedureId) {
            vm.acqProcedureId = parseInt(urlParams.acqProcedureId);
        }
        vm.validationMessage = urlParams.validationMessage;
        vm.isLoadingData = false;

        vm.INTEGRITY_FORM_SECTION = Constants.INTEGRITY_FORM_SECTION;
        vm.INTEGRITY_FORM_SUBSECTION = Constants.INTEGRITY_FORM_SUBSECTION;

        vm.isAC = Principal.hasAnyAuthority(['ROLE_AC']);
        vm.isOpSeap = Principal.hasAnyAuthority(['ROLE_OP_SEAP']);

        /**
         * Verificare daca formularul este doar in modul de citire date, dara editare
         */
        vm.checkIfReadonly = function() {
            vm.isReadOnlyMode = false;

            if (vm.acqProcedure.isReadOnly
                || !vm.acqProcedure.editableSectionScheme[vm.INTEGRITY_FORM_SECTION.I_4]) {

                vm.isReadOnlyMode = true;
            }
        };

        /**
         * Se va face asocierea avertismentelor pentru ultima versiune cu persoanele din lista
         */
        vm.asociereAvertismenteCuPersoane = function() {
            if (vm.warningList && vm.warningList.length) {
                angular.forEach(vm.warningList, function(warningItem, warningIndex) {
                    if (warningItem.identificatorDtoList && warningItem.identificatorDtoList.length) {
                        angular.forEach(warningItem.identificatorDtoList, function(identificatorDtoItem, identificatorDtoIndex) {
                            angular.forEach(vm.evalComiteeList, function(evalComiteeItem, evalComiteeIndex) {
                                // lista membri
                                angular.forEach(evalComiteeItem.evalCommitteeMemberDTOs, function(memberItem, memberIndex) {
                                    var hasWarning = false;
                                    if (identificatorDtoItem.cnp) {
                                        if (identificatorDtoItem.cnp === memberItem.naturalPersonDTO.cnp) {
                                            hasWarning = true;
                                        }
                                    } else {
                                        if (identificatorDtoItem.pin === memberItem.naturalPersonDTO.pin) {
                                            hasWarning = true;
                                        }
                                    }
                                    if (hasWarning) {
                                        vm.evalComiteeList[evalComiteeIndex].evalCommitteeMemberDTOs[memberIndex].warningDesc = vm.warningList[warningIndex].warningDesc;
                                        vm.evalComiteeList[evalComiteeIndex].evalCommitteeMemberDTOs[memberIndex].warningDate = vm.warningList[warningIndex].warningDate;
                                    }
                                });
                                // lista experti
                                angular.forEach(evalComiteeItem.evalCommitteeExpertDTOs, function(expertItem, expertIndex) {
                                    var hasWarning = false;
                                    if (identificatorDtoItem.cnp) {
                                        if (identificatorDtoItem.cnp === expertItem.naturalPersonDTO.cnp) {
                                            hasWarning = true;
                                        }
                                    } else {
                                        if (identificatorDtoItem.pin === expertItem.naturalPersonDTO.pin) {
                                            hasWarning = true;
                                        }
                                    }
                                    if (hasWarning) {
                                        vm.evalComiteeList[evalComiteeIndex].evalCommitteeExpertDTOs[expertIndex].warningDesc = vm.warningList[warningIndex].warningDesc;
                                        vm.evalComiteeList[evalComiteeIndex].evalCommitteeExpertDTOs[expertIndex].warningDate = vm.warningList[warningIndex].warningDate;
                                    }
                                });
                            });
                        });
                    }
                });
            }
        };

        /**
         * Intializeaza date Controller
         */
        vm.initData = function() {
            var serverRequestArray = [];
            serverRequestArray.push(FormProceduraAchizitieService.findById(vm.acqProcedureId));
            serverRequestArray.push(AcqProcedureLotService.findByAcqProcedureId(vm.acqProcedureId));
            serverRequestArray.push(FormComisieEvaluareService.findByAcqProcedureId(vm.acqProcedureId));
            serverRequestArray.push(FormProceduraAchizitieService.getEditableSubsectionTypeList(vm.acqProcedureId, vm.INTEGRITY_FORM_SECTION.I_4));
            if (vm.isAC || vm.isOpSeap) {
                serverRequestArray.push(IntegrityWarningService.getWarningListForLastVersion(null, vm.acqProcedureId));
            }

            vm.isLoadingData = true;
            $q.all(serverRequestArray).then(
                function success(responseArray) {
                    vm.acqProcedure = responseArray[0];
                    vm.acqProcedureLotList = responseArray[1];
                    vm.evalComiteeList = responseArray[2];
                    vm.editableSubsectionTypeList = responseArray[3];
                    if (vm.isAC || vm.isOpSeap) {
                        vm.warningList = responseArray[4];
                        vm.asociereAvertismenteCuPersoane();
                    }

                    vm.calculateLotEval();
                    vm.checkIfReadonly();
                    vm.isLoadingData = false;
                }, function(errMsg) {
                    vm.isLoadingData = false;
                    SeapAlertService.toastError("Eroare la aducere date!", "Eroare");
                }
            );
        };

        vm.initData();

        /**
         * Adauga o noua Comisie de evaluare
         */
        vm.addNewEvalCommittee = function() {
            vm.isLoadingData = true;
            FormComisieEvaluareService.create(vm.acqProcedureId, {}).then(
                function success(evalCommitteeResponse) {
                    if (!vm.evalComiteeList) {
                        vm.evalComiteeList = [];
                    }
                    vm.evalComiteeList.push(evalCommitteeResponse);
                    vm.calculateLotEval();
                    vm.isLoadingData = false;
                    SeapAlertService.toastSuccess("O noua comisie de evaluare a fost creata!", "Succes");
                }, function error(errMsg) {
                    vm.isLoadingData = false;
                    SeapAlertService.toastError("Eroare la adaugare comisie!", "Eroare");
                }
            );
        };

        /**
         * Salveaza loturile valuate de aceasta comisie
         */
        vm.saveEvalComitteeLots = function(evalCommittee) {
            vm.isLoadingData = true;
            FormComisieEvaluareService.update(vm.acqProcedureId, evalCommittee).then(
                function success(evalCommitteeResponse) {
                    vm.initData();
                    vm.isLoadingData = false;
                    SeapAlertService.toastSuccess("Comisia a fost actualizata cu succes!", "Succes");
                }, function error(errMsg) {
                    vm.isLoadingData = false;
                    SeapAlertService.toastError("Eroare la actualizare date despre comisie!", "Eroare");
                }
            );
        };

        /**
         * Sterge o comisie de evaluare
         * @param evalCommittee
         */
        vm.deleteEvalCommittee = function(evalCommittee) {

            if (evalCommittee.acqProcedureLotDTOs.length > 0) {
                SeapAlertService.toastError("Nu poate fi stearsa o comisie avand loturi evaluate! " +
                    "Deselectati loturile evaluate si apoi stergeti comisia.", "Succes");
                return;
            }

            FormComisieEvaluareService.delete(vm.acqProcedureId, evalCommittee.evalCommitteeId).then(
                function success(evalCommitteeResponse) {
                    vm.initData();
                    vm.isLoadingData = false;
                    SeapAlertService.toastSuccess("Comisia a fost stearsa cu succes!", "Succes");
                }, function error(errMsg) {
                    vm.isLoadingData = false;
                    SeapAlertService.toastError("Eroare la stergere date despre comisie! Mesaj eroare: " + errMsg, "Eroare");
                }
            );
        };

        // Adaugare Membru Comisie
        vm.openSaveMembruComisieModal = function(evalCommittee, evalCommitteeMember) {
            if (evalCommitteeMember) {
                $state.go('form-comisie-evaluare-save-membru', {
                    proc: vm.acqProcedureId,
                    comisie: evalCommittee.evalCommitteeId,
                    membru: evalCommitteeMember.evalCommitteeMemberId,
                    validationMessage: vm.validationMessage});
            } else {
                $state.go('form-comisie-evaluare-save-membru', {
                    proc: vm.acqProcedureId,
                    comisie: evalCommittee.evalCommitteeId});
            }
        };

        // Sterge Membru Comisie
        vm.deleteMembruComisie = function(evalCommittee, evalCommitteeMember) {
            EvalCommitteMemberService.delete(vm.acqProcedureId, evalCommitteeMember.evalCommitteeMemberId).then(
                function success(response) {
                    SeapAlertService.toastSuccess('Membru sters cu succes sters cu succes!', 'Succes');
                    angular.forEach(evalCommittee.evalCommitteeMemberDTOs, function(evalCommitteeMemberDTOItem, index) {
                        if (evalCommitteeMemberDTOItem.evalCommitteeMemberId === evalCommitteeMember.evalCommitteeMemberId) {
                            evalCommittee.evalCommitteeMemberDTOs.splice(index, 1);
                            return;
                        }
                    });
                }, function error(errorMsg) {
                    SeapAlertService.toastError('Eroare la stergerea membrului din comisie. Mesaj: ' + errorMsg, 'Eroare');
                }
            );
        };

        // Adaugare Expert Cooptat
        vm.openSaveExpertCooptatModal = function(evalCommittee, evalCommitteeExpert) {
            if (evalCommitteeExpert) {
                $state.go('form-comisie-evaluare-save-expert', {
                    proc: vm.acqProcedureId,
                    comisie: evalCommittee.evalCommitteeId,
                    expert: evalCommitteeExpert.evalCommitteeExpertId,
                    validationMessage: vm.validationMessage});
            } else {
                $state.go('form-comisie-evaluare-save-expert', {
                    proc: vm.acqProcedureId,
                    comisie: evalCommittee.evalCommitteeId});
            }
        };

        // Sterge Expert Cooptat
        vm.deleteExpertCooptat = function(evalCommittee, evalCommitteeExpert) {
            EvalCommitteExpertService.delete(vm.acqProcedureId, evalCommitteeExpert.evalCommitteeExpertId).then(
                function success(response) {
                    SeapAlertService.toastSuccess('Expert sters cu succes sters cu succes!', 'Succes');
                    angular.forEach(evalCommittee.evalCommitteeExpertDTOs, function(evalCommitteeExpertDTOItem, index) {
                        if (evalCommitteeExpertDTOItem.evalCommitteeExpertId === evalCommitteeExpert.evalCommitteeExpertId) {
                            evalCommittee.evalCommitteeExpertDTOs.splice(index, 1);
                            return;
                        }
                    });
                }, function error(errorMsg) {
                    SeapAlertService.toastError('Eroare la stergerea Expertului cooptat din comisie. Mesaj: ' + errorMsg, 'Eroare');
                }
            );
        };

        /**
         * Calucleaza loturile evaluate / neevaluate de comisie
         */
        vm.calculateLotEval = function(commiteeIndex) {
            vm.isAddEvalCommitteeBtnEnabled = true;
            var totalLoturiEvaluate = 0;

            if (commiteeIndex) {

            } else {
                angular.forEach(vm.evalComiteeList, function(evalComiteeItem, index) {
                    if (evalComiteeItem.acqProcedureLotDTOs && evalComiteeItem.acqProcedureLotDTOs.length) {
                        totalLoturiEvaluate += evalComiteeItem.acqProcedureLotDTOs.length;
                    }

                    if (evalComiteeItem.acqProcedureLotDTOs &&
                        (evalComiteeItem.acqProcedureLotDTOs.length === vm.acqProcedureLotList.length)) {

                        evalComiteeItem.lotNeevaluatList = [];
                        evalComiteeItem.toateLoturileEvaluate = true;
                    } else {
                        evalComiteeItem.toateLoturileEvaluate = false;
                        evalComiteeItem.lotNeevaluatList = [];
                        angular.forEach(vm.acqProcedureLotList, function(acqProcedureLot, indexAcqProcedureLot) {
                            var isEval = false;
                            angular.forEach(evalComiteeItem.acqProcedureLotDTOs, function(lotEvaluat, indexLotEvaluat) {
                                if (acqProcedureLot.lotNumber === lotEvaluat.lotNumber) {
                                    isEval = true;
                                }
                            });
                            if (!isEval) {
                                evalComiteeItem.lotNeevaluatList.push(acqProcedureLot);
                            }
                        });
                    }
                });
            }

            if (vm.evalComiteeList.length > 0 && vm.acqProcedure.isLot && (totalLoturiEvaluate === vm.acqProcedureLotList.length)) {
                vm.isAddEvalCommitteeBtnEnabled = false;
            }
        };

        /**
         * Schimbare switch pentru loaturi evaluate
         */
        vm.onLoturiEvaluateSwitchChange = function(evalCommittee) {
            if (evalCommittee.toateLoturileEvaluate) {
                evalCommittee.lotNeevaluatList = [];
                evalCommittee.acqProcedureLotDTOs = vm.acqProcedureLotList;
            } else {
                evalCommittee.lotNeevaluatList = vm.acqProcedureLotList;
                evalCommittee.acqProcedureLotDTOs = [];
            }
        };
    }
})();
