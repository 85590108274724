/**
 * Created by florin.iacob on 5/16/2017.
 */
(function() {
    'use strict';

    angular
        .module('seapAniFormsApp')
        .controller('FormProceduraAchizitieCtrl', FormProceduraAchizitieCtrl);

    FormProceduraAchizitieCtrl.$inject = ['$state', '$q', 'SeapAlertService', 'urlParams', 'FormProceduraAchizitieService', 'IntegrityFormService', 'NomenclatorService', 'Constants', 'Principal', 'SicapSyncService'];

    function FormProceduraAchizitieCtrl($state, $q, SeapAlertService, urlParams, FormProceduraAchizitieService, IntegrityFormService, NomenclatorService, Constants, Principal, SicapSyncService) {

        var vm = this;
        if (urlParams.idProcedura) {
            vm.acqProcedureId = parseInt(urlParams.idProcedura);
        }
        vm.validationMessage = urlParams.validationMessage;

        vm.INTEGRITY_FORM_SECTION = Constants.INTEGRITY_FORM_SECTION;
        vm.INTEGRITY_FORM_SUBSECTION = Constants.INTEGRITY_FORM_SUBSECTION;

        vm.hasRoleManualAddAcqProcedure = Principal.hasAnyAuthority(['ROLE_MANUAL_ADD_ALL', 'ROLE_MANUAL_ADD_ACQ_PROCEDURE']);
        vm.hasRoleExclusiveSyncAllSections = Principal.hasAnyAuthority(['ROLE_EXCLUSIVE_SYNC_ALL_SECTIONS']);

        vm.acqProcedure = {
            acqProcedureId: null,
            procedureName: null,
            procedureCode: null,
            estimatedTotalValue: null,
            isOnline: true,
            isLocal: false,
            isLot: false,
            tenderOpenDate: new Date(),
            publicationDate: new Date(),
            nomAcqProcedureTypeDTO: {},
            nomContractTypeDTO: {},
            nomNoticeTypeDTO: {},
            nomSourceFinanceDTO: {},
            nomCurrencyDTO: {},
            nomAcqProcedureStateDTO: {
                id: 3
            },
            editableSectionScheme: {
                Sectiune_I_1: true
            }
        };

        var serverRequests = [
            NomenclatorService.findByKey('NomAcqProcedureType'),
            NomenclatorService.findByKey('NomContractType'),
            NomenclatorService.findByKey('NomNoticeType'),
            NomenclatorService.findByKey('NomSourceFinance'),
            NomenclatorService.findByKey('NomCurrency'),
            NomenclatorService.findByKey('NomAcqProcedureState')
        ];
        if (vm.acqProcedureId) {
            //serverRequests.push(FormProceduraAchizitieService.findById(vm.acqProcedureId));
            serverRequests.push(IntegrityFormService.findByAcqProcedureId(vm.acqProcedureId));
            serverRequests.push(FormProceduraAchizitieService.getEditableSubsectionTypeList(vm.acqProcedureId, vm.INTEGRITY_FORM_SECTION.I_1));
        } else {
            vm.isAddMode = true;
            vm.acqProcedure.isLocal = true;
        }

        vm.data = {};

        // Aducere date Server
        vm.isLoadingData = true;
        $q.all(serverRequests).then(
            function success(responseArray) {

                vm.data.nomAcqProcedureTypeList = responseArray[0];
                vm.data.nomContractTypeList = responseArray[1];
                vm.data.nomNoticeTypeList = responseArray[2];
                vm.data.nomSourceFinanceList = responseArray[3];
                vm.data.nomCurrencyList = responseArray[4];
                vm.data.nomAcqProcedureStateList = responseArray[5];

                if (vm.acqProcedureId) {
                    vm.integrityForm = responseArray[responseArray.length - 2];
                    vm.editableSubsectionTypeList = responseArray[responseArray.length - 1];
                    vm.acqProcedure = vm.integrityForm.acqProcedureDTO;
                    vm.acqProcedure.lastPublicationDate = vm.integrityForm.lastPublicationDate;
                    if (vm.acqProcedure.publicationDate) {
                        vm.acqProcedure.publicationDate = new Date(vm.acqProcedure.publicationDate);
                    }

                    vm.hasRoleManualAddAcqProcedure = vm.hasRoleManualAddAcqProcedure && vm.acqProcedure.isLocal;

                } else if (vm.acqProcedure.isLocal) {
                    vm.acqProcedure.nomNoticeTypeDTO = vm.data.nomNoticeTypeList[5]; // tip anunt ALTELE
                }
                vm.isLoadingData = false;
            },
            function error(errorArray) {
                SeapAlertService.toastError('Eroare la aducere date!');
                vm.isLoadingData = false;
            }
        );

        vm.isIdentificareProceduraFormEditable = false;
        vm.toggleIdentificareProceduraFormEdit = function() {
            vm.isIdentificareProceduraFormEditable = !vm.isIdentificareProceduraFormEditable;
        };

        /**
         * Salveaza informatiile depre Procedura
         */
        vm.saveIdentificareProceduraForm = function() {
            vm.isLoadingData = true;
            if (!FormProceduraAchizitieService.validateAcqProcedureAndWarn(vm.acqProcedure)) {
                vm.isLoadingData = false;
                return;
            }

            var saveProcedureRequest = [];
            if (vm.acqProcedure.acqProcedureId) {
                saveProcedureRequest.push(FormProceduraAchizitieService.update(vm.acqProcedure));
            } else {
                saveProcedureRequest.push(FormProceduraAchizitieService.create(vm.acqProcedure));
            }
            $q.all(saveProcedureRequest).then(
                function success(procedureArray) {
                    SeapAlertService.toastSuccess('Procedura salvata cu succes!');
                    vm.acqProcedure = procedureArray[0];
                    if (vm.acqProcedure.publicationDate) {
                        vm.acqProcedure.publicationDate = new Date(vm.acqProcedure.publicationDate);
                    }
                    vm.acqProcedureId = procedureArray[0].acqProcedureId;
                    vm.isIdentificareProceduraFormEditable = false;
                    vm.isLoadingData = false;
                }, function error(errorMsg) {
                    vm.isLoadingData = false;
                    SeapAlertService.toastError('Eroare la salvarea Procedurii. Mesaj: ' + errorMsg, 'Eroare');
                }
            );
        };

        // DATE PICKER
        vm.datePickerOpenStatus = {};
        vm.datePickerOpenStatus.dataPublicare = false;
        vm.openCalendar = function(date) {
            vm.datePickerOpenStatus[date] = true;
        };

        // Sincronizare date procedura din SICAP
        vm.syncAcqProcedureFromSicap = function() {
            vm.isLoadingData = true;
            SicapSyncService.syncAcqProcedure(vm.acqProcedureId).then(
                function success(response) {
                    vm.initData();
                    SeapAlertService.toastSuccess("Preluarea datelor pentru procedura din Noul Seap s-a efectuat cu succes!");
                    vm.isLoadingData = false;
                },
                function error(errorMsg) {
                    SeapAlertService.toastError(errorMsg);
                    vm.isLoadingData = false;
                }
            );
        };
    }
})();
